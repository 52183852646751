import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Assistant, SocialMediaAssistant, Post } from '@/types/assistant';
import { updateTopics as apiUpdateTopics } from '@/helper/apiHelper';
import Http from '@/http/httpClient';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { AppDispatch } from '@/redux/store';

type AssistanceState = {
  selectedAssistantId: string | null;
  groupByType: boolean;
  assistants: Assistant[];
  socialMediaAssistant: SocialMediaAssistant | null;
  posts: Post[];
  strategies: any[];
  businessDevelopmentAssistants: Assistant[];
  relationshipGrowthAssistants: Assistant[];
  socialMediaAssistants: Assistant[];
};

function mapSequenceTypeToAssistantType(templateType: string): Assistant['type'] {
  switch (templateType) {
    case 'acquire':
      return 'Business Development';
    case 'retain':
      return 'Relationship Growth';
    case 'grow':
      return 'Keeping in Touch';
    default:
      return 'Other';
  }
}

function loadGroupByType(): boolean {
  const storedValue = localStorage.getItem('groupByType');
  if (storedValue === null) return false;
  return storedValue === 'true';
}

const initialState: AssistanceState = {
  selectedAssistantId: null,
  groupByType: loadGroupByType(),
  assistants: [],
  socialMediaAssistant: null,
  posts: [],
  strategies: [],
  businessDevelopmentAssistants: [],
  relationshipGrowthAssistants: [],
  socialMediaAssistants: [],
};

export const fetchStrategies = createAsyncThunk('assistance/fetchStrategies', async () => {
  const response = await Http.getData('strategy/all');
  return response.strategies;
});

export const useInitializeStrategies = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchStrategies());
  }, [dispatch]);
};

const assistanceSlice = createSlice({
  name: 'assistance',
  initialState,
  reducers: {
    setSelectedAssistantId(state, action: PayloadAction<string | null>) {
      state.selectedAssistantId = action.payload;
    },
    clearSelectedAssistantId(state) {
      state.selectedAssistantId = null;
    },
    setGroupByType(state, action: PayloadAction<boolean>) {
      state.groupByType = action.payload;
      localStorage.setItem('groupByType', JSON.stringify(action.payload));
    },
    setAssistantsFromSequences(state, action: PayloadAction<any[]>) {
      const sequences = action.payload;
      const mappedAssistants: Assistant[] = sequences.map((seq) => {
        return {
          ...seq,
          _id: seq._id,
          name: seq.name,
          status: seq.status === 'Active' ? 'Active' : 'Paused',
          type: mapSequenceTypeToAssistantType(seq.template?.type ?? ''),
        };
      });

      state.assistants = mappedAssistants;

      state.businessDevelopmentAssistants = mappedAssistants.filter(
        (assistant) => assistant.type === 'Business Development',
      );
      state.relationshipGrowthAssistants = mappedAssistants.filter(
        (assistant) => assistant.type === 'Relationship Growth',
      );
      state.socialMediaAssistants = mappedAssistants.filter((assistant) => assistant.type === 'Social Media');
    },
    setSocialMediaAssistant(state, action: PayloadAction<SocialMediaAssistant>) {
      state.socialMediaAssistant = action.payload;
    },
    updateTopics(state, action: PayloadAction<string[]>) {
      if (state.socialMediaAssistant) {
        state.socialMediaAssistant.topics = action.payload;
      }
    },
    updatePostFrequency(state, action: PayloadAction<string>) {
      if (state.socialMediaAssistant) {
        state.socialMediaAssistant.postFrequency = action.payload;
      }
    },
    updateSocialNetworks(state, action: PayloadAction<string[]>) {
      if (state.socialMediaAssistant) {
        state.socialMediaAssistant.socialNetworks = action.payload;
      }
    },
    setPosts(state, action: PayloadAction<Post[]>) {
      state.posts = action.payload;
    },
    updateAssistantName(state, action: PayloadAction<{ id: string; name: string }>) {
      state.assistants = state.assistants.map((assistant) =>
        assistant._id === action.payload.id ? { ...assistant, name: action.payload.name } : assistant,
      );

      state.businessDevelopmentAssistants = state.assistants.filter(
        (assistant) => assistant.type === 'Business Development',
      );
      state.relationshipGrowthAssistants = state.assistants.filter(
        (assistant) => assistant.type === 'Relationship Growth',
      );
      state.socialMediaAssistants = state.assistants.filter((assistant) => assistant.type === 'Social Media');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStrategies.fulfilled, (state, action) => {
      state.strategies = action.payload;
    });
  },
});

export const {
  setSelectedAssistantId,
  clearSelectedAssistantId,
  setGroupByType,
  setAssistantsFromSequences,
  setSocialMediaAssistant,
  updateTopics,
  updatePostFrequency,
  updateSocialNetworks,
  setPosts,
  updateAssistantName,
} = assistanceSlice.actions;

export const selectSelectedAssistantId = (state: any) => state.assistance.selectedAssistantId;
export const selectGroupByType = (state: any) => state.assistance.groupByType;
export const selectAssistants = (state: any) => state.assistance.assistants;

export const selectBusinessDevelopmentAssistants = (state: any) => state.assistance.businessDevelopmentAssistants;
export const selectRelationshipGrowthAssistants = (state: any) => state.assistance.relationshipGrowthAssistants;
export const selectSocialMediaAssistants = (state: any) => state.assistance.socialMediaAssistants;

export const updateTopicsAsync = createAsyncThunk(
  'assistance/updateTopicsAsync',
  async (topics: string[], { dispatch }) => {
    const result = await apiUpdateTopics(topics);
    if (result) {
      dispatch(updateTopics(topics));
    } else {
      console.error('Failed to update topics in the backend');
    }
  },
);

export const selectGrowStrategy = (state: any) => state.assistance.strategies.find((s: any) => s.id === 'grow');

export default assistanceSlice.reducer;
