import { SequenceStepStatus, TwitterStepIds, RULE_ENGINE_CODES } from '../../../constants/constant.js';

export const isEmail = (contact, listItem) => {
  const step = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);
  return ['meeting', 'followup', 'email', 'checkin.recurring'].includes(step?.id);
};

export const isGift = (contact, listItem) => {
  const step = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);
  return ['gift'].includes(step?.id);
};

export const getFormattedTime = (sentAtTime, currentStep, nextStepTime, now) => {
  const diff = Math.abs(nextStepTime - now);
  const isPast = nextStepTime - now <= 0;

  const mins = 60 * 1000;
  const hrs = 60 * mins;
  const day = 24 * hrs;
  const week = 7 * day;
  const month = 30 * day;

  const months = Math.floor(diff / month);
  const weeks = Math.floor((diff % month) / week);
  const days = Math.floor((diff % month) / day);
  const hours = Math.floor((diff % day) / hrs);
  const minutes = Math.floor((diff % hrs) / mins);

  let formattedTime = '';
  if (isPast) {
    if (months > 0) {
      formattedTime = sentAtTime?.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    } else if (weeks > 0) {
      formattedTime = `${weeks}w ago`;
    } else if (days > 0) {
      formattedTime = `${days}d ago`;
    } else if (hours > 0) {
      formattedTime = `${hours}h ago`;
    } else if (minutes > 0) {
      formattedTime = `${minutes}m ago`;
    } else {
      formattedTime = 'less than a minute ago';
    }

    return formattedTime;
  } else {
    const verb = currentStep && currentStep.approveRequired ? 'Starts' : 'Sends';
    if (months > 0) {
      const monthName = nextStepTime.toLocaleDateString('en-US', {
        month: 'long',
      });
      formattedTime = `${verb} in ${monthName}`;
      // formattedTime = paused ? (`Would send the email ${months === 1 ? 'a month' : `${months} months`} after unpausing`) : `Starts in ${nextStepTime.getDate()}, ${monthName}`
      //formattedTime += `Starts in ${months} ${months === 1 ? 'month' : 'months'}`
    } else {
      if (days > 0) {
        formattedTime += `${verb} ${days === 1 ? 'tomorrow' : `in ${days} days`}`;
        // formattedTime += paused ? `Would send the email ${days === 1 ? 'the day' : `${days} days`} after unpausing` : `Starts ${days === 1 ? 'tomorrow' : `in ${days} days`}`
      } else {
        // Using "else if" to reduce granularity
        if (hours > 0) {
          formattedTime += ` ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
        } else if (minutes > 0) {
          formattedTime += ` ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
        }

        if (formattedTime.length === 0) {
          formattedTime += 'less than a minute';
        }
        // formattedTime = paused ? `Would send the email ${formattedTime} after unpausing` : `Starts in ${formattedTime}`
        formattedTime = `${verb} in ${formattedTime}`;
      }
    }
  }

  return formattedTime;
};

export const formatTimeToolTip = (contact) => {
  if (contact?.stepStatus?.status === 'failed' && !!contact.stepStatus?.linkedInResponse?.failReason) {
    return `Failed due to ${contact.stepStatus.linkedInResponse.failReason.toLowerCase()}`;
  } else if (contact?.stepStatus?.status === 'queued' && !!contact.stepStatus?.linkedInResponse?.delayedDueToLimit) {
    if (contact.stepStatus?.linkedInResponse?.action === 'profileVisit') {
      return `Rescheduled due to reaching the daily limit for LinkedIn profile visits.`;
    } else {
      return `Rescheduled due to reaching the daily limit.`;
    }
  }
  return '';
};

export function getContactType(contact, listItem) {
  if (isEmail(contact, listItem)) return 'email';
  if (isGift(contact, listItem)) return 'gift';
  return 'social'; // Default to social if none of the above
}

export const getSkipLabel = (contact, rule) => {
  const statusCodes = contact?.stepStatus?.status?.split(',').map((code) => code.trim()) || [];
  return statusCodes.includes(rule.CODE);
};

// Helper function to format a date consistently
const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

// Appends status update time to a message if contact has a statusUpdatedAt time
const appendStatusTime = (message, contact) => {
  const updatedAt = contact?.stepStatus?.statusUpdatedAt;
  if (!updatedAt) return message;
  const formattedDate = formatDate(updatedAt);
  return `${message} (updated on ${formattedDate})`;
};

// Extracted helper: handle statuses for LinkedIn Email steps
const getLinkedInEmailStatusMessage = (contact) => {
  const { status } = contact?.stepStatus || {};
  if (status === SequenceStepStatus.FAILED) {
    return appendStatusTime('Failed', contact);
  }
  if (status === SequenceStepStatus.MANUALLY_DELETED) {
    return appendStatusTime('Manually removed', contact);
  }
  return null;
};

// Extracted helper: handle standard statuses that return a known message
const getStandardStatusMessage = (status, contact) => {
  switch (status) {
    case SequenceStepStatus.MANUALLY_DELETED:
      return appendStatusTime('Manually removed', contact);
    case SequenceStepStatus.LIMITEXCEEDED:
      return appendStatusTime('Daily email limit exceeded', contact);
    default:
      return null;
  }
};

// Extracted helper: handle paused state
const getPausedMessage = (contact) => {
  if (contact.status !== 'paused') return null;
  const pausedType = contact.pausedType || 'Paused';
  return appendStatusTime(pausedType, contact);
};

// Extracted helper: handle skipped state
const getSkippedMessage = (contact) => {
  const isSkipped = contact?.stepStatus?.status === 'skipped';
  return isSkipped ? appendStatusTime('Failed', contact) : null;
};

// Extracted helper: handle skip labels from RULE_ENGINE_CODES
const getSkipLabelMessage = (contact) => {
  const skipLabels = RULE_ENGINE_CODES.filter((rule) => getSkipLabel(contact, rule));
  if (!skipLabels || skipLabels.length === 0) return null;
  skipLabels.sort((a, b) => b.WEIGHT - a.WEIGHT);
  return appendStatusTime(skipLabels[0].LABEL, contact);
};

// Extracted helper: handle when nextStepId doesn't exist but is a Twitter step scheduled for the future
const getTwitterScheduledMessage = (contact) => {
  const nextStepExecTime = contact?.stepStatus?.sentAt || contact?.stepStatus?.startDate;
  const isTwitterStep = TwitterStepIds.includes(contact?.stepStatus?.stepType);
  if (!contact.nextStepId && isTwitterStep && new Date(nextStepExecTime) > new Date()) {
    return appendStatusTime('Next step', contact);
  }
  return null;
};

// Extracted helper: handle scenario when no nextStepExecTime is defined
const getNoNextStepTimeMessage = (contact) => {
  const nextStepExecTime = contact?.stepStatus?.sentAt || contact?.stepStatus?.startDate;
  if (nextStepExecTime) return null;

  const likeStepExist = contact.stepStatus?.stepType === 'like';
  const lastStepCompletedTime = contact?.lastStepCompletedTime;
  const lastCompletedStepId = contact?.lastCompletedStepId;
  const isLimitExceeded =
    contact.stepStatus?.id?.toString() === lastCompletedStepId?.toString() &&
    contact.stepStatus?.status === SequenceStepStatus.LIMITEXCEEDED;

  if (isLimitExceeded) {
    return appendStatusTime('Daily email limit exceeded', contact);
  }

  if (!lastStepCompletedTime) {
    // Returns a string or empty string depending on if we have a like step
    return likeStepExist ? 'Auto-liking their posts frequently.' : '';
  }

  const formattedTime = formatDate(lastStepCompletedTime);
  // Original code returns JSX, we assume a React environment. Let's keep consistent:
  return (
    <>
      Completed on {formattedTime}
      {likeStepExist && <p>Auto-liking their posts frequently.</p>}
    </>
  );
};

// Extracted helper: handle scenario when next step time is in the past or now
const getPastNextStepTimeMessage = (contact, listItem) => {
  const nextStepExecTime = contact?.stepStatus?.sentAt || contact?.stepStatus?.startDate;
  const now = new Date();
  const nextStepTime = new Date(nextStepExecTime);
  const diff = nextStepTime - now;
  const isPast = diff <= 0;

  if (!isPast) return null; // Only handle if it's in the past

  const status = contact?.stepStatus?.status;
  const ONE_DAYms = 1000 * 60 * 60 * 24;

  if (status === SequenceStepStatus.NOTAPPROVED && diff <= -ONE_DAYms) {
    return appendStatusTime('Expired', contact);
  }

  switch (status) {
    case SequenceStepStatus.MANUALLY_DELETED:
      return appendStatusTime('Manually removed', contact);

    case SequenceStepStatus.NOTAPPROVED:
      return appendStatusTime('Waiting for approval', contact);

    case SequenceStepStatus.LIMITEXCEEDED:
      return appendStatusTime('Daily email limit exceeded', contact);

    case SequenceStepStatus.QUEUED:
      const stepType = getContactType(contact, listItem);
      const baseMessage =
        status === SequenceStepStatus.NOTAPPROVED
          ? `Next ${stepType} step waiting for approval`
          : `Next ${stepType} step scheduled for processing`;
      // For non-email/gift steps, default message
      const message =
        stepType === 'email' || stepType === 'gift' ? baseMessage : 'Next social step scheduled for processing';
      return appendStatusTime(message, contact);

    default:
      // If none of the above matched, just break out
      break;
  }

  return null;
};

// Main function
export const formatTimeUntilNextStep = (contact, currentStep, listItem) => {
  // Handle LinkedIn Email Steps first
  if (contact?.stepStatus?.stepType === 'linkedInEmail') {
    const linkedInMessage = getLinkedInEmailStatusMessage(contact);
    if (linkedInMessage) return linkedInMessage;
  }

  // Handle standard statuses
  const status = contact?.stepStatus?.status;
  const standardMessage = getStandardStatusMessage(status, contact);
  if (standardMessage) return standardMessage;

  // Handle paused
  const pausedMessage = getPausedMessage(contact);
  if (pausedMessage) return pausedMessage;

  // Handle skipped
  const skippedMessage = getSkippedMessage(contact);
  if (skippedMessage) return skippedMessage;

  // Handle skip labels
  const skipLabelMessage = getSkipLabelMessage(contact);
  if (skipLabelMessage) return skipLabelMessage;

  // Handle Twitter scheduled scenario
  const twitterMessage = getTwitterScheduledMessage(contact);
  if (twitterMessage) return twitterMessage;

  // Handle no next step time scenario
  const noNextStepTimeMessage = getNoNextStepTimeMessage(contact);
  if (noNextStepTimeMessage !== null) return noNextStepTimeMessage;

  // If we have a nextStepExecTime, handle if it's in the past
  const pastNextStepMessage = getPastNextStepTimeMessage(contact, listItem);
  if (pastNextStepMessage) return pastNextStepMessage;

  // If none of the above provided a message, handle future step formatting
  const nextStepExecTime = contact?.stepStatus?.sentAt || contact?.stepStatus?.startDate;
  const now = new Date();
  const nextStepTime = new Date(nextStepExecTime);
  const sentAtTime = null; // According to original code, not reassigned
  const formattedTime = getFormattedTime(sentAtTime, currentStep, nextStepTime, now);

  // Handle special OOO message
  if (contact.status === 'active' && contact.message?.startsWith('OOO')) {
    return `${contact.message}. ${formattedTime}`;
  }

  return formattedTime;
};
