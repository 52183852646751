import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Home,
  Users,
  Settings,
  Briefcase,
  ListChecks,
  BarChart,
  Bot,
  MoreHorizontal,
  Play,
  Pencil,
  Trash2,
  Pause,
  CheckSquare,
  MessageSquare,
  Share2,
} from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '@/redux/authSlice';
import { GetCookie } from '@/helper/cookieManager';
import { UserConst } from '@/constants/userConst';
import { useIsFeatureFlagEnabled } from '@/hooks/useFeatureFlags';

import PSidebar from '@Library/PSidebar';

import BrandTopics from '@/pages/setup/simpleSetupComponents/Brands/brandTopics';
import Socials from '@/pages/setup/simpleSetupComponents/Brands/socials';
import httpClient from '@/http/httpClient';
import { useAssistance } from '@/hooks/useAssistance';
import {
  setSelectedAssistantId,
  selectGroupByType,
  setGroupByType,
  selectAssistants,
  selectGrowStrategy,
  useInitializeStrategies,
  selectBusinessDevelopmentAssistants,
  selectRelationshipGrowthAssistants,
  selectSocialMediaAssistants,
} from '@/redux/slices/Assistance/assistanceSlice';
import AssistantsSection from '@/v0/Assistants/AssistantsSection';

import { Switch } from '@/components/ui/switch';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import PToolTip from '@Library/PToolTip';
import BrandingFlow from '@/pages/ClientAcquisition/components/addSequenceModal/brandingFlow';
import { useStrategies } from '@/hooks/useStrategies';

const Sidebar = ({ full }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.auth.page);
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const groupByType = useSelector(selectGroupByType);
  const assistants = useSelector(selectAssistants);
  const BDAssistants = useSelector(selectBusinessDevelopmentAssistants);
  const RGAssistants = useSelector(selectRelationshipGrowthAssistants);
  const SMAssistants = useSelector(selectSocialMediaAssistants);
  const [socialsModalOpen, setSocialsModalOpen] = useState(false);
  const [topicsModalOpen, setTopicsModalOpen] = useState(false);
  const [isFetchingTopics, setIsFetchingTopics] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [topics, setTopics] = useState();
  const [user, setUser] = useState({ name: null, type: null });

  const curUser = React.useMemo(() => GetCookie('user'), []);

  const isErmDashboardEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard');
  const isOutreachListsEnabled = useIsFeatureFlagEnabled('ui.outreachLists');
  const isSequencesEnabled = useIsFeatureFlagEnabled('sequences');
  const [brandingModalOpen, setBrandingModalOpen] = useState(false);
  const strategies = useStrategies();
  const strategyList = Array.isArray(strategies) && Array.isArray(strategies[0]) ? strategies[0] : [];

  const isAcquireEnabled = strategyList.some((s) => s.id === 'acquire' && s.enabled);
  const isRetainEnabled = strategyList.some((s) => s.id === 'retain' && s.enabled);
  const isGrowEnabled = strategyList.some((s) => s.id === 'grow' && s.enabled);

  useInitializeStrategies();
  const growStrategy = useSelector(selectGrowStrategy);
  const [showWritePost, setShowWritePost] = useState(false);

  const fetchTopics = async () => {
    try {
      const res = await httpClient.getData('getTopics');
      return res;
    } catch (error) {
      console.error('Failed to fetch topics:', error);
      return null;
    }
  };

  const setModal = async () => {
    setIsFetchingTopics(true);
    setTopicsModalOpen(true);
    try {
      const topicsResponse = await fetchTopics();
      if (topicsResponse?.topics?.length > 0) {
        setTopics(topicsResponse.topics);
      }
    } catch (error) {
      console.error('Failed to set modal:', error);
    } finally {
      setIsFetchingTopics(false);
    }
  };

  const handleWritePostClick = () => {
    setBrandingModalOpen(true);
  };

  const closeSocialsModal = () => setSocialsModalOpen(false);
  const closeTopicsModal = () => setTopicsModalOpen(false);

  const updateMenu = (baseMenu) => {
    const updatedMenu = baseMenu.map((item) => ({
      ...item,
      active: item.page === currentPage,
    }));
    setMenuItems(updatedMenu);
  };

  useEffect(() => {
    if (topicsModalOpen) {
      (async () => {
        try {
          const topicsResponse = await fetchTopics();
          if (topicsResponse?.topics?.length > 0) {
            setTopics(topicsResponse.topics);
          }
        } catch (error) {
          console.error('Failed to set modal:', error);
        }
      })();
    }
  }, [topicsModalOpen]);

  useEffect(() => {
    if (featureFlags) {
      const sbClientRetention = { title: 'Approval Queue', icon: CheckSquare, page: 'retention', active: false };
      const sbClientAcquisition = { title: 'Assistants', icon: Bot, page: 'acquisition', active: false };
      const sbClients = { title: 'Relationship Intelligence', icon: Briefcase, page: 'clients', active: false };

      const sbStats = { title: 'Stats', icon: BarChart, page: 'stats', active: false };
      const sbOutreachLists = { title: 'Lists', icon: ListChecks, page: 'outreachLists', active: false };

      let sbItems = [sbClientRetention];

      if (isSequencesEnabled) {
        sbItems.push(sbClientAcquisition);
      }
      if (isErmDashboardEnabled) {
        sbItems.push(sbClients);
      }
      if (isOutreachListsEnabled) {
        sbItems.push(sbOutreachLists);
      }

      if (isGrowEnabled) {
        sbItems.push(sbStats);
      }

      updateMenu(sbItems);
    }
  }, [featureFlags, curUser, isErmDashboardEnabled, isOutreachListsEnabled, isSequencesEnabled]);

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      name: curUser.name,
      type: curUser.userType === UserConst.UserTypes.TRIAL_USER ? 'Free Plan' : 'Premium',
    }));
  }, [curUser]);

  useEffect(() => {
    updateMenu(menuItems);
  }, [currentPage]);

  const { createAssistant, pauseOrResumeAssistant, handleAssistantRename, handleAssistantDelete, handleAssistantEdit } =
    useAssistance();

  const handlePauseToggle = useCallback(
    async (id, status) => {
      await pauseOrResumeAssistant(id, status);
    },
    [pauseOrResumeAssistant],
  );

  const handleDelete = useCallback(
    async (id) => {
      await handleAssistantDelete(id);
    },
    [handleAssistantDelete],
  );

  const handleAssistantClick = useCallback(
    (assistantId) => {
      dispatch(setSelectedAssistantId(assistantId));
      dispatch(setPage('acquisition'));
    },
    [dispatch],
  );

  const [expandedTypes, setExpandedTypes] = useState([]);

  useEffect(() => {
    if (groupByType) {
      setExpandedTypes(['type-Business Development']);
    } else {
      setExpandedTypes([]);
    }
  }, [groupByType]);

  const groupedAssistants = groupByType
    ? assistants.reduce((acc, a) => {
        if (!acc[a.type]) acc[a.type] = [];
        acc[a.type].push(a);
        return acc;
      }, {})
    : { All: assistants };

  const processedMenuItems = [];

  const handleRefreshClientAcquisition = useCallback(() => {
    window.dispatchEvent(new Event('refreshClientAcquisition'));
  }, []);

  return (
    <>
      <PSidebar
        pVariant="primary"
        full={true}
        user={user}
        menuItems={menuItems}
        postOnClick={handleWritePostClick}
        menuOnClick={(title, hasSubmenu, page) => dispatch(setPage(page))}
        assistantsSection={
          isSequencesEnabled && (
            <>
              {isAcquireEnabled && (
                <AssistantsSection
                  isSequencesEnabled={isSequencesEnabled}
                  typeLabel="Lead Assistant"
                  typeIcon={Briefcase}
                  desiredType="Business Development"
                  assistants={BDAssistants}
                  expandedTypes={expandedTypes}
                  setExpandedTypes={setExpandedTypes}
                  onPauseToggle={handlePauseToggle}
                  onDelete={handleDelete}
                  onRename={handleAssistantRename}
                  onAssistantClick={handleAssistantClick}
                  currentPage={currentPage}
                  onRefreshClientAcquisition={handleRefreshClientAcquisition}
                />
              )}

              {isRetainEnabled && (
                <AssistantsSection
                  isSequencesEnabled={isSequencesEnabled}
                  typeLabel="Relationship Assistant"
                  typeIcon={Users}
                  desiredType="Relationship Growth"
                  assistants={RGAssistants}
                  expandedTypes={expandedTypes}
                  setExpandedTypes={setExpandedTypes}
                  onPauseToggle={handlePauseToggle}
                  onDelete={handleDelete}
                  onRename={handleAssistantRename}
                  onAssistantClick={handleAssistantClick}
                  currentPage={currentPage}
                  onRefreshClientAcquisition={handleRefreshClientAcquisition}
                />
              )}

              {isGrowEnabled && (
                <AssistantsSection
                  isSequencesEnabled={isSequencesEnabled}
                  typeLabel="Social Assistant"
                  typeIcon={Share2}
                  desiredType="Social Media"
                  assistants={SMAssistants}
                  expandedTypes={expandedTypes}
                  setExpandedTypes={setExpandedTypes}
                  onPauseToggle={handlePauseToggle}
                  onDelete={handleDelete}
                  onRename={handleAssistantRename}
                  onAssistantClick={handleAssistantClick}
                  currentPage={currentPage}
                  onRefreshClientAcquisition={handleRefreshClientAcquisition}
                />
              )}
            </>
          )
        }
        showWritePost={growStrategy?.enabled === true}
      />

      {brandingModalOpen && <BrandingFlow onClose={() => setBrandingModalOpen(false)} />}

      {topicsModalOpen && (
        <BrandTopics
          open={topicsModalOpen}
          onClose={closeTopicsModal}
          source="ComposePost"
          selectedTopicsSetter={setTopics}
          initialSelectedTopics={topics}
          isLoading={isFetchingTopics}
        />
      )}
      {socialsModalOpen && (
        <Socials open={socialsModalOpen} onClose={closeSocialsModal} source="ComposePost" topics={topics} />
      )}
    </>
  );
};

export default Sidebar;
