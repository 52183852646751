'use client';

import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AnimatedDraggableList from './animated-draggable-list';
import { BadgeEditListItem } from './badge-edit-list-item';
import { StageBadge } from './stage-badge';
import { Plus, X, Trash2, Check } from 'lucide-react';
import { IconButton } from './icon-button';
import { motion, AnimatePresence } from 'framer-motion';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { ColorPicker } from './color-picker';
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { ConfirmationDialog } from '@/v0/confirmation-dialog';
import { updateErmStages } from '@/helper/apiHelper';
import { setPipelineStages } from '@/redux/authSlice';

type StageManagerProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  currentStageId: string;
  triggerRef: React.RefObject<HTMLElement>;
  top: number;
  left: number;
};

const NEW_STAGE_TEMPLATE = () => {
  const bgColor = getRandomColor();
  return {
    _id: '',
    name: 'New pipeline stage',
    backgroundColor: bgColor,
    textColor: getTextColor(bgColor),
  };
};

export const StageManager: React.FC<StageManagerProps> = ({ open, onOpenChange, triggerRef, top, left }) => {
  const dispatch = useDispatch();
  const stages = useSelector((state) => state.auth?.company?.company?.ermPreference?.pipelineStages);

  const popoverRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isAddingNewStage, setIsAddingNewStage] = useState(false);
  const [newStage, setNewStage] = useState(NEW_STAGE_TEMPLATE());
  const [draggedItem, setDraggedItem] = useState<string | null>(null);
  const [popoverPosition, setPopoverPosition] = useState({ top, left });
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [stageToDelete, setStageToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (popoverRef.current) {
      const rect = popoverRef.current.getBoundingClientRect();
      setPopoverPosition({
        top: top - 15,
        left: left - rect.width / 2,
      });
    }
  }, [top, left, open]);

  const handleStageTextChange = (stageId: string, newText: string) => {
    const updatedStages = stages.map((stage) => (stage._id === stageId ? { ...stage, name: newText } : stage));
  };

  const handleAddStage = () => {
    setIsAddingNewStage(true);
    setNewStage(NEW_STAGE_TEMPLATE());
  };

  const handleConfirmNewStage = async () => {
    const updatedStages = [...stages, newStage];
    const result = await updateErmStages(updatedStages);
    if (result.success) {
      dispatch(setPipelineStages(updatedStages));
      setIsAddingNewStage(false);
      setNewStage(NEW_STAGE_TEMPLATE());
    } else {
      // show a toast notification
      console.error('Error adding new stage:', result.message);
    }
  };

  const handleDeleteStage = async (stageDraggableId: string) => {
    const updatedStages = stages.filter(
      (stage, index) => `${index}` !== stageDraggableId.replace('draggable-item-', ''),
    );
    const result = await updateErmStages(updatedStages);
    if (result.success) {
      // show a toast notification

      dispatch(setPipelineStages(updatedStages));
    } else {
      // show a toast notification
      console.error('Error deleting stage:', result.message);
    }
  };

  const handleDragStateChange = async (isDragging: boolean, draggedId?: string, orderedItems?: Stage[]) => {
    setIsDragging(isDragging);
    if (isDragging) {
      setDraggedItem(draggedId || null);
    } else {
      setDraggedItem(null);
    }

    if (orderedItems) {
      // determine if the order has changed
      const isOrderChanged = JSON.stringify(orderedItems) !== JSON.stringify(stages);
      if (isOrderChanged) {
        // update the stages
        const result = await updateErmStages(orderedItems);
        if (result.success) {
          // update the stages in the redux store
          dispatch(setPipelineStages(orderedItems));
        } else {
          // show a toast notification
          console.error('Error updating stage order:', result.message);
        }
      }
    }
  };

  const handleDragEnd = (result: DropResult) => {
    setIsDragging(false);
    setDraggedItem(null);
    if (result.destination && result.destination.droppableId === 'trash') {
      handleDeleteStage(result.draggableId);
    }
  };

  const handleTrashCanMouseUp = () => {
    if (isDragging && draggedItem) {
      setStageToDelete(draggedItem);
      setIsConfirmationDialogOpen(true);
      setIsDragging(false);
      setDraggedItem(null);
    }
  };

  const confirmDeleteStage = () => {
    if (stageToDelete) {
      handleDeleteStage(stageToDelete);
      setStageToDelete(null);
    }
    setIsConfirmationDialogOpen(false);
  };

  const popoverStyle: React.CSSProperties = {
    minWidth: '300px',
    position: 'fixed',
    top: `${popoverPosition.top}px`,
    left: `${popoverPosition.left}px`,
    ...(open && triggerRef.current && popoverRef.current ? {} : { display: 'none' }),
  };

  const stageItems = useMemo(
    () =>
      stages?.map((stage) => ({
        data: stage,
        content: (
          <Tooltip key={stage._id}>
            <TooltipTrigger asChild>
              <div aria-label="Click to edit stage text">
                <BadgeEditListItem
                  key={stage._id}
                  initialText={stage.name}
                  backgroundColor={stage.backgroundColor}
                  textColor={stage.textColor}
                  onTextChange={(newText) => handleStageTextChange(stage._id, newText)}
                />
              </div>
            </TooltipTrigger>
            <TooltipContent>Click to edit stage text</TooltipContent>
          </Tooltip>
        ),
      })),
    [stages],
  );

  return (
    <>
      <TooltipProvider>
        <div
          ref={popoverRef}
          className="absolute z-50 bg-white border border-gray-100 rounded-xl shadow-lg shadow-gray-100/50"
          style={popoverStyle}
        >
          <div className="flex justify-between items-center px-4 py-3 border-b border-gray-100">
            <h3 className="text-sm font-semibold text-gray-900">Pipeline Stage Management</h3>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  onClick={() => onOpenChange(false)}
                  className="text-gray-400 hover:text-gray-600 transition-colors"
                  aria-label="Close stage manager"
                >
                  <X size={16} />
                </button>
              </TooltipTrigger>
              <TooltipContent>Close pipeline stage manager</TooltipContent>
            </Tooltip>
          </div>
          <div className="p-3 max-h-[80vh] overflow-y-auto">
            <DragDropContext onDragEnd={handleDragEnd}>
              <AnimatedDraggableList
                items={stageItems}
                onDragStateChange={handleDragStateChange}
                onItemDelete={handleDeleteStage}
              />
              <div className="flex items-center justify-between w-full py-2 mt-2 mb-2">
                <div className="flex items-center gap-2">
                  <Droppable droppableId="trash">
                    {(provided) => (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={`rounded-full p-2 transition-all ${
                              isDragging
                                ? 'bg-gray-100 cursor-pointer hover:bg-red-100 active:bg-red-200 scale-110'
                                : isAddingNewStage
                                  ? 'bg-gray-100 cursor-pointer'
                                  : ''
                            }`}
                            onMouseUp={handleTrashCanMouseUp}
                            onClick={() => {
                              if (isAddingNewStage) {
                                setIsAddingNewStage(false);
                              }
                            }}
                            aria-label={
                              isDragging
                                ? 'Drop here to delete stage'
                                : isAddingNewStage
                                  ? 'Cancel adding new stage'
                                  : 'Drag stages here to delete'
                            }
                          >
                            <AnimatePresence>
                              <motion.div
                                initial={{ opacity: 0.4, scale: 0.9 }}
                                animate={{
                                  opacity: isDragging || isAddingNewStage ? 1 : 0.4,
                                  scale: isDragging || isAddingNewStage ? 1 : 0.9,
                                  transition: { duration: 0.2 },
                                }}
                                exit={{ opacity: 0.4, scale: 0.9 }}
                              >
                                <Trash2
                                  className={`h-5 w-5 transition-colors ${
                                    isDragging
                                      ? 'text-gray-600 hover:text-red-500 active:text-red-600'
                                      : isAddingNewStage
                                        ? 'text-gray-600'
                                        : 'text-gray-400'
                                  }`}
                                />
                              </motion.div>
                            </AnimatePresence>
                            {provided.placeholder}
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          {isDragging
                            ? 'Drop here to delete'
                            : isAddingNewStage
                              ? 'Cancel adding new stage'
                              : 'Drag stages here to delete'}
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </Droppable>
                  {isAddingNewStage && (
                    <div className="flex items-center gap-2">
                      <StageBadge
                        initialText={newStage.name}
                        backgroundColor={newStage.backgroundColor}
                        textColor={newStage.textColor}
                        isEditing={true}
                        editable={true}
                        onChange={(newText) => setNewStage((prev) => ({ ...prev, name: newText }))}
                      />
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div aria-label="Choose badge color">
                            <ColorPicker
                              color={newStage.backgroundColor}
                              onChange={(color) => {
                                const newTextColor = getTextColor(color);
                                setNewStage((prev) => ({ ...prev, backgroundColor: color, textColor: newTextColor }));
                              }}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>Choose badge color</TooltipContent>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div aria-label={isAddingNewStage ? 'Confirm new stage' : 'Add new stage'}>
                      <IconButton
                        icon={isAddingNewStage ? <Check className="h-3.5 w-3.5" /> : <Plus className="h-3.5 w-3.5" />}
                        color="gray-600"
                        hoverColor="gray-800"
                        circleColor="gray-100"
                        hoverCircleColor="gray-200"
                        onClick={isAddingNewStage ? handleConfirmNewStage : handleAddStage}
                        isActive={true}
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>{isAddingNewStage ? 'Confirm new stage' : 'Add new stage'}</TooltipContent>
                </Tooltip>
              </div>
            </DragDropContext>
          </div>
        </div>
      </TooltipProvider>

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={confirmDeleteStage}
        title="Delete Stage"
        description="This will permanently delete the stage and remove it from all associated records. Please type DELETE to confirm."
        confirmText="Delete"
        cancelText="Cancel"
        severity="high"
        confirmationMethod="text"
        confirmationValue="DELETE"
        caseSensitive={false}
      />
    </>
  );
};

const getRandomColor = () => {
  const availableColors = [
    '#E0F2FE',
    '#0369A1',
    '#FEF3C7',
    '#B45309',
    '#ECFCCB',
    '#4D7C0F',
    '#D1FAE5',
    '#047857',
    '#E0E7FF',
    '#4338CA',
    '#FCE7F3',
    '#BE185D',
    '#FFE4E6',
    '#BE123C',
    '#F3E8FF',
    '#7E22CE',
    '#FFEDD5',
    '#C2410C',
    '#DBEAFE',
    '#1D4ED8',
    '#F0FDF4',
    '#15803D',
    '#FDF2F8',
    '#9D174D',
    '#F0FDFA',
    '#0F766E',
    '#FEF2F2',
    '#B91C1C',
    '#F8FAFC',
    '#334155',
  ];
  return availableColors[Math.floor(Math.random() * availableColors.length)];
};

const getTextColor = (bgColor: string) => {
  const hex = bgColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? '#000000' : '#ffffff';
};
