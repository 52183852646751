import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, TooltipTrigger } from '@/components/ui/tooltip';
import { Briefcase, Users, MessageSquare, Share2, MoreHorizontal } from 'lucide-react';
import { AssistantCountHover } from './AssistantCountHover';
import { AssistantActions } from './AssistantActions';
import { Assistant } from '@/types/assistant';
import { CountDisplay } from './CountDisplay';

const getTypeIcon = (type: string) => {
  switch (type) {
    case 'Business Development':
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Briefcase className="h-4 w-4 text-blue-500" />
          </TooltipTrigger>
        </Tooltip>
      );
    case 'Relationship Growth':
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Users className="h-4 w-4 text-green-500" />
          </TooltipTrigger>
        </Tooltip>
      );
    case 'Keeping in Touch':
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <MessageSquare className="h-4 w-4 text-yellow-500" />
          </TooltipTrigger>
        </Tooltip>
      );
    case 'Social Media':
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Share2 className="h-4 w-4 text-purple-500" />
          </TooltipTrigger>
        </Tooltip>
      );
    case 'Other':
    default:
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <MoreHorizontal className="h-4 w-4 text-gray-400" />
          </TooltipTrigger>
        </Tooltip>
      );
  }
};

type AssistantItemProps = {
  assistant: Assistant;
  onPauseToggle: (assistant: Assistant) => void;
  onDeleteClick: (assistant: Assistant) => void;
  onRename: (id: string, newName: string) => Promise<void>;
  onAssistantClick: (assistantId: string) => void;
  onSettingsClick?: (assistant: Assistant) => void;
  onAddContactsClick?: (assistant: Assistant) => void;
  selectedAssistantId: string | null;
  setSelectedAssistantId: (id: string | null) => void;
  showLabel?: boolean;
};

export const AssistantItem: React.FC<AssistantItemProps> = ({
  assistant,
  onPauseToggle,
  onDeleteClick,
  onRename,
  onAssistantClick,
  onSettingsClick,
  onAddContactsClick,
  selectedAssistantId,
  setSelectedAssistantId,
  showLabel = true,
}) => {
  const isSelected = selectedAssistantId === assistant._id;
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(assistant.name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleRenameClick = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    const newName = editedName.trim();
    if (newName && newName !== assistant.name) {
      await onRename(assistant._id, newName);
    } else {
      // Revert the name if unchanged
      setEditedName(assistant.name);
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedName(assistant.name);
    setIsEditing(false);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await handleSave();
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  const handleRowClick = () => {
    if (!isEditing) {
      setSelectedAssistantId(assistant._id);
      onAssistantClick(assistant._id);
    }
  };

  const handleSettings = onSettingsClick ? () => onSettingsClick(assistant) : undefined;
  const handleAddContacts = onAddContactsClick ? () => onAddContactsClick(assistant) : undefined;

  return (
    <div
      className={`group flex items-center gap-3 rounded-lg px-2 py-2 text-gray-500 transition-all cursor-pointer hover:text-gray-900 ${
        isSelected ? 'bg-gray-100' : 'hover:bg-gray-100'
      }`}
      onClick={handleRowClick}
    >
      {/* Show the icon based on assistant.type */}
      {getTypeIcon(assistant.type)}
      {showLabel && (
        <div className="flex-1 text-xs truncate font-medium">
          {isEditing ? (
            <input
              ref={inputRef}
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onBlur={handleSave}
              onKeyDown={handleKeyDown}
              className="w-full bg-transparent outline-none border-b border-dashed border-gray-300"
            />
          ) : (
            <span>{assistant.name}</span>
          )}
        </div>
      )}
      <AssistantActions
        assistant={assistant}
        onPauseToggle={assistant._id !== 'social-media' ? onPauseToggle : undefined}
        onDeleteClick={onDeleteClick}
        onRenameClick={handleRenameClick}
        onSettingsClick={handleSettings}
        onAddContactsClick={handleAddContacts}
      />

      {assistant.contacts && (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="inline-flex items-center">
              <CountDisplay count={assistant.contacts} />
            </div>
          </TooltipTrigger>
          <AssistantCountHover assistant={assistant} />
        </Tooltip>
      )}
    </div>
  );
};
