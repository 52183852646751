import React, { useMemo, useCallback } from 'react';
import PTypography from '../PTypography';
import { Providers, TopicType } from '@/constants/constant';
import PPostCardCommonContent from './PPostCardCommonContent';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useSelector } from 'react-redux';
import featureFlagService from '@/services/featureFlagService';
import { isLexicalHTML } from '@/utils/richTextUtils';
import ProxyImage from '@/components/ProxyImage';
import moment from 'moment';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';

const StyledLink = styled('a')({
  'color': '#0077b5',
  'textDecoration': 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

// Lookup configuration for providers
const PROVIDER_CONFIG = {
  [Providers.TWITTER]: {
    text: 'Tweet',
    Icon: null, // Add TwitterIcon if available
  },
  [Providers.LINKED_IN]: {
    text: 'LinkedIn Post',
    Icon: LinkedInIcon,
  },
};

// Utility function to calculate expiration time
const getExpirationTime = (removingAt) => {
  return moment(removingAt).fromNow();
};

/**
 * @typedef {Object} UploadDetails
 * @property {string} preSignedURL - The pre-signed URL for the image.

 * @typedef {Object} Post
 * @property {string} source - The source of the post, must be one of Providers.
 * @property {string} [message] - The message content of the post.
 * @property {string} [topic_type] - The type of the topic, must be one of TopicType.
 * @property {UploadDetails} [uploadDetails] - Details about the uploaded image.
 * @property {string} [articleImageUrl] - The URL of the article image.
 * @property {string} [createdAt] - The creation date of the post.
 * @property {boolean} [isDemoPost] - Flag indicating if the post is a demo.
 */

/**
 * SocialMediaPostCard component
 * @param {Object} props - Component properties.
 * @param {Post} props.post - The post data to display.
 * @param {Function} props.onClick - The function to call when the post is clicked.
 */
const SocialMediaPostCard = ({ post, onClick }) => {
  const { featureFlags } = useSelector((state) => ({
    featureFlags: state.auth.featureFlags,
  }));

  const isPRTEditorEnabled = useIsFeatureFlagEnabled('ui.richTextEditor');
  const determineMessageDisplay = useCallback(
    (msg) => {
      const isPRTEditorEnabled = featureFlagService.isFeatureEnabled('ui.richTextEditor', featureFlags);

      if (isPRTEditorEnabled && isLexicalHTML(msg)) {
        return <div dangerouslySetInnerHTML={{ __html: msg }} />;
      }

      const urlPattern = /(https?:\/\/[^\s]+)/g;
      const parts = msg.split(urlPattern);

      return (
        <PTypography size="body1" weight="regular" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {parts.map((part, index) => {
            if (part.match(urlPattern)) {
              return (
                <StyledLink
                  key={index}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(e);
                  }}
                >
                  {part}
                </StyledLink>
              );
            }
            return part;
          })}
        </PTypography>
      );
    },
    [featureFlags, onClick],
  );

  const headerConfig = PROVIDER_CONFIG[post.source] || {};

  const expirationTime = useMemo(
    () => (post.removingAt ? getExpirationTime(post.removingAt) : null),
    [post.removingAt],
  );

  const messageDisplay = useMemo(() => {
    if (isPRTEditorEnabled && isLexicalHTML(post.message)) {
      return <div dangerouslySetInnerHTML={{ __html: post.message }} />;
    }

    return (
      <div style={{ marginTop: '8px' }} onClick={onClick}>
        {determineMessageDisplay(post?.message)}
      </div>
    );
  }, [isPRTEditorEnabled, post.message, onClick, determineMessageDisplay]);

  const imageUrl = useMemo(() => {
    if (post.topic_type === TopicType.IMAGE_POST && post.uploadDetails?.preSignedURL) {
      return post.uploadDetails.preSignedURL;
    }
    return post.articleImageUrl;
  }, [post]);

  return (
    <PPostCardCommonContent
      post={post}
      headerNode={
        <div
          className={clsx(
            'w-full',
            'grid',
            'grid-cols-[auto,1fr,auto]',
            'gap-2',
            'items-center',
            'text-muted-foreground',
            'text-xs',
          )}
        >
          <div className="flex items-center gap-2">
            {headerConfig.Icon && <headerConfig.Icon className="h-5 w-5" />}
            <span className="font-medium">{headerConfig.text}</span>
          </div>

          {post.createdAt && (
            <>
              <time>{moment(post.createdAt).fromNow()}</time>
              <time>Expires {expirationTime}</time>
            </>
          )}
        </div>
      }
    >
      <div className="mt-2">{messageDisplay}</div>
      {imageUrl && (
        <ProxyImage className="mt-4 h-[200px] w-[350px] rounded object-cover" alt="imagepost" src={imageUrl} />
      )}
    </PPostCardCommonContent>
  );
};
export default SocialMediaPostCard;
