import { useState, useRef, useEffect } from 'react';
import { useTheme, Box, Typography, CircularProgress } from '@mui/material';
import PButton from '@/components/library/PButton';
import PTextfield from '@/components/library/PTextfield';
import { getUserPersona, updateUserPersona } from '@/helper/apiHelper';
import { useMediaQuery } from '@mui/material';

export default function Personas(props) {
  const theme = useTheme();
  const personaInputRef = useRef(null);
  const [persona, setPersona] = useState('');
  const [tempPersona, setTempPersona] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsLoading(true);
    getUserPersona()
      .then((response) => {
        const personaValue = response || '';
        setPersona(personaValue);
        setTempPersona(personaValue);
      })
      .catch((error) => {
        console.error('Error getting user persona:', error);
        setPersona('');
        setTempPersona('');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSave = async () => {
    if (persona.trim().length === 0) {
      return;
    }

    setIsSaving(true);
    try {
      await updateUserPersona(persona);
      setTempPersona(persona);
    } catch (error) {
      console.error('Error saving persona:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const hasChanges = persona !== tempPersona;

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Typography
        sx={{
          fontSize: '32px',
          fontWeight: 700,
          color: theme.palette.primary.black,
          pt: { xs: 5, md: 2 },
          textAlign: { xs: 'left' },
          pl: { xs: 5 },
          mt: { xs: 4 },
          lineHeight: 'normal',
        }}
      >
        Personas
      </Typography>

      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: theme.palette.primary.grey,
          pb: 5,
          pl: { xs: 5 },
          pr: { xs: 2 },
        }}
      >
        This will help you customize your messages to sound more like your writing style.
      </Typography>

      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: theme.palette.primary.black,
          pl: { xs: 5 },
        }}
      >
        Your Persona <span style={{ color: theme.palette.primary.orange }}>*</span>
      </Typography>

      <PTextfield
        ref={personaInputRef}
        value={persona}
        multiline
        maxRows={10}
        type="text"
        variant="standard"
        InputProps={{ disableUnderline: true }}
        placeholder="Enter your persona"
        sx={{ ml: 1, flex: 1 }}
        onChange={(e) => setPersona(e.target.value)}
        paperStyle={{
          mb: 3,
          width: { xs: '90vw', md: '80%' },
          ml: { xs: 5 },
        }}
      />

      {isMobile ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <PButton onClick={handleSave} disabled={!hasChanges || isSaving}>
            {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save'}
          </PButton>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10rem' }}>
          <PButton onClick={handleSave} disabled={!hasChanges || isSaving}>
            {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save'}
          </PButton>
        </Box>
      )}
    </>
  );
}
