import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import PLogo from './PLogo';
import PSideMenuUser from './PSideMenuUser';
import PSideMenuItem from './PSideMenuItem';
import VZeroSideMenuItem from '@/v0/VZeroSideMenuItem';

import { ReactComponent as SendIcon } from '../../assets/Icons/Send.svg';
import PLinkedInNoticeArea from './PLinkedInNoticeArea';
import { VZeroOnboardingCompletionPanel } from '@/v0/VZeroOnboardingCompletionPanel';
import { Plus } from 'lucide-react';

type MenuItem = {
  title: string;
  icon: React.ElementType;
  page: string;
  active: boolean;
  hasSubmenu?: boolean;
  onAdd?: () => void;
};

type User = {
  name: string;
  type: string;
};

type PSidebarProps = {
  pVariant?: 'primary';
  full?: boolean;
  user: User;
  postOnClick: () => void;
  menuItems: MenuItem[];
  menuOnClick: (title: string, hasSubmenu: boolean, page: string) => void;
  assistantsSection?: React.ReactNode;
  showWritePost?: boolean;
};

const getVariantStyles = (theme: any, pVariant: string) => {
  let colorScheme = {};
  switch (pVariant) {
    case 'primary':
    default:
      colorScheme = {
        background: theme.palette.primaryCL.White110,
        stroke: theme.palette.primaryCL.Black70,
      };
      break;
  }
  return colorScheme;
};

const StyledPDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, full }: { theme: any; pVariant: string; full: boolean }) => {
  const { background } = getVariantStyles(theme, pVariant);

  return {
    'flexShrink': 0,
    'minHeight': '50vh',
    'zIndex': 1,
    '& .MuiDrawer-paper': {
      width: full ? '300px' : '88px',
      backgroundColor: background,
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid',
      borderColor: '#E3E3E3',
      overflow: 'hidden',
    },
  };
});

const PSidebar: React.FC<PSidebarProps> = ({
  pVariant = 'primary',
  full = true,
  user,
  postOnClick,
  menuItems,
  menuOnClick,
  assistantsSection,
  showWritePost = false,
}) => {
  // Create a new assistantsSection with postOnClick if it exists
  const assistantsSectionWithProps = assistantsSection
    ? React.cloneElement(assistantsSection as React.ReactElement, { postOnClick })
    : null;

  return (
    <StyledPDrawer variant="permanent" disableElevation pVariant={pVariant} full={full}>
      <div className="flex flex-col h-full">
        <div className="pt-7 px-3">
          <div className={`px-5 pb-8 flex ${full ? 'justify-start' : 'justify-center'} w-full`}>
            <PLogo showLabel={full} />
          </div>
        </div>

        <div className="flex-1 overflow-y-auto px-3">
          {menuItems.map((item, index) => {
            if (item.title === 'Assistants' && assistantsSectionWithProps) {
              return <React.Fragment key="assistants-section">{assistantsSectionWithProps}</React.Fragment>;
            } else {
              return (
                <VZeroSideMenuItem
                  key={index}
                  active={item.active}
                  onClick={() => menuOnClick(item.title, item.hasSubmenu || false, item.page)}
                  showLabel={full}
                  Icon={item.icon}
                  onAdd={item.onAdd}
                >
                  {item.title}
                </VZeroSideMenuItem>
              );
            }
          })}
        </div>

        <div className="px-3">
          {showWritePost && (
            <div className="flex justify-center w-full py-4">
              <PSideMenuItem pVariant="black" onClick={postOnClick} showLabel={full} Icon={SendIcon} post={true}>
                Write Post
              </PSideMenuItem>
            </div>
          )}

          <VZeroOnboardingCompletionPanel />
          <PLinkedInNoticeArea />

          <div className="flex justify-center w-full pt-7 pb-7">
            <PSideMenuUser showLabel={full} children={user.name} subtitle={user.type} />
          </div>
        </div>
      </div>
    </StyledPDrawer>
  );
};

export default PSidebar;
