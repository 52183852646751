import React from 'react';
import { cn } from '@/lib/utils';
import PModalFloating from '@/components/library/PModalFloating';
import Settings from '@/pages/settings/settings';

const SettingsModal = ({ open, onClose }) => {
  return (
    <PModalFloating
      open={open}
      onClose={onClose}
      showCloseButton={true}
      headerText="Settings"
      floatingDimensions={{ width: '1024px', height: '90vh' }}
      padding="0"
    >
      <div className="flex-1 overflow-auto h-full bg-background">
        <Settings />
      </div>
    </PModalFloating>
  );
};

export default SettingsModal;
