import { createSlice, createAsyncThunk, createAction, PayloadAction } from '@reduxjs/toolkit';
import { generateOutreachSearchPayload } from '@/helper/apiHelper';
import {
  findContacts,
  getUniqueKnownByNames,
  convertCountryNameToCode,
} from '@/v0/OutreachLists/components/mockdataHelpers';
import { demoData } from '@/v0/OutreachLists/mockdata';
import { TITLES, INDUSTRIES, COMPANY_SIZES } from '@postilize/shared/constants/outreachConstants';

// Updated interfaces to match new data structure
export type ContactInfo = {
  _id: { $oid: string };
  name: string;
  linkedin_url: string | null;
  title: string;
  location: string;
};

export type Contact = {
  contactId: { $oid: string };
  relationshipScore: number;
  knownBy: string[];
  contactInfo: ContactInfo[];
};

export type CompanyData = {
  name: string;
  employees?: number;
  relationshipScore: number;
};

export type Company = {
  _id: { $oid: string };
  contacts: Contact[];
  companyData: CompanyData;
};

// Updated interface for search results
export type ContactWithCompany = {
  id: string;
  name: string;
  title: string;
  email?: string;
  company: string;
  location: string;
  knownBy: string[];
  relationshipScore: number;
  companyData?: CompanyData;
  linkedin_url?: string | null;
};

// Rest of the interfaces remain the same
type SavedList = {
  id: string;
  name: string;
  count: number;
};

type OutreachListsState = {
  searchCriteria: {
    query: string;
    selectedTitles: string[];
    selectedIndustries: string[];
    selectedSize: string[];
    location: string;
    knownBy: string[];
    company: string | null;
  };
  availableOptions: {
    titles: { [key: string]: string[] };
    industries: { [key: string]: string[] };
    companySizes: string[];
    knownByOptions: string[];
  };
  ui: {
    showCriteria: boolean;
    selectedContacts: string[];
    isSearching: boolean;
  };
  data: {
    searchResults: ContactWithCompany[];
    savedLists: SavedList[];
  };
  newlyAdded: {
    titles: string[];
    industries: string[];
    companySizes: string[];
  };
};

// Helper function to get unique industries from mock data
const getUniqueIndustries = (): string[] => {
  const industries = demoData.map((company) => company.companyData.industry);
  return [...new Set(industries)].filter(Boolean).sort();
};

// Updated initial state with new mock data structure
const initialState: OutreachListsState = {
  searchCriteria: {
    query: '',
    selectedTitles: [],
    selectedIndustries: [],
    selectedSize: [],
    location: '',
    knownBy: [],
    company: null,
  },
  availableOptions: {
    titles: TITLES,
    industries: INDUSTRIES,
    companySizes: COMPANY_SIZES,
    knownByOptions: getUniqueKnownByNames(),
  },
  ui: {
    showCriteria: false,
    selectedContacts: [],
    isSearching: false,
  },
  data: {
    searchResults: [],
    savedLists: [
      { id: '1', name: 'Toronto Tech CEOs', count: 45 },
      { id: '2', name: 'Bay Area Investors', count: 120 },
      { id: '3', name: 'European Founders', count: 75 },
    ],
  },
  newlyAdded: {
    titles: [],
    industries: [],
    companySizes: [],
  },
};

// Helper function to format large numbers with abbreviations
function abbreviateNumber(num: number): string {
  const abbreviations = [
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'K' },
  ];

  for (const { value, symbol } of abbreviations) {
    if (num >= value) {
      return (num / value).toFixed(0) + symbol;
    }
  }
  return num.toString();
}

// Main function to parse numerical ranges
function parseNumberRange(range: string): string {
  // Clean the input string
  const cleanRange = range.trim().replace(/\s+/g, '');

  // Regex pattern:
  // \d+          - Matches a single number
  // \d+-\d+      - Matches a range of numbers (e.g., "72-377")
  // <\d+         - Matches a number with a less-than sign (e.g., "<500")
  // >\d+         - Matches a number with a greater-than sign (e.g., ">500")
  // \d+\+        - Matches a number followed by a plus sign (e.g., "500+")
  // \d+>         - Matches a number with a greater-than sign (e.g., "500>")
  // \d+<         - Matches a number with a less-than sign (e.g., "500<")
  const validFormat = /^(\d+|\d+-\d+|<\d+|>\d+|\d+\+|\d+>|\d+<)$/;
  if (!validFormat.test(cleanRange)) {
    throw new Error(`Invalid company size: ${cleanRange}`);
  }

  // e.g. "<500" -> "1-500"
  // and "500>" -> "1-500"
  if (cleanRange.startsWith('<') || cleanRange.endsWith('>')) {
    const num = parseInt(cleanRange.replace(/[<>]/g, ''));
    return `1-${num}`;
  }

  // e.g. "500<" -> "500+"
  // and ">500" -> "500+"
  if (cleanRange.endsWith('<') || cleanRange.endsWith('>')) {
    const num = parseInt(cleanRange.replace(/[<>]/g, ''));
    return `${num}+`;
  }

  // return the original string since it must be one of:
  // - a single number, e.g. "500"
  // - a range of numbers, e.g. "72-377"
  // - a number followed by a plus sign, e.g. "500+"
  // which do not need to be formatted
  return cleanRange;
}

// Add a new action to reset search criteria
export const resetSearchCriteria = createAction('outreachLists/resetSearchCriteria');

// Updated search contacts thunk
export const searchContacts = createAsyncThunk(
  'outreachLists/searchContacts',
  async (query: string | undefined, { getState, dispatch }) => {
    const state = getState() as { outreachLists: OutreachListsState };
    const { availableOptions } = state.outreachLists;

    try {
      if (query) {
        // Get AI payload first
        const aiPayloadResponse = await generateOutreachSearchPayload(query);

        // Extract the AI payload
        const aiPayload = aiPayloadResponse.payload;

        // Reset all search criteria first
        dispatch(resetSearchCriteria());

        // Handle job titles
        if (aiPayload.jobTitle && Array.isArray(aiPayload.jobTitle)) {
          const [displayTitle, ...variations] = aiPayload.jobTitle;
          const desiredTitles = [displayTitle, ...variations];
          // Add or update the title mapping
          if (!availableOptions.titles[displayTitle]) {
            dispatch(addAvailableTitle({ displayTitle, variations: desiredTitles }));
          }
          // Select the display title
          dispatch(setSelectedTitles([displayTitle]));
        }

        // Handle industries
        if (aiPayload.industry && Array.isArray(aiPayload.industry)) {
          const [displayIndustry, ...variations] = aiPayload.industry;
          const desiredIndustries = [displayIndustry, ...variations];
          // Add or update the industry mapping
          if (!availableOptions.industries[displayIndustry]) {
            dispatch(
              addAvailableIndustry({
                displayIndustry,
                variations: desiredIndustries.length ? desiredIndustries : [],
              }),
            );
          }
          // Select the display industry
          dispatch(setSelectedIndustries([displayIndustry]));
        }

        // Handle location
        if (aiPayload.location) {
          const countryCode = convertCountryNameToCode(aiPayload.location.trim());
          dispatch(setLocation(countryCode));
        }

        // Handle company size
        if (aiPayload.companySize) {
          const formattedSize = parseNumberRange(aiPayload.companySize.trim());
          if (!availableOptions.companySizes.includes(formattedSize)) {
            dispatch(addAvailableCompanySize(formattedSize));
          }
          dispatch(setSelectedSize([formattedSize]));
        }

        // Handle company
        if (aiPayload.company) {
          dispatch(setCompany(aiPayload.company.trim()));
        }

        // After updating the state, perform the search
        const results = await dispatch(performSearch()).unwrap();

        return {
          results,
          aiPayload: null,
        };
      } else {
        // Regular search with current criteria
        const results = await dispatch(performSearch()).unwrap();
        return {
          results,
          aiPayload: null,
        };
      }
    } catch (error) {
      console.error('Search failed:', error);
      throw error;
    }
  },
);

// Add a new thunk for performing the search after criteria updates
export const performSearch = createAsyncThunk('outreachLists/performSearch', async (_, { getState }) => {
  const state = getState() as { outreachLists: OutreachListsState };
  const { searchCriteria, availableOptions } = state.outreachLists;

  const searchParams: any = {
    knownBy: searchCriteria.knownBy || undefined,
    companySize: searchCriteria.selectedSize[0],
    industries: searchCriteria.selectedIndustries,
    location: searchCriteria.location || undefined,
    selectedTitles: searchCriteria.selectedTitles,
    titleMappings: availableOptions.titles,
    industryMappings: availableOptions.industries,
  };

  const results = findContacts(searchParams);
  return results.map((contact) => ({
    id: contact.contactInfo?.[0]?._id.$oid || '',
    name: contact.contactInfo?.[0]?.name || '',
    title: contact.contactInfo?.[0]?.title || '',
    email: '',
    company: contact.companyData?.name || '',
    location: contact.contactInfo?.[0]?.location || '',
    knownBy: contact.knownBy || [],
    relationshipScore: contact.relationshipScore,
    companyData: contact.companyData,
    linkedin_url: contact.contactInfo?.[0]?.linkedin_url || null,
  }));
});

// Slice
const outreachListsSlice = createSlice({
  name: 'outreachLists',
  initialState,
  reducers: {
    setQuery(state, action: PayloadAction<string>) {
      state.searchCriteria.query = action.payload;
    },
    setSelectedTitles(state, action: PayloadAction<string[]>) {
      state.searchCriteria.selectedTitles = action.payload;
    },
    setSelectedIndustries(state, action: PayloadAction<string[]>) {
      state.searchCriteria.selectedIndustries = action.payload;
    },
    setSelectedSize(state, action: PayloadAction<string[]>) {
      state.searchCriteria.selectedSize = action.payload;
    },
    setKnownBy(state, action: PayloadAction<string[]>) {
      state.searchCriteria.knownBy = action.payload;
    },
    setCompany(state, action: PayloadAction<string | null>) {
      state.searchCriteria.company = action.payload;
    },
    setSelectedContacts(state, action: PayloadAction<string[]>) {
      state.ui.selectedContacts = action.payload;
    },
    setLocation(state, action: PayloadAction<string>) {
      state.searchCriteria.location = action.payload;
    },
    addAvailableTitle(state, action: PayloadAction<{ displayTitle: string; variations: string[] }>) {
      const { displayTitle, variations } = action.payload;
      if (!state.availableOptions.titles[displayTitle]) {
        state.availableOptions.titles[displayTitle] = variations;
      }
    },
    addAvailableIndustry(state, action: PayloadAction<{ displayIndustry: string; variations: string[] }>) {
      const { displayIndustry, variations } = action.payload;
      if (!state.availableOptions.industries[displayIndustry]) {
        state.availableOptions.industries[displayIndustry] = variations;
      }
    },
    addAvailableCompanySize(state, action: PayloadAction<string>) {
      if (!state.availableOptions.companySizes.includes(action.payload)) {
        state.availableOptions.companySizes.push(action.payload);
      }
    },
    selectAllContacts(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        // Select all contact IDs
        state.ui.selectedContacts = state.data.searchResults.map((contact) => contact.id);
      } else {
        // Deselect all contacts
        state.ui.selectedContacts = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchContacts.pending, (state) => {
        state.ui.showCriteria = true;
        state.ui.isSearching = true;
        state.data.searchResults = [];
      })
      .addCase(searchContacts.fulfilled, (state, action) => {
        state.ui.isSearching = false;
        state.data.searchResults = action.payload.results;
      })
      .addCase(searchContacts.rejected, (state) => {
        state.ui.isSearching = false;
        state.data.searchResults = [];
      })
      .addCase(performSearch.fulfilled, (state, action) => {
        state.data.searchResults = action.payload;
      })
      .addCase(resetSearchCriteria, (state) => {
        state.searchCriteria = {
          query: '',
          selectedTitles: [],
          selectedIndustries: [],
          selectedSize: [],
          location: '',
          knownBy: [],
          company: null,
        };
      });
  },
});

// Export actions
export const {
  setQuery,
  setSelectedTitles,
  setSelectedIndustries,
  setSelectedSize,
  setKnownBy,
  setCompany,
  setSelectedContacts,
  setLocation,
  addAvailableTitle,
  addAvailableIndustry,
  addAvailableCompanySize,
  selectAllContacts,
} = outreachListsSlice.actions;

// Export reducer
export default outreachListsSlice.reducer;
