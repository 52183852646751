import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authReducer from './authSlice';
import sequenceReviewReducer from './sequenceReviewSlice';
import outreachListsReducer from './outreachListsSlice';
import { saveStateMiddleware, loadPersistedState } from './middlewares/saveStateMiddleware';
import clientReducer from './slices/ERMDashboard/clientSlice';
import contactReducer from './slices/ERMDashboard/contactSlice';
import relationshipReducer from './slices/ERMDashboard/relationshipSlice';
import dashboardReducer from './slices/ERMDashboard/dashboardSlice';
import interactionReducer from './slices/ERMDashboard/interactionSlice';

import assistanceReducer from './slices/Assistance/assistanceSlice';
// Load persisted state
const preloadedState = loadPersistedState();

const reducers = combineReducers({
  auth: authReducer,
  sequenceReview: sequenceReviewReducer,
  dashboard: dashboardReducer,
  outreachLists: outreachListsReducer,
  clientTable: clientReducer,
  contactTable: contactReducer,
  relationshipTable: relationshipReducer,
  interactions: interactionReducer,
  assistance: assistanceReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveStateMiddleware),
  preloadedState,
});
