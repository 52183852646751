// client/src/v0/Assistants/AssistantsSection.tsx

import React, { useState, useEffect, useCallback } from 'react';
import BrandTopics from 'pages/setup/simpleSetupComponents/Brands/brandTopics';
import { motion } from 'framer-motion';
import {
  Bot,
  ChevronDown,
  Plus,
  // from feature branch icons below if you use them:
  // ChevronRight,
  // User,
  Briefcase,
  Share2,
  TrendingUp,
  Users,
  FileCheck,
  UserCircle2,
  ArrowUpRight,
} from 'lucide-react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { TooltipProvider } from '@/components/ui/tooltip'; // Kept from HEAD
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog';
import { cn } from 'lib/utils';
import SequenceModal from 'pages/ClientAcquisition/components/addSequenceModal/sequenceModal.js';
import EditModal from 'pages/ClientAcquisition/components/sequenceSettingsModal/edit.js';
import AddContactsModal from 'pages/ClientAcquisition/components/addContactsModal';
import { useAssistance } from 'hooks/useAssistance';
import { Assistant } from 'types/assistant';
import { useSelector, useDispatch } from 'react-redux';
import { setUserTopics } from 'redux/authSlice';

import { AssistantItem } from './components/AssistantItem';
import { TypeAccordion } from './components/TypeAccordion';
import { updateTopics } from '@/helper/apiHelper'; // Keep HEAD path alias
import { AssistantsFilter } from './components/AssistantsFilter'; // Kept from HEAD

type AssistantsSectionProps = {
  isSequencesEnabled: boolean;
  // Props from HEAD:
  groupByType: boolean;
  setGroupByType: (value: boolean) => void;
  expandedTypes: string[];
  setExpandedTypes: (values: string[]) => void;
  onPauseToggle: (assistant: Assistant) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  onRename: (id: string, newName: string) => Promise<void>;
  assistants: Assistant[];
  onAssistantClick: (assistantId: string) => void;
  currentPage: string;
  onRefreshClientAcquisition?: () => void; // from HEAD
  // Prop from feature branch:
  postOnClick?: () => void;
  typeLabel: string;
  typeIcon: React.ComponentType<any>;
  desiredType?: string;
};

// Animations from HEAD
const chevronAnimation = {
  expanded: { rotate: 0, transition: { duration: 0.3 } },
  collapsed: { rotate: 180, transition: { duration: 0.3 } },
};

const contentAnimation = {
  expanded: { opacity: 1, height: 'auto', transition: { duration: 0.3 } },
  collapsed: { opacity: 0, height: 0, transition: { duration: 0.3 } },
};

function getGoalIdFromType(assistantType: string): string {
  switch (assistantType) {
    case 'Business Development':
      return 'acquire';
    case 'Relationship Growth':
      return 'retain';
    case 'Social Media':
      return 'grow';
    default:
      return 'acquire';
  }
}

const AssistantsSection: React.FC<AssistantsSectionProps> = ({
  isSequencesEnabled,
  groupByType,
  setGroupByType,
  expandedTypes,
  setExpandedTypes,
  onPauseToggle,
  onDelete,
  onRename,
  assistants,
  onAssistantClick,
  currentPage,
  onRefreshClientAcquisition,
  postOnClick,
  typeLabel,
  typeIcon: TypeIcon,
  desiredType,
}) => {
  const {
    modalOpen,
    closeSequenceModal,
    openSequenceModal,
    createdSequenceId,
    setCreatedSequenceId,
    defaultTemplates,
    stepText,
    strategies,
    targetAudience,
    availableList,
    initialLoading,
    strategyLoading,
    initialListLoading,
    fetchAssistantsData,
    handleAssistantEdit,
    filteredAndSortedAssistants,
  } = useAssistance();

  const [selectedAssistantId, setSelectedAssistantId] = useState<string | null>(null);
  const [mainAccordionValue, setMainAccordionValue] = useState<string[]>(['assistants']);
  const [assistantToDelete, setAssistantToDelete] = useState<Assistant | null>(null);
  const [assistantToToggle, setAssistantToToggle] = useState<Assistant | null>(null);

  // HEAD logic for 'groupByType'
  const [groupedAssistants, setGroupedAssistants] = useState<[string, Assistant[]][]>([]);

  const [editOpen, setEditOpen] = useState(false);
  const [assistantToEdit, setAssistantToEdit] = useState<Assistant | null>(null);
  const userTopics = useSelector((state: any) => state.auth.user.topics);
  const dispatch = useDispatch();

  const [addContactsOpen, setAddContactsOpen] = useState(false);
  const [assistantForContacts, setAssistantForContacts] = useState<Assistant | null>(null);

  // From feature branch for brand topics
  const [topicsModalOpen, setTopicsModalOpen] = useState(false);
  const [topics, setTopics] = useState<any>();

  useEffect(() => {
    const storedMain = localStorage.getItem(`assistantsMainAccordion_${typeLabel}`);
    if (storedMain) {
      try {
        const val = JSON.parse(storedMain);
        if (Array.isArray(val)) {
          setMainAccordionValue(val);
        }
      } catch (error) {
        console.error('Failed to parse assistantsMainAccordion:', error);
      }
    }
  }, [typeLabel]);

  const handleSettingsClick = useCallback(
    async (assistant: Assistant) => {
      try {
        let full = assistant;
        if (!assistant.template) {
          const fetched = await handleAssistantEdit(assistant._id);
          if (fetched) full = fetched;
        }
        setAssistantToEdit(full);
        setEditOpen(true);
      } catch (error) {
        console.error('Error handleSettingsClick:', error);
      }
    },
    [handleAssistantEdit],
  );

  const handleAddContactsClick = useCallback((assistant: Assistant) => {
    setAssistantForContacts(assistant);
    setAddContactsOpen(true);
  }, []);

  const refreshAfterChange = useCallback(
    (id?: string) => {
      fetchAssistantsData();
      if (id) onAssistantClick(id);

      // HEAD's optional refresh:
      if (onRefreshClientAcquisition) {
        onRefreshClientAcquisition();
      }
    },
    [fetchAssistantsData, onAssistantClick, onRefreshClientAcquisition],
  );

  // Build grouped data from HEAD
  useEffect(() => {
    const finalList = filteredAndSortedAssistants();
    const groups: Record<string, Assistant[]> = {};

    for (const assistant of finalList) {
      if (!groups[assistant.type]) groups[assistant.type] = [];
      groups[assistant.type].push(assistant);
    }

    // Add Social Media group if user has topics
    if (userTopics && userTopics.length > 0) {
      groups['Social Media'] = [
        {
          _id: 'social-media',
          name: 'Social Media Assistant',
          status: 'Active',
          type: 'Social Media',
        },
      ];
    }

    setGroupedAssistants(Object.entries(groups));
  }, [filteredAndSortedAssistants, userTopics]);

  // Reset selected assistant when page changes
  useEffect(() => {
    if (currentPage !== 'acquisition') {
      setSelectedAssistantId(null);
    }
  }, [currentPage]);

  // When a new assistant is created
  useEffect(() => {
    if (createdSequenceId && assistants.length > 0) {
      const newAsst = assistants.find((a) => a._id === createdSequenceId);
      if (newAsst) {
        setSelectedAssistantId(newAsst._id);
        onAssistantClick(newAsst._id);
      }
    }
  }, [createdSequenceId, assistants, onAssistantClick, setCreatedSequenceId]);

  if (!isSequencesEnabled) return null;

  // HEAD code for storing expanded accordion
  const handleMainAccordionChange = (value: string[]) => {
    setMainAccordionValue(value);
    localStorage.setItem(`assistantsMainAccordion_${typeLabel}`, JSON.stringify(value));
  };

  const handleTypeAccordionChange = (value: string[]) => {
    setExpandedTypes(value);
    localStorage.setItem('assistantsTypeAccordion', JSON.stringify(value));
  };

  const handleDeleteClick = (assistant: Assistant) => {
    setAssistantToDelete(assistant);
  };

  const handleConfirmDelete = async () => {
    if (assistantToDelete) {
      // Special handling for Social Media assistant
      try {
        if (assistantToDelete.type === 'Social Media') {
          dispatch(setUserTopics([]));
          await updateTopics([]);
          // Then delete from DB
          await onDelete(assistantToDelete._id);
        } else {
          await onDelete(assistantToDelete._id);
        }
      } catch (error) {
        console.error('Error deleting assistant:', error);
      }

      setAssistantToDelete(null);
      refreshAfterChange();
    }
  };

  const handleCancelDelete = () => {
    setAssistantToDelete(null);
  };

  // Handle Pause/Resume
  const handlePauseToggleClick = (assistant: Assistant) => {
    setAssistantToToggle(assistant);
  };

  const handleConfirmPauseToggle = async () => {
    if (assistantToToggle) {
      await onPauseToggle(assistantToToggle);
      setAssistantToToggle(null);
      refreshAfterChange();
    }
  };

  const handleCancelPauseToggle = () => {
    setAssistantToToggle(null);
  };

  const handleCreateNewAssistant = (e: React.MouseEvent) => {
    e.stopPropagation();
    const strategyGoalId = getGoalIdFromType(desiredType);
    openSequenceModal(strategyGoalId);
  };

  return (
    <TooltipProvider>
      <div>
        {/* Delete Confirmation */}
        <AlertDialog
          open={assistantToDelete !== null}
          onOpenChange={(open) => {
            if (!open) setAssistantToDelete(null);
          }}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete the assistant{' '}
                <strong>{assistantToDelete?.name}</strong>.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleCancelDelete}>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleConfirmDelete}>Delete</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Pause/Resume Confirmation */}
        <AlertDialog
          open={assistantToToggle !== null}
          onOpenChange={(open) => {
            if (!open) setAssistantToToggle(null);
          }}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                {assistantToToggle?.status === 'Paused' ? 'Confirm Resume' : 'Confirm Pause'}
              </AlertDialogTitle>
              <AlertDialogDescription>
                {assistantToToggle?.status === 'Paused' ? (
                  <>
                    Are you sure you want to resume the assistant <strong>{assistantToToggle?.name}</strong>?
                  </>
                ) : (
                  <>
                    Are you sure you want to pause the assistant <strong>{assistantToToggle?.name}</strong>?
                  </>
                )}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={handleCancelPauseToggle}>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleConfirmPauseToggle}>
                {assistantToToggle?.status === 'Paused' ? 'Resume' : 'Pause'}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Main Accordion (HEAD style) */}
        <AccordionPrimitive.Root type="multiple" value={mainAccordionValue} onValueChange={handleMainAccordionChange}>
          <AccordionPrimitive.Item value="assistants">
            <AccordionPrimitive.Header className="flex">
              <AccordionPrimitive.Trigger
                className={cn(
                  'flex flex-1 items-center justify-between gap-l-2 rounded-lg px-4 py-[14px] text-sm hover:bg-gray-100',
                  selectedAssistantId && currentPage === 'acquisition' ? 'bg-gray-100' : 'text-gray-600',
                )}
              >
                <div className="flex items-center gap-2">
                  <TypeIcon className="h-[18px] w-[18px]" />
                  <span className={`text-sm font-bold ${selectedAssistantId ? 'text-gray-900' : ''}`}>{typeLabel}</span>
                  <motion.div
                    animate={mainAccordionValue.includes('assistants') ? 'expanded' : 'collapsed'}
                    variants={chevronAnimation}
                    className="shrink-0"
                  >
                    <ChevronDown className="h-5 w-5 transition-transform duration-200" />
                  </motion.div>
                </div>
                <div className="flex items-center gap-2">
                  {/* AssistantsFilter from HEAD */}
                  {/* <div onClick={(e) => e.stopPropagation()} className="my-2 flex items-end justify-end">
                    <AssistantsFilter />
                  </div> */}
                  {/* + Button to create a new Assistant */}
                  <Plus className="h-5 w-5 cursor-pointer" onClick={handleCreateNewAssistant} />
                </div>
              </AccordionPrimitive.Trigger>
            </AccordionPrimitive.Header>
            <AccordionPrimitive.Content forceMount asChild>
              <motion.div
                initial="collapsed"
                animate={mainAccordionValue.includes('assistants') ? 'expanded' : 'collapsed'}
                variants={contentAnimation}
                className="overflow-hidden"
              >
                <div className="ml-4 space-y-2">
                  {groupByType ? (
                    // Grouped by type => show sub-accordions
                    <AccordionPrimitive.Root
                      type="multiple"
                      value={expandedTypes}
                      onValueChange={handleTypeAccordionChange}
                    >
                      {groupedAssistants.map(([type, typeAssistants]) => {
                        const isOpen = expandedTypes.includes(`type-${type}`);
                        // We skip Settings or AddContacts for the placeholder "Social Media" pseudo-assistant:
                        const localOnSettingsClick = type === 'Social Media' ? undefined : handleSettingsClick;
                        const localOnAddContactsClick = type === 'Social Media' ? undefined : handleAddContactsClick;

                        return (
                          <TypeAccordion
                            key={type}
                            type={type}
                            assistants={typeAssistants}
                            isOpen={isOpen}
                            onPauseToggle={handlePauseToggleClick}
                            onDeleteClick={handleDeleteClick}
                            onRename={onRename}
                            onAssistantClick={onAssistantClick}
                            onSettingsClick={localOnSettingsClick}
                            onAddContactsClick={localOnAddContactsClick}
                            selectedAssistantId={selectedAssistantId}
                            setSelectedAssistantId={setSelectedAssistantId}
                          />
                        );
                      })}
                    </AccordionPrimitive.Root>
                  ) : (
                    // If not grouped, just list them
                    <div>
                      {groupedAssistants
                        .filter(([type]) => type === desiredType)
                        .flatMap(([_, typeAssistants]) => typeAssistants)
                        .map((assistant) => (
                          <AssistantItem
                            key={assistant._id}
                            assistant={assistant}
                            onPauseToggle={handlePauseToggleClick}
                            onDeleteClick={handleDeleteClick}
                            onRename={onRename}
                            onAssistantClick={onAssistantClick}
                            onSettingsClick={() => handleSettingsClick(assistant)}
                            onAddContactsClick={() => handleAddContactsClick(assistant)}
                            selectedAssistantId={selectedAssistantId}
                            setSelectedAssistantId={setSelectedAssistantId}
                          />
                        ))}
                    </div>
                  )}
                </div>
              </motion.div>
            </AccordionPrimitive.Content>
          </AccordionPrimitive.Item>
        </AccordionPrimitive.Root>

        {/* Sequence Modal (HEAD) */}
        {!initialLoading && !strategyLoading && !initialListLoading && (
          <SequenceModal
            open={modalOpen}
            onClose={closeSequenceModal}
            setCreatedSequenceId={setCreatedSequenceId}
            templates={defaultTemplates}
            templateSteps={stepText}
            strategies={strategies}
            targetAudience={targetAudience}
            availableList={availableList}
            selectedGoal={{ goal: { id: getGoalIdFromType(desiredType) } }}
          />
        )}

        {/* Edit Steps Modal (HEAD) */}
        {editOpen && assistantToEdit && (
          <EditModal
            open={editOpen}
            onClose={() => {
              setEditOpen(false);
              setAssistantToEdit(null);
            }}
            activeItem={assistantToEdit}
            // HEAD code: template => assistantToEdit.template
            // BUT from feature code: we passed entire assistant as template
            // If your code needs it exactly from HEAD, do:
            template={assistantToEdit.template}
            onRefresh={(seqId) => refreshAfterChange(seqId)}
          />
        )}

        {/* Add Contacts Modal (HEAD) */}
        {addContactsOpen && assistantForContacts && (
          <AddContactsModal
            open={addContactsOpen}
            onClose={() => {
              setAddContactsOpen(false);
              setAssistantForContacts(null);
            }}
            source="sequence"
            sequenceItem={assistantForContacts}
            handleModification={() => {
              setAddContactsOpen(false);
              setAssistantForContacts(null);
              refreshAfterChange(assistantForContacts._id);
            }}
            targetAudience={targetAudience}
            existingList={availableList}
          />
        )}

        {/* Brand Topics Modal (from feature branch) */}
        {topicsModalOpen && (
          <BrandTopics
            open={topicsModalOpen}
            onClose={() => setTopicsModalOpen(false)}
            source="ComposePost"
            selectedTopicsSetter={setTopics}
            initialSelectedTopics={topics}
            isLoading={false}
          />
        )}
      </div>
    </TooltipProvider>
  );
};

export default AssistantsSection;
