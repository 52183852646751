import React, { forwardRef } from 'react';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const ERMPagination = forwardRef<HTMLElement, PaginationProps>(
  ({ currentPage, totalPages, onPageChange }, ref) => {
    const getPageNumbers = () => {
      const pageRange = 1;
      const pages = [];
      const startPage = Math.max(2, currentPage - pageRange);
      const endPage = Math.min(totalPages - 1, currentPage + pageRange);

      pages.push(1);

      if (startPage > 2) {
        pages.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) {
        pages.push('...');
      }

      if (totalPages > 1) {
        pages.push(totalPages);
      }

      return pages;
    };

    return (
      <div className="mt-4 flex justify-center" ref={ref}>
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                href="#"
                onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1 || totalPages === 1}
              />
            </PaginationItem>
            {getPageNumbers().map((page, index) => (
              <PaginationItem key={index}>
                {typeof page === 'number' ? (
                  <PaginationLink href="#" isActive={currentPage === page} onClick={() => onPageChange(page)}>
                    {page}
                  </PaginationLink>
                ) : (
                  <PaginationEllipsis />
                )}
              </PaginationItem>
            ))}
            <PaginationItem>
              <PaginationNext
                href="#"
                onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
                disabled={currentPage === totalPages || totalPages === 1}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    );
  },
);
