import React, { useState, useEffect } from 'react';
import {
  Box,
  MenuItem,
  Select,
  CircularProgress,
  Button,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

import Http from '../../../../http/httpClient';

export default function LinkedInPostEngagementScraping() {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');
  const [triggerJobButtonClicked, setTriggerJobButtonClicked] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      Http.getData('users/list')
        .then((res) => {
          const resUsers = [];
          res.users.map((item) => {
            resUsers.push({ id: item._id.toString(), name: item.name, email: item.email });
          });
          setUsers(resUsers);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUsersLoading(false);
        });
    };

    fetchAvailableUsers();
  }, []);

  const queueLinkedInJob = async () => {
    setTriggerJobButtonClicked(true);
    await Http.postData('admin/triggerScrapeUserPosts', { userId: selectedUser });
    handleDialogClose();
  };

  const handleUserChange = (e) => {
    setSelectedUser((prev) => e.target.value);
  };

  const handleDialogOpen = () => {
    setShowConfirmation(true);
  };

  const handleDialogClose = () => {
    setShowConfirmation(false);
  };

  const detailedPersonLabel = (userId) => {
    const user = users.find((u) => u.id === userId);
    if (!user) return `Could not find '${userId}'`;
    return `${user.name} - ${user.email}`;
  };

  return (
    <>
      <h3>LinkedIn Post Engagement Scraping</h3>
      <Box display="flex" flexDirection="column" gap={2} alignItems="flex-start" sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {usersLoading ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '52px',
                  height: '34px',
                  display: 'flex',
                  borderRadius: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <>
                <Select value={selectedUser} onChange={handleUserChange} sx={{ minWidth: 200 }}>
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name} - {user.email}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={handleDialogOpen}
                  disabled={selectedUser.length === 0}
                >
                  Queue LinkedIn Scraping Job for this User
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={showConfirmation}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Run LinkedIn Post Scraping Job for this user?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to run LinkedIn Post Scraping for this user?
            <br />
            {detailedPersonLabel(selectedUser)}
            <br />
            <br />
            <FormHelperText sx={{ color: 'red' }}>
              * It it NOT advisable to run this against a production user. Running this adhoc job might cause the user
              to be rate limited by LinkedIn. Please consult with Engineering staff before continuing.
            </FormHelperText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="info" variant="contained">
            Cancel
          </Button>
          <Button onClick={queueLinkedInJob} autoFocus color="error" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
