import React from 'react';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { InteractionIcons } from './InteractionIcons';
import moment from 'moment';

type InteractionCellProps = {
  interaction: {
    messagingPlatform?: string;
    messagingTraffic?: string;
    actionDate?: string;
    user?: {
      name?: string;
    };
    actionData?: {
      notes?: string;
    };
    style?: React.CSSProperties;
    userName?: string;
  };
};

const formatDate = (date: string) => moment(date).format('YYYY-MM-DD');

const InteractionDetailsTooltip = ({ interaction }: { interaction: any }) => {
  if (!interaction?.actionDate && !(interaction?.user?.name || interaction?.userName)) return 'No interaction recorded';

  const platformTextMap: { [key: string]: string } = {
    linkedin: 'LinkedIn message',
    email: 'Email',
    meeting: 'Meeting',
    calendar: 'Calendar invitation',
  };

  const trafficText = (traffic: string, platform: string) => {
    if (interaction?.action === 'addedToSequence') return '';

    if (platform === 'meeting') return 'attended a';
    if (platform === 'calendar') return 'received a';
    if (traffic === 'incoming') return `received ${platform === 'email' ? 'an ' : 'a '}`;
    if (traffic === 'outgoing') return `sent ${platform === 'email' ? 'an ' : 'a '}`;
    return platform === 'linkedin' || platform === 'email' ? 'communicated via' : 'communicated on';
  };

  const platformText =
    interaction?.action === 'addedToSequence' ? (
      <>
        <span className="text-muted-foreground">added to a</span> <span className="font-semibold">Sequence</span>
      </>
    ) : (
      platformTextMap[interaction?.messagingPlatform] || 'an unspecified platform'
    );

  return (
    <div className="bg-popover rounded-md overflow-hidden shadow-lg">
      <div className="bg-muted px-4 py-2 font-semibold text-foreground">Interaction Details</div>
      <div className="p-4 space-y-2">
        <p className="text-sm whitespace-normal">
          <span className="font-semibold">{interaction?.user?.name || interaction?.userName}</span>{' '}
          <span className="text-muted-foreground">
            {trafficText(interaction?.messagingTraffic, interaction?.messagingPlatform)}
          </span>{' '}
          <span className="font-semibold">{platformText}</span> <span className="text-muted-foreground">on</span>{' '}
          <span className="font-semibold">{formatDate(interaction?.actionDate)}</span>
        </p>
      </div>
    </div>
  );
};

export const InteractionsCell = ({ interaction }: InteractionCellProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center gap-2 cursor-pointer">
            <InteractionIcons interaction={interaction} />
          </div>
        </TooltipTrigger>
        <TooltipContent className="w-64 p-0">
          <InteractionDetailsTooltip interaction={interaction} />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
