import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import PButton from '@/components/library/PButton';
import { Target } from 'lucide-react';
import PModal from '@/components/library/PModal';

type BrandingAssistantCreatedProps = {
  onClose: () => void;
};

const BrandingAssistantCreated: React.FC<BrandingAssistantCreatedProps> = ({ onClose }) => {
  const theme = useTheme();

  const handleContinue = () => {
    onClose({ fullClose: true });
  };

  const handleOnClose = () => {
    onClose({ fullClose: true });
  };

  return (
    <PModal
      open
      onClose={handleOnClose}
      floating
      floatingDimensions={{ width: '450px', height: 'auto' }}
      ultraWideContent
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" padding={4}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={72}
          height={72}
          borderRadius="50%"
          bgcolor={theme.palette.primaryCL.Blue50}
        >
          <Target color={theme.palette.primaryCL.Blue100} size={36} />
        </Box>
        <Typography
          variant="h3"
          fontWeight={700}
          color={theme.palette.primaryCL.Black200}
          marginTop={3}
          textAlign="center"
        >
          Your Social Media Assistant is Ready!
        </Typography>
        <Typography variant="body1" color={theme.palette.primaryCL.Black100} marginTop={2} textAlign="center">
          Your new assistant will <b>take a few minutes</b> to automatically draft posts for your LinkedIn account and
          help you build your social media presence. <b>Visit the queue</b> to see your drafted posts once they are
          created for your approval.
        </Typography>
        <PButton
          pVariant="primary"
          onClick={handleContinue}
          sx={{
            'marginTop': 4,
            'textTransform': 'uppercase',
            'bgcolor': theme.palette.primaryCL.Blue100,
            'color': theme.palette.primaryCL.White100,
            'fontSize': '16px',
            '&:hover': {
              bgcolor: theme.palette.primaryCL.Blue90,
            },
          }}
        >
          Continue to Home/Queue
        </PButton>
      </Box>
    </PModal>
  );
};

export default BrandingAssistantCreated;
