import React, { useRef, useEffect, useState } from 'react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface StageBadgeProps {
  stageId: string;
  initialText: string;
  backgroundColor: string;
  textColor: string;
  isEditing: boolean;
  editable: boolean;
  onClick: () => void;
  onBlur: () => void;
  onChange: (newText: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

export const StageBadge: React.FC<StageBadgeProps> = ({
  stageId,
  initialText,
  backgroundColor,
  textColor,
  isEditing,
  editable,
  onClick,
  onBlur,
  onChange,
  onKeyDown,
  maxLength = 100,
}) => {
  const [text, setText] = useState(initialText?.slice(0, maxLength) || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const measureRef = useRef<HTMLSpanElement>(null);
  const textCursorPositionRef = useRef<number | null>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      const cursorPosition = textCursorPositionRef.current ?? text.length;
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [isEditing, text]);

  // useEffect(() => {
  //   if (inputRef.current && textCursorPositionRef.current !== null) {
  //     inputRef.current.setSelectionRange(textCursorPositionRef.current, textCursorPositionRef.current);
  //   }
  // });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = e.target.selectionStart;
    textCursorPositionRef.current = cursorPosition;
    const newText = e.target.value.slice(0, maxLength);

    // prevent the letters at the end from being truncated when the max length is reached
    // and the user types into the middle of the text.
    if (text.length >= maxLength && newText.length >= maxLength) {
      return;
    }

    if (newText.length <= text.length || newText.length <= maxLength) {
      setText(newText);
      onChange(newText);
    }
  };

  const handleBadgeClick = () => {
    onClick?.();
  };

  return (
    <div className="flex items-center pointer-events-none">
      <Badge
        variant="outline"
        className="p-0 shadow-sm transition-shadow hover:shadow-md pointer-events-auto"
        style={{
          height: 'auto',
          backgroundColor,
          color: textColor,
          borderColor: 'transparent',
        }}
      >
        <div onClick={handleBadgeClick} className="relative px-2 py-0.5">
          <input
            ref={inputRef}
            type="text"
            value={stageId === 'no-stage' ? 'No stage' : text}
            onChange={handleChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            className={cn(
              'absolute',
              'top-0',
              'left-2',
              'w-full',
              'h-full',
              'bg-transparent',
              'outline-none',
              'font-medium',
              'tracking-tight',
            )}
            style={{
              color: textColor,
              caretColor: textColor,
              cursor: editable ? 'text' : 'pointer',
            }}
            readOnly={!editable}
          />
          <span
            style={{
              zIndex: 1,
            }}
            ref={measureRef}
            className="invisible inline-block whitespace-pre font-medium tracking-tight"
          >
            {stageId === 'no-stage' ? 'No stage' : text}
          </span>
        </div>
      </Badge>
    </div>
  );
};
