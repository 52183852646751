import React from 'react';
import './authSuccess.css';

const AuthSuccess = () => {
  React.useEffect(() => {
    // Multiple methods to detect if this is a popup window
    const isPopup = [
      // Method 1: Check window.opener
      window.opener && window.opener !== window,
      // Method 2: Check window dimensions
      window.innerWidth < 800 || window.innerHeight < 600,
      // Method 3: Check window features
      !window.menubar?.visible,
      // Method 4: Check if window name contains 'popup'
      window.name.toLowerCase().includes('popup'),
    ].some((check) => check === true);

    // Wait for animation to complete before redirecting/closing
    setTimeout(() => {
      if (isPopup) {
        window.close();
      } else {
        window.location.href = '/main';
      }
    }, 1000); // 1 second delay to match animation duration
  }, []);

  return (
    <div className="centered-container">
      <div className="tick-container">
        <div className="tick-icon">
          <div className="tick-line tick-line1"></div>
          <div className="tick-line tick-line2"></div>
        </div>
      </div>
    </div>
  );
};

export default AuthSuccess;
