import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Stack from '@mui/system/Stack';
import Snackbar from '@mui/material/Snackbar';
import { PTextfield, PButton, PRTEditor } from '@Library';
import { useFeatureFlags } from '../../hooks/useFeatureFlags'; // Import the useFeatureFlags hook

import { ReactComponent as ArrowLeft } from '../../assets/Icons/Arrow-Left.svg';
import { ReactComponent as Check } from '../../assets/Icons/Check.svg';
import Http from '../../http/httpClient';
import { ordinalSuffixOf } from '../../helper/utilities';
import { replaceEmailBodyPlaceholders, replaceEmailSubjectPlaceholders } from '../../helper/emailUtility';
import { StepRecurringTimings } from '../../constants/constant';
import { $generateHtmlFromNodes } from '@lexical/html';

export default function EmailModal(props) {
  const theme = useTheme();
  const { onClose, open, step, setupList, isEditEmail, listItem, readOnly, contact, userBio } = props;
  const [subjectLine, setSubjectLine] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [emailBody, setEmailBody] = React.useState(null);
  const copyText = [
    'Their full name',
    'Their first name',
    'Their last name',
    'Their title',
    'Their company',
    'My name',
    'My company',
  ];
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  const [selectedTone, setSelectedTone] = React.useState(null);
  const [emailTemplateIndex, setEmailTemplateIndex] = React.useState(0);
  const [emailTemplates, setEmailTemplates] = React.useState([]);
  const editorRef = React.useRef(null);

  const featureFlags = useFeatureFlags();
  const isRichTextEditorEnabled = featureFlags.some(
    (flag) => flag.name === 'ui.richTextEditor' && flag.isFeatureEnabled,
  );

  const handleToneChange = (event, newTone) => {
    setSelectedTone(newTone);
  };

  const [selectedLength, setSelectedLength] = React.useState(null);
  const handleLengthChange = (event, newLength) => {
    setSelectedLength(newLength);
  };

  const saveEmail = () => {
    const isSubjectLineEmpty = !subjectLine || subjectLine.trim() === '';
    const isEmailBodyEmpty = !emailBody || emailBody.trim() === '';

    const message =
      isSubjectLineEmpty && isEmailBodyEmpty
        ? 'Please fill required fields to save'
        : isSubjectLineEmpty
          ? 'Please fill subject to save'
          : isEmailBodyEmpty
            ? 'Please fill body to save'
            : '';
    if (message !== '') {
      setShowAlert(true);
      setAlertMessage(message);
      return;
    }

    if (isEditEmail) {
      const emailTemplate = {};
      emailTemplate.subjectLine = subjectLine;
      emailTemplate.emailBody = emailBody;
      // TODO: updateEmailTemplate endpoint needs to properly persist multiple emails
      Http.postData('updateEmailTemplate', {
        emailTemplate,
        emailTemplates,
        sequenceId: listItem._id,
        stepId: step._id,
      })
        .then(function (response) {
          if (response) {
            onClose();
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    step.emailTemplate = {
      ...step.emailTemplate,
      subjectLine: subjectLine,
      emailBody: emailBody,
      reviewed: true,
      genReqFailed: false,
    };

    // Update the new value into the emailTemplates array as well
    if (emailTemplates?.length > 1) {
      const updatedTemplates = [...emailTemplates];
      updatedTemplates[emailTemplateIndex] = {
        ...updatedTemplates[emailTemplateIndex],
        subjectLine: subjectLine,
        emailBody: emailBody,
      };
      step.emailTemplates = updatedTemplates;
      step.emailTemplate = {
        ...step.emailTemplate,
        subjectLine: updatedTemplates[0].subjectLine,
        emailBody: updatedTemplates[0].emailBody,
      };
    }

    onClose();
  };

  const generateNewEmail = () => {
    setIsLoading(true);
    step.emailTemplate ? (step.emailTemplate.requestDraft = true) : (step.emailTemplate = { requestDraft: true });
    const req = { seqEmails: [step] };
    if (selectedTone) req.tone = selectedTone;
    if (selectedLength) req.length = selectedLength;
    // When generating an email from the Email Modal we want to indicate to the controller
    // that it is ok to re-generate an email even if it was previously reviewed.
    if (step.emailTemplate) step.emailTemplate.reviewed = false;
    if (step.id === 'checkin.recurring' && req.seqEmails.length === 1) {
      req.seqEmails[0].emailTemplateIndex = emailTemplateIndex;
    }

    Http.postData('generateSequenceEmail', req)
      .then(function (response) {
        if (response[0]) {
          let newTemplates = response[0].emailTemplates?.length > 0 ? response[0].emailTemplates : [response[0]];
          setEmailTemplates((prev) => newTemplates);
          if (emailTemplateIndex === 0) {
            if (response[0].emailTemplates?.length > 1) {
              setEmailBody(newTemplates[0].emailBody);
              setSubjectLine(newTemplates[0].subjectLine);
            } else {
              setEmailBody(newTemplates[0].email);
              setSubjectLine(newTemplates[0].subject);
            }
          }
          step.emailTemplate.requestDraft = false;
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleSubjectChange = (e) => {
    setSubjectLine(e.target.value);
  };

  const handleRichTextEditorChange = (editorState) => {
    if (!editorRef.current) return;

    editorState.read(() => {
      const htmlString = $generateHtmlFromNodes(editorRef.current);
      if (emailBody !== htmlString) {
        setEmailBody(htmlString);
      }
    });
  };

  const handleEmailBodyChange = (e) => {
    setEmailBody(e.target.value);
  };

  const handlePersonalizedChipClick = (text) => {
    const emailBodyEle = document.getElementById('emailBody');

    if (emailBodyEle) {
      const startPos = emailBodyEle.selectionStart;
      const endPos = emailBodyEle.selectionEnd;
      const mailBody = emailBodyEle.value;
      const updatedBody = mailBody.substring(0, startPos) + '<' + text + '>' + mailBody.substring(endPos);
      setEmailBody(updatedBody);
      emailBodyEle.focus();
      emailBodyEle.setSelectionRange(startPos + text.length, startPos + text.length);
    }
  };

  const handleEmailSwitcherClick = (index) => {
    let swapped = [...emailTemplates];

    swapped[emailTemplateIndex].subjectLine = subjectLine;
    swapped[emailTemplateIndex].emailBody = emailBody;

    setEmailTemplates(swapped);
    setEmailTemplateIndex(index);
    setSelectedTone(null);
    setSelectedLength(null);
  };

  const frequencyToEmailCount = {
    'Every 2 weeks': 6,
    'Once a month': 6,
    'Every quarter': 4,
    'Twice a year': 2,
    'Once a year': 1,
  };

  React.useEffect(() => {
    const currentTemplate = emailTemplates[emailTemplateIndex];
    if (currentTemplate) {
      setSubjectLine((prev) => currentTemplate.subject || currentTemplate.subjectLine);
      setEmailBody((prev) => currentTemplate.email || currentTemplate.emailBody);
    }
  }, [emailTemplateIndex, emailTemplates]);

  React.useEffect(() => {
    if (step) {
      let incomingTemplates;
      if (step.emailTemplates?.length > 0) {
        incomingTemplates = [...step.emailTemplates];
        // incomingTemplates.splice(parseInt(step.frequency) || 1)
      } else {
        incomingTemplates = [step.emailTemplate];
      }
      setEmailTemplates(incomingTemplates);

      let currentTemplate = incomingTemplates[emailTemplateIndex];
      if (currentTemplate) {
        setSubjectLine(currentTemplate.subjectLine);
        setEmailBody(currentTemplate.emailBody);
      }
    }
  }, [step]);

  React.useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        fullScreen
        sx={{
          px: {
            xs: 0,
            md: 2,
          },
        }}
      >
        <DialogTitle sx={{ p: 1 }}>
          <Grid container alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="close" onClick={onClose} sx={{ mt: 2, ml: 2 }}>
                <ArrowLeft fill={theme.palette.primary.grey} />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                onClick={readOnly ? onClose : saveEmail}
                disabled={isLoading}
                sx={{
                  'maxWidth': '200px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'mr': 2,
                  'mt': 2,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                }}
              >
                {readOnly ? 'Close' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
            <Grid item xs={12} md={4} sx={{ mb: { xs: 2, md: 0 }, order: { xs: 2, md: 1 } }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 700, color: theme.palette.primary.black, pt: 5 }}>
                Adjust the style
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 3 }}>
                Select the field you want to insert
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey }}>
                Tone
              </Typography>
              <ToggleButtonGroup
                value={selectedTone}
                exclusive
                onChange={handleToneChange}
                aria-label="tone"
                orientation="vertical"
                fullWidth
                disabled={step?.emailTemplate?.genReqFailed || readOnly}
                sx={{ opacity: readOnly ? 0.5 : 1 }}
              >
                <ToggleButton
                  value="formal"
                  aria-label="formal"
                  sx={{
                    'textTransform': 'none',
                    'border': 'none',
                    'justifyContent': 'flex-start',
                    '&:disabled': {
                      border: 'none',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent',
                    },
                    'mb': -1,
                  }}
                >
                  <Check
                    fill={selectedTone === 'formal' ? theme.palette.primary.black : theme.palette.primary.white}
                    style={{ marginRight: 3 }}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, color: theme.palette.primary.black }}>
                    Formal
                  </Typography>
                </ToggleButton>

                <ToggleButton
                  value="informal"
                  aria-label="informal"
                  sx={{
                    'textTransform': 'none',
                    'border': 'none',
                    'justifyContent': 'flex-start',
                    '&:disabled': {
                      border: 'none',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Check
                    fill={selectedTone === 'informal' ? theme.palette.primary.black : theme.palette.primary.white}
                    style={{ marginRight: 3 }}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, color: theme.palette.primary.black }}>
                    Informal
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>

              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pt: 2 }}>
                Length
              </Typography>
              <ToggleButtonGroup
                value={selectedLength}
                exclusive
                onChange={handleLengthChange}
                aria-label="length"
                orientation="vertical"
                fullWidth
                disabled={step?.emailTemplate?.genReqFailed || readOnly}
                sx={{ opacity: readOnly ? 0.5 : 1 }}
              >
                <ToggleButton
                  value="short"
                  aria-label="short"
                  sx={{
                    'textTransform': 'none',
                    'border': 'none',
                    'justifyContent': 'flex-start',
                    '&:disabled': {
                      border: 'none',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent',
                    },
                    'mb': -1,
                  }}
                >
                  <Check
                    fill={selectedLength === 'short' ? theme.palette.primary.black : theme.palette.primary.white}
                    style={{ marginRight: 3 }}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, color: theme.palette.primary.black }}>
                    Short
                  </Typography>
                </ToggleButton>

                <ToggleButton
                  value="long"
                  aria-label="long"
                  sx={{
                    'textTransform': 'none',
                    'border': 'none',
                    'justifyContent': 'flex-start',
                    '&:disabled': {
                      border: 'none',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Check
                    fill={selectedLength === 'long' ? theme.palette.primary.black : theme.palette.primary.white}
                    style={{ marginRight: 3 }}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, color: theme.palette.primary.black }}>
                    Long
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>

              <Button
                onClick={generateNewEmail}
                disabled={(!selectedTone && !selectedLength) || isLoading || step.emailTemplate.genReqFailed}
                sx={{
                  'maxWidth': '200px',
                  'fontWeight': 500,
                  'fontSize': '14px',
                  'backgroundColor': theme.palette.primary.blue,
                  'color': theme.palette.primary.white,
                  'textTransform': 'none',
                  'width': '167px',
                  'py': 1,
                  'px': 2,
                  'my': 3,
                  'whiteSpace': 'nowrap',
                  'overflow': 'hidden',
                  'textOverflow': 'ellipsis',
                  'borderRadius': 2,
                  'boxShadow': '0px 4px 8px 0px #2C64E31F',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                  },
                  '&:disabled': {
                    backgroundColor: `${theme.palette.primary.blue}AA`,
                    color: `${theme.palette.primary.white}AA`,
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Generate new email'}
              </Button>

              <Divider sx={{ width: '90%' }} />
              <Typography sx={{ fontSize: '16px', fontWeight: 700, color: theme.palette.primary.black, pt: 3 }}>
                Insert personalized variable
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 3 }}>
                Select the field you want to insert
              </Typography>
              <Container
                sx={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0 !important', justifyContent: 'flex-start' }}
              >
                {copyText.map((text, index) => (
                  <Chip
                    key={index}
                    label={text}
                    sx={{
                      m: 1,
                      mt: 0,
                      backgroundColor: theme.palette.primary.white,
                      fontWeight: 500,
                      fontSize: '14px',
                      color: theme.palette.primary.grey,
                      border: '1px solid #E0E0E0',
                      borderRadius: 3,
                      opacity: readOnly ? 0.5 : 1,
                      pointerEvents: readOnly ? 'none' : 'auto',
                    }}
                    onClick={() => !readOnly && handlePersonalizedChipClick(text)}
                  />
                ))}
              </Container>
            </Grid>
            <Grid item xs={12} md={8} sx={{ order: { xs: 1, md: 2 } }}>
              <Typography
                sx={{
                  fontSize: {
                    xs: '28px',
                    md: '32px',
                  },
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                  pt: 5,
                  lineHeight: 'normal',
                }}
              >
                Compose your email
              </Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 3 }}>
                Our AI generates tailored emails from your profile, and you can edit them to suit your goals.
              </Typography>
              <Container
                sx={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '0 !important', justifyContent: 'flex-start' }}
              >
                <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
                  {emailTemplates.length > 1 &&
                    emailTemplates.slice(0, frequencyToEmailCount[step?.frequency] || 1).map((_, index) => (
                      <PButton
                        key={index}
                        onClick={() => handleEmailSwitcherClick(index)}
                        pVariant={emailTemplateIndex === index ? 'primary' : 'outlined'}
                      >
                        {index === 0 ? 'First email' : `${ordinalSuffixOf(index + 1)} email`}
                      </PButton>
                    ))}
                </Stack>
              </Container>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                }}
              >
                Subject line
                <span style={{ color: theme.palette.primary.orange }}>*</span>
              </Typography>
              <PTextfield
                value={replaceEmailSubjectPlaceholders(subjectLine, userBio, contact)}
                onChange={handleSubjectChange}
                disabled={isLoading}
                readOnly={readOnly}
                type="text"
                paperStyle={{ mx: 0, width: '90%' }}
              />

              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  color: theme.palette.primary.black,
                  marginTop: '24px',
                }}
              >
                Your message
                <span style={{ color: theme.palette.primary.orange }}>*</span>
              </Typography>
              {isRichTextEditorEnabled ? (
                <PRTEditor
                  disabled={isLoading}
                  onChange={handleRichTextEditorChange}
                  readOnly={readOnly}
                  sx={{ width: '90%' }}
                  initialValue={emailBody}
                  editorRef={editorRef}
                />
              ) : (
                <PTextfield
                  id="emailBody"
                  value={replaceEmailBodyPlaceholders(emailBody, userBio, contact)}
                  disabled={isLoading}
                  readOnly={readOnly}
                  onChange={handleEmailBodyChange}
                  type="text"
                  onFocus={(e) => (e.target.placeholder = '')}
                  multiline
                  rows={15}
                  paperStyle={{ mx: 0, width: '90%' }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {alertMessage && (
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
