import * as React from 'react';
import { Plus } from 'lucide-react';
import PToolTip from '@Library/PToolTip';

type VZeroSideMenuItemProps = {
  children: React.ReactNode;
  Icon?: React.ElementType;
  pVariant?: 'primary';
  disabled?: boolean;
  onClick: () => void;
  active?: boolean;
  showLabel?: boolean;
  post?: any;
  onAdd?: () => void;
};

function VZeroSideMenuItem({
  children,
  Icon = null,
  pVariant = 'primary',
  disabled = false,
  onClick,
  active = false,
  showLabel = true,
  post,
  onAdd,
}: VZeroSideMenuItemProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  const menuItem = (
    <div
      className={`relative text-sm flex items-center gap-2 rounded-lg px-4 py-[14px] text-gray-500 transition-all hover:text-gray-700 cursor-pointer ${
        active ? 'bg-gray-100' : 'hover:bg-gray-100'
      }`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {Icon && <Icon className="h-[18px] w-[18px]" />}
      {showLabel && (
        <div className="flex-1 flex items-center justify-between">
          <span className={`font-bold ${active ? 'text-gray-700' : ''}`}>{children}</span>
          {onAdd && isHovered && (
            <Plus
              className="h-[18px] w-[18px] text-gray-500 hover:text-gray-700 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onAdd();
              }}
            />
          )}
        </div>
      )}
    </div>
  );

  return showLabel ? (
    menuItem
  ) : (
    <PToolTip title={children} placement="right">
      {menuItem}
    </PToolTip>
  );
}

export default VZeroSideMenuItem;
