const { allAbbreviationReplacementMap, allAbbreviationsPattern } = require('../constants');

/**
 * Abbreviates job titles by replacing full phrases, then individual words, with their abbreviations.
 * Handles edge cases like extra spaces, hyphens, and ampersands.
 * @param {string} title - The job title to abbreviate
 * @returns {string} - The abbreviated job title
 */
function abbreviateJobTitle(title) {
  if (!title || typeof title !== 'string' || !title.trim()) return '';

  // Replace hyphens with spaces to standardize the title.
  let formattedTitle = title.trim().replace(/-/g, ' ');

  // Replace full phrases with abbreviations using the abbreviation map.
  formattedTitle = formattedTitle.replace(allAbbreviationsPattern, (match) => {
    // Normalize the match by removing punctuation, converting to lowercase,
    // and replacing hyphens and multiple spaces with a single space.
    const normalizedMatch = match
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, '') // Remove punctuation.
      .replace(/\s+/g, ' ');

    // Use the abbreviation map to replace the match.
    return allAbbreviationReplacementMap.get(normalizedMatch) || match;
  });

  // Normalize spaces and handle ampersands.
  formattedTitle = formattedTitle.replace(/\s+/g, ' ').trim();

  return formattedTitle;
}

module.exports = { abbreviateJobTitle };
