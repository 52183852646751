// Constants for domain-specific information, like job titles, industry terms, etc.

const jobTitleAbbreviations = [
  // Executive titles phrases
  { full: 'Assistant Vice President', short: 'AVP' },
  { full: 'Board of Directors', short: 'BoD' },
  { full: 'Chief Executive', short: 'CEO' },
  { full: 'Chief Executive Officer', short: 'CEO' },
  { full: 'Chief Financial Officer', short: 'CFO' },
  { full: 'Chief Human Resources Officer', short: 'CHRO' },
  { full: 'Chief Information Officer', short: 'CIO' },
  { full: 'Chief Legal Officer', short: 'CLO' },
  { full: 'Chief Marketing Officer', short: 'CMO' },
  { full: 'Chief Operating Officer', short: 'COO' },
  { full: 'Chief Risk Officer', short: 'CRO' },
  { full: 'Chief Technology Officer', short: 'CTO' },
  { full: 'Executive Vice President', short: 'EVP' },
  { full: 'General Counsel', short: 'GC' },
  { full: 'Managing Director', short: 'MD' },
  { full: 'Senior Vice President', short: 'SVP' },
  { full: 'Vice President', short: 'VP' },

  // Professional certification phrases
  { full: 'Certified Fraud Examiner', short: 'CFE' },
  { full: 'Certified Information Systems Auditor', short: 'CISA' },
  { full: 'Certified Internal Auditor', short: 'CIA' },
  { full: 'Certified Management Accountant', short: 'CMA' },
  { full: 'Certified Public Accountant', short: 'CPA' },
  { full: 'Certified Treasury Professional', short: 'CTP' },
  { full: 'Chartered Financial Analyst', short: 'CFA' },
  { full: 'Data Protection Officer', short: 'DPO' },
  { full: 'Enterprise Risk Manager', short: 'ERM' },
  { full: 'Financial Risk Manager', short: 'FRM' },
  { full: 'Personal Financial Specialist', short: 'PFS' },
  { full: 'Project Management Professional', short: 'PMP' },
  { full: 'Sarbanes-Oxley Manager', short: 'SOX' },

  // Other useful title phrases in legal & accounting firms
  { full: 'Business Development Manager', short: 'BDM' },
  { full: 'Contract Lifecycle Management', short: 'CLM' },
  { full: 'Human Resources Manager', short: 'HRM' },
  { full: 'Product Lifecycle Management', short: 'PLM' },
  { full: 'Product Manager', short: 'PM' },
  { full: 'Project Manager', short: 'PM' },

  // Common words in titles
  { full: 'Account', short: 'Acct' },
  { full: 'Accountant', short: 'Acctnt' },
  { full: 'Accounting', short: 'Acctg' },
  { full: 'Advisor', short: 'Adv' },
  { full: 'Advocate', short: 'Advct' },
  { full: 'and', short: '&' },
  { full: 'Assistant', short: 'Asst' },
  { full: 'Associate', short: 'Assoc' },
  { full: 'Attorney', short: 'Atty' },
  { full: 'Auditor', short: 'Aud' },
  { full: 'Bookkeeper', short: 'Bkkr' },
  { full: 'Chief', short: 'Chf' },
  { full: 'Clerk', short: 'Clrk' },
  { full: 'Compliance', short: 'Compl' },
  { full: 'Consultant', short: 'Cons' },
  { full: 'Controller', short: 'Ctrlr' },
  { full: 'Counsel', short: 'Cnsl' },
  { full: 'Director', short: 'Dir' },
  { full: 'Executive', short: 'Exec' },
  { full: 'Finance', short: 'Fin' },
  { full: 'Financial', short: 'Fin' },
  { full: 'Founder', short: 'Fndr' },
  { full: 'Lawyer', short: 'Lawy' },
  { full: 'Legal', short: 'Leg' },
  { full: 'Manager', short: 'Mgr' },
  { full: 'Officer', short: 'Ofcr' },
  { full: 'Operations', short: 'Ops' },
  { full: 'Owner', short: 'Ownr' },
  { full: 'Paralegal', short: 'Para' },
  { full: 'Partner', short: 'Prtnr' },
  { full: 'President', short: 'Pres' },
  { full: 'Principal', short: 'Princ' },
  { full: 'Regulatory', short: 'Reg' },
  { full: 'Services', short: 'Svcs' },
  { full: 'Science', short: 'Sci' },
  { full: 'Sciences', short: 'Sci' },
  { full: 'Solicitor', short: 'Solic' },
  { full: 'Specialist', short: 'Spcl' },
  { full: 'Tax', short: 'Tx' },
  { full: 'Treasurer', short: 'Treas' },
].sort((a, b) => b.full.length - a.full.length);

const geographicAbbreviations = [
  // Geographic Regions
  { full: 'Asia Pacific', short: 'APAC' },
  { full: 'Europe, Middle East, Africa', short: 'EMEA' },
  { full: 'Latin America', short: 'LATAM' },
  { full: 'North America', short: 'NA' },
  { full: 'Middle East', short: 'ME' },
  { full: 'Emerging Markets', short: 'Emerg Mkts' },

  // Major cities for legal & accounting firms
  { full: 'Amsterdam', short: 'AMS' },
  { full: 'Berlin', short: 'BER' },
  { full: 'Brussel', short: 'BRU' },
  { full: 'Buenos Aires', short: 'BA' },
  { full: 'Calgary', short: 'CAL' },
  { full: 'Chicago', short: 'CHI' },
  { full: 'Frankfurt', short: 'FRK' },
  { full: 'London', short: 'LDN' },
  { full: 'Los Angeles', short: 'LA' },
  { full: 'Mexico City', short: 'MEX' },
  { full: 'Montreal', short: 'MTL' },
  { full: 'Montréal', short: 'MTL' },
  { full: 'New York City', short: 'NYC' },
  { full: 'New York, New York', short: 'NYC' },
  { full: 'NY NY', short: 'NYC' },
  { full: 'NY, NY', short: 'NYC' },
  { full: 'Paris', short: 'PAR' },
  { full: 'Rome', short: 'ROM' },
  { full: 'San Francisco', short: 'SF' },
  { full: 'São Paulo', short: 'SP' },
  { full: 'Sao Paulo', short: 'SP' },
  { full: 'Toronto', short: 'TOR' },
  { full: 'T.O.', short: 'TOR' },
  { full: 'Washington, D.C.', short: 'DC' },
  { full: 'Washington DC', short: 'DC' },
  { full: 'Vancouver', short: 'VAN' },
  { full: 'Vienna', short: 'VIE' },

  // Common US States for legal & accounting firms
  { full: 'Alabama', short: 'AL' },
  { full: 'Alaska', short: 'AK' },
  { full: 'Arizona', short: 'AZ' },
  { full: 'Arkansas', short: 'AR' },
  { full: 'California', short: 'CA' },
  { full: 'Colorado', short: 'CO' },
  { full: 'Connecticut', short: 'CT' },
  { full: 'Delaware', short: 'DE' },
  { full: 'Florida', short: 'FL' },
  { full: 'Georgia', short: 'GA' },
  { full: 'Hawaii', short: 'HI' },
  { full: 'Idaho', short: 'ID' },
  { full: 'Illinois', short: 'IL' },
  { full: 'Indiana', short: 'IN' },
  { full: 'Iowa', short: 'IA' },
  { full: 'Kansas', short: 'KS' },
  { full: 'Kentucky', short: 'KY' },
  { full: 'Louisiana', short: 'LA' },
  { full: 'Maine', short: 'ME' },
  { full: 'Maryland', short: 'MD' },
  { full: 'Massachusetts', short: 'MA' },
  { full: 'Michigan', short: 'MI' },
  { full: 'Minnesota', short: 'MN' },
  { full: 'Mississippi', short: 'MS' },
  { full: 'Missouri', short: 'MO' },
  { full: 'Montana', short: 'MT' },
  { full: 'Nebraska', short: 'NE' },
  { full: 'Nevada', short: 'NV' },
  { full: 'New Hampshire', short: 'NH' },
  { full: 'New Jersey', short: 'NJ' },
  { full: 'New Mexico', short: 'NM' },
  { full: 'New York', short: 'NY' },
  { full: 'North Carolina', short: 'NC' },
  { full: 'North Dakota', short: 'ND' },
  { full: 'Ohio', short: 'OH' },
  { full: 'Oklahoma', short: 'OK' },
  { full: 'Oregon', short: 'OR' },
  { full: 'Pennsylvania', short: 'PA' },
  { full: 'Rhode Island', short: 'RI' },
  { full: 'South Carolina', short: 'SC' },
  { full: 'South Dakota', short: 'SD' },
  { full: 'Tennessee', short: 'TN' },
  { full: 'Texas', short: 'TX' },
  { full: 'Utah', short: 'UT' },
  { full: 'Vermont', short: 'VT' },
  { full: 'Virginia', short: 'VA' },
  { full: 'Washington', short: 'WA' },
  { full: 'West Virginia', short: 'WV' },
  { full: 'Wisconsin', short: 'WI' },
  { full: 'Wyoming', short: 'WY' },

  // Canadian Provinces and Territories
  { full: 'Alberta', short: 'AB' },
  { full: 'British Columbia', short: 'BC' },
  { full: 'Manitoba', short: 'MB' },
  { full: 'New Brunswick', short: 'NB' },
  { full: 'Newfoundland and Labrador', short: 'NL' },
  { full: 'Northwest Territories', short: 'NWT' },
  { full: 'Nova Scotia', short: 'NS' },
  { full: 'Nunavut', short: 'NU' },
  { full: 'Ontario', short: 'ON' },
  { full: 'Prince Edward Island', short: 'PEI' },
  { full: 'Quebec', short: 'QC' },
  { full: 'Saskatchewan', short: 'SK' },
  { full: 'Yukon', short: 'YT' },
].sort((a, b) => b.full.length - a.full.length);

const allAbbreviationReplacementMap = new Map(
  [...jobTitleAbbreviations, ...geographicAbbreviations]
    .map(({ full, short }) => [
      full
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '') // Remove punctuation.
        .replace(/\s+/g, ' '),
      short,
    ])
    .sort((a, b) => b[0].length - a[0].length),
);

const allAbbreviationsPattern = new RegExp(
  `\\b(${Array.from(allAbbreviationReplacementMap.keys())
    .map((key) => key.split(' ').join('[^a-z0-9]*'))
    .join('|')})\\b`,
  'gi',
);

module.exports = {
  jobTitleAbbreviations,
  geographicAbbreviations,
  allAbbreviationReplacementMap,
  allAbbreviationsPattern,
};
