import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Container, Divider, Stack, Typography, MenuItem, useMediaQuery, useTheme, Menu } from '@mui/material';
import { PDropdown, PDropdownVDeux, PYesNoDialog, PTypography, PProgressBar, PButton, PToast } from '@Library';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Http from '@/http/httpClient';
import EmptyState from '@/pages/ClientRetention/components/emptyState.js';
import Scheduled from '@/pages/ClientRetention/components/scheduled.js';
import ApprovalItems from '@/pages/ClientRetention/components/approvalItems.js';
import ContactCard from '@/pages/ClientRetention/components/contactCard.js';
import { TopicType, PostSources, Severity, ApprovalTypes } from '@/constants/constant.js';
import PostEdit from '@/pages/ClientRetention/components/postEdit.js';
import PublishModal from '@/pages/ClientRetention/components/publishModal.js';
import SchedulePostModal from '@/pages/ClientRetention/components/SchedulePostModal.js';
import { handleAuthentication } from '@/helper/authUtility';
import { recommendApproveDelegatePost, recommendDeclineDelegatePost } from '@/helper/apiHelper';
import { useSelector, useDispatch } from 'react-redux';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import {
  setSelectedDelegation as setAuthSliceSelectedDelegation,
  setPosts as setAuthSlicePosts,
} from '@/redux/authSlice.js';
import { useAvailableDelegations } from '@Hooks/CompanyHooks';
import { refreshPosts } from '@/App';
import SetupCards from './components/setupCards';
import NewsCards from './components/newsCards';

const ClientRetention = () => {
  const dispatch = useDispatch();
  const authSlicePosts = useSelector((state) => state.auth.posts);
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation);
  const userAuth = useSelector((state) => state.auth.user);

  const theme = useTheme();
  const featureFlags = useFeatureFlags();
  const enableDelegationFeature = featureFlags.some(
    (flag) => flag.name === 'enableDelegationFeature' && flag.isFeatureEnabled,
  );
  const sequenceFilterDropdownEnabled = featureFlags.some(
    (flag) => flag.name === 'sequenceFilterDropdownEnabled' && flag.isFeatureEnabled,
  );
  const enableGiftsFeature = featureFlags.some((flag) => flag.name === 'gifts' && flag.isFeatureEnabled);
  const [isEmpty, setIsEmpty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [postItem, setPostItem] = useState();
  const [posts, setPosts] = useState(authSlicePosts || []);
  const [scheduledPosts, setScheduledPosts] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [activeButton, setActiveButton] = useState('All');
  const [filterPosts, setFilterPosts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sequenceNames, setSequenceNames] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [sequenceNameFilter, setSequenceNameFilter] = useState('');
  const [toastMessge, setToastMessge] = useState('');
  const [toastSeverity, setToastSeverity] = useState('');
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openSchedulePostModal, setOpenSchedulePostModal] = useState(false);
  const [schedulingPost, setSchedulingPost] = useState();
  const [schedulingSize, setSchedulingSize] = useState();
  const [selectedItem, setSelectedItem] = useState('Assistant');
  const approvalList = [
    ApprovalTypes.APPROVE_ALL_MESSAGES,
    ApprovalTypes.APPROVE_ALL_POSTS,
    ApprovalTypes.APPROVE_ALL_REPLIES,
    ApprovalTypes.APPROVE_ALL_GIFTS,
    ApprovalTypes.APPROVE_EVERYTHING,
  ];
  const [similarEditsDialogOpen, setSimilarEditsDialogOpen] = useState(false);
  const [similarEditsMessage, setSimilarEditsMessage] = useState('');
  const [similarEditsMessageAnalysisId, setSimilarEditsMessageAnalysisId] = useState();
  const [originalMessage, setOriginalMessage] = useState();
  const isLargeScreen = useMediaQuery('(min-width:1280px)');
  const delegationAccessId = authSliceSelectedDelegation?.userId || null;
  const toggleDelegationSelection = (access) => {
    if (
      !access ||
      (authSliceSelectedDelegation?.userId && access?.userId && authSliceSelectedDelegation?.userId === access?.userId)
    ) {
      dispatch(setAuthSliceSelectedDelegation(null));
      setSequenceNameFilter('');
      setSelectedItem('Assistant');
    } else {
      dispatch(setAuthSliceSelectedDelegation(access));
      setSequenceNameFilter('');
      setSelectedItem('Assistant');
    }
  };
  const [isQueueLoading, setIsQueueLoading] = useState(true);

  const user = useSelector((state) => state.auth.user);
  const delegationData = useAvailableDelegations();

  const MemoizedPDropdown = useMemo(() => {
    const menuList = [
      {
        name: 'Assistant',
        hasSubMenu: false,
        isEnabled: true,
        placeholder: true,
        onSubmit: () => {
          setSequenceNameFilter('');
          setSelectedItem('Assistant');
        },
      },
      ...sequenceNames.map((name) => ({
        name: name,
        hasSubMenu: false,
        isEnabled: true,
        onSubmit: () => {
          setSequenceNameFilter(name);
          setSelectedItem(name);
        },
      })),
    ];

    return <PDropdown truncateText closeWhenClicked pVariant="grey" selectedItem={selectedItem} menuList={menuList} />;
  }, [selectedItem, sequenceNames, authSliceSelectedDelegation]);

  const queueTabButtons = [
    { name: 'Most Urgent', value: 'All' },
    { name: 'Social Posts', value: 'Posts' },
    { name: 'Social Replies', value: 'Replies' },
    { name: 'Messages', value: 'Emails' },
    // Conditionally add the Gifts button based on the enableGiftsFeature flag
    ...(enableGiftsFeature ? [{ name: 'Gifts', value: 'Gifts' }] : []),
  ];

  const customBtnStyle = {
    fontWeight: 500,
    fontSize: '14px',
    mx: 0.5,
    my: 1.5,
    py: 1,
    px: 1,
  };

  const progressBarStyle = {
    'width': '100%',
    'backgroundColor': '#E8EFFD',
    '& .MuiLinearProgress-bar': {
      backgroundImage: `linear-gradient(90deg, ${theme.palette.primaryCL.Red100} 0%, ${theme.palette.primaryCL.Blue100} 99.9%)`,
    },
  };

  const errorToasty = (message) => {
    toasty(message, Severity.ERROR);
  };

  const successToasty = (message) => {
    toasty(message, Severity.SUCCESS);
  };

  const toasty = (message, severity = '') => {
    setToastMessge(message);
    setToastSeverity(severity);
    setShowToast(true);
  };

  const updatePostCallback = useCallback((updatedPost) => {
    setFilterPosts((prevFilterPosts) => {
      const hasChanged = prevFilterPosts.some((post) => post._id === updatedPost._id && post !== updatedPost);

      if (!hasChanged) {
        return prevFilterPosts;
      }

      return prevFilterPosts.map((post) => (post._id === updatedPost._id ? updatedPost : post));
    });
  }, []);

  const filteringPosts = (type) => {
    let filteredPosts = authSlicePosts;
    if (selectedItem !== 'Assistant' && sequenceNameFilter) {
      filteredPosts = authSlicePosts.filter((post) => {
        const sequenceNameFromPost = post?.sequenceAttr?.sequenceName || post?.sequenceDetails?.sequenceName;
        return sequenceNameFromPost === sequenceNameFilter;
      });
    }

    if (type === 'All' || type === ApprovalTypes.APPROVE_EVERYTHING) {
      filteredPosts = filteredPosts.filter((post) => post !== null);
    } else if (type === ApprovalTypes.APPROVE_ALL_POSTS || type === 'Posts') {
      filteredPosts = filteredPosts.filter(
        (post) => post.authorType === 'user' && post.topic_type === TopicType.USER_POST,
      );
    } else if (type === ApprovalTypes.APPROVE_ALL_REPLIES || type === 'Replies') {
      filteredPosts = filteredPosts.filter((post) => post.authorType === 'contact');
    } else if (enableGiftsFeature && (type === ApprovalTypes.APPROVE_ALL_GIFTS || type === 'Gifts')) {
      filteredPosts = filteredPosts.filter(
        (post) =>
          post.topic_type === 'gift' &&
          !['meeting', 'followup', 'email', 'checkin.recurring', 'linkedInEmail'].includes(post.topic_type),
      );
    } else if (type === ApprovalTypes.APPROVE_ALL_MESSAGES || type === 'Emails') {
      filteredPosts = filteredPosts.filter((post) => {
        const isSequenceEmail =
          post.source === PostSources.SEQUENCE &&
          ['meeting', 'followup', 'email', 'checkin.recurring', 'linkedInEmail'].includes(post.topic_type);
        const hasRecommendationKey = !!post.recommendationKey;
        const sequenceNameFromPost = post?.sequenceAttr?.sequenceName || post?.sequenceDetails?.sequenceName;
        const isInSequenceFilter = !sequenceNameFilter || sequenceNameFromPost === sequenceNameFilter;
        return (isSequenceEmail || hasRecommendationKey) && isInSequenceFilter;
      });
    }

    filteredPosts = filteredPosts.filter(
      (post) =>
        post !== null &&
        ((post.source === PostSources.SEQUENCE && !post.approved) ||
          (post.source !== PostSources.SEQUENCE && !post.scheduledDate) ||
          (post.source === PostSources.CHROME_EXTENSION && !post.approved) ||
          (post.source === PostSources.ERM && !post.approved) ||
          (post.source === PostSources.AI_NOTETAKER && !post.approved)),
    );

    // New sorting logic based on delegationAccessId and recommendationList
    if (delegationAccessId) {
      // If delegationAccessId has data, show recommendationList posts last
      filteredPosts = filteredPosts.sort((a, b) => {
        const aHasRecommendation = a.recommendationList?.length > 0;
        const bHasRecommendation = b.recommendationList?.length > 0;

        const aUserRecommended = a.recommendationList?.some((rec) => rec.userId === userAuth?._id);
        const bUserRecommended = b.recommendationList?.some((rec) => rec.userId === userAuth?._id);

        // Logic to move posts with userAuth's recommendation to the bottom
        if (aUserRecommended && !bUserRecommended) return 1;
        if (!aUserRecommended && bUserRecommended) return -1;

        // If both posts have recommendation lists, sort by the last recommendation ('approve' or 'decline')
        if (aHasRecommendation && bHasRecommendation) {
          const aRecommendation = a.recommendationList[a.recommendationList.length - 1]?.recommendation;
          const bRecommendation = b.recommendationList[b.recommendationList.length - 1]?.recommendation;

          if (aRecommendation === 'approve' && bRecommendation === 'decline') return -1;
          if (aRecommendation === 'decline' && bRecommendation === 'approve') return 1;

          return 0;
        }

        // If one has a recommendation list and the other doesn't, prioritize the one without a recommendation
        if (aHasRecommendation && !bHasRecommendation) return 1;
        if (!aHasRecommendation && bHasRecommendation) return -1;

        // If neither post has a recommendation list, maintain their current order
        return 0;
      });
    } else {
      // If no delegationAccessId, keep the original logic (recommendationList at the top)
      filteredPosts = filteredPosts.sort((a, b) => {
        const aHasRecommendation = a.recommendationList?.length > 0;
        const bHasRecommendation = b.recommendationList?.length > 0;

        if (aHasRecommendation && bHasRecommendation) {
          const aRecommendation = a.recommendationList[a.recommendationList.length - 1]?.recommendation;
          const bRecommendation = b.recommendationList[b.recommendationList.length - 1]?.recommendation;

          if (aRecommendation === 'approve' && bRecommendation === 'decline') return -1;
          if (aRecommendation === 'decline' && bRecommendation === 'approve') return 1;

          return 0;
        }

        if (aHasRecommendation && !bHasRecommendation) return -1;
        if (!aHasRecommendation && bHasRecommendation) return 1;

        return 0;
      });
    }
    return filteredPosts;
  };

  const handleButtonClick = (type) => {
    const filteredPosts = filteringPosts(type);
    setFilterPosts(filteredPosts);
    setActiveButton(type);
  };

  const getProcessingTime = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  // const handleImmediatePublish = async (userPost, userType, priority = 'immediate', time) => {
  //   try {
  //     setIsLoading((prevLoadingItems) => [...prevLoadingItems, userPost._id]);

  //     let res = await Http.postData('publishPost', { post: userPost, priority, time });
  //     if (res && res.status === 401) {
  //       await handleAuthentication('/api/auth/twitter?via=reauth');

  //       const bc = new BroadcastChannel('reauthChannel');
  //       const eventPromise = new Promise((resolve) => {
  //         bc.addEventListener('message', async (event) => {
  //           if (event.data.status === 200) {
  //             res = await Http.postData('publishPost', { post: userPost, priority, time });
  //             resolve();
  //           }
  //         });
  //       });

  //       await eventPromise;
  //     }

  //     if (res && res.status) {
  //       setPosts((prevPosts) => prevPosts.filter((post) => post._id !== userPost._id));
  //       setStatus(Severity.SUCCESS, 'Published!!');
  //     } else {
  //       setStatus(Severity.ERROR, 'Failed!!')
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.error('Error status:', error.response.status);
  //       console.error('Error data:', error.response.data);
  //     } else {
  //       console.error(error);
  //     }

  //     setStatus(Severity.ERROR, 'Failed!!');
  //   } finally {
  //     setIsLoading((prevLoadingItems) =>
  //       prevLoadingItems.filter((id) => id !== userPost._id)
  //     );
  //   }
  // };

  const availPostForApprove = (type) => {
    const postToApprove = filteringPosts(type)
      ?.filter((p) => p !== null && !p.approved)
      ?.map((p) => {
        if (p._id) {
          return p._id.toString();
        } else if (p.recommendationKey) {
          return p.recommendationKey.toString();
        }
        return null;
      })
      ?.filter((id) => id !== null);
    return postToApprove?.length > 0;
  };

  const handleMassApprove = (type = null) => {
    //'Approve all posts', 'Approve all messages', 'Approve all gifts', 'Approve all replies', 'Approve everything'
    if (!type) {
      console.warn('No posts type selected for approvals.');
      return;
    }

    const postsToApprove = filteringPosts(type)?.filter((p) => p !== null && !p.approved);
    if (postsToApprove?.length === 0) {
      console.warn('No posts exists for approval. Hence, return.');
      return;
    }

    const postsToPublish = postsToApprove.filter((p) => p.source !== PostSources.SEQUENCE);

    if (postsToPublish?.length > 0) {
      let currentTime = dayjs(new Date());
      for (const post of postsToPublish) {
        if (post.source === PostSources.SEQUENCE) {
          handlePostApprove(post, post.message, post.subject);
        } else {
          handlePublish(post, 'scheduled', new Date(currentTime));
          currentTime = currentTime + getProcessingTime(900000, 1800000);
        }
      }
    }
    setAnchorEl(null);
  };

  const handlePublish = (userPost, priority = 'immediate', time = new Date()) => {
    setIsLoading(true);
    //userPost.scheduledDate = time
    const endpoint = authSliceSelectedDelegation?.userId
      ? `publishPost/${authSliceSelectedDelegation.userId}`
      : 'publishPost';
    Http.postData(endpoint, { post: userPost, priority, time })
      .then(async (res) => {
        if (res && res.status) {
          if (res.status === 401) {
            handleAuthentication('/api/auth/twitter?via=reauth');

            const bc = new BroadcastChannel('reauthChannel');
            const eventPromise = new Promise((resolve) => {
              bc.addEventListener('message', async (event) => {
                if (event.data.status) {
                  const endpoint = authSliceSelectedDelegation?.userId
                    ? `publishPost/${authSliceSelectedDelegation.userId}`
                    : 'publishPost';
                  res = await Http.postData(endpoint, { post: userPost, priority, time });
                  if (res && res.status) {
                    setPosts((prevPosts) => prevPosts.filter((post) => post._id !== userPost._id));
                    successToasty(priority === 'immediate' ? "It's been approved" : "It's been scheduled");
                  }
                  resolve();
                }
              });
            });
            await eventPromise;
          } else {
            setPosts((prevPosts) => prevPosts.filter((post) => post._id !== userPost._id));
            successToasty(priority === 'immediate' ? "It's been approved" : "It's been scheduled");
          }
        } else {
          errorToasty('Approve Failed!');
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Error status:', error.response.status);
          console.error('Error data:', error.response.data);
        } else {
          console.error(error);
        }
        errorToasty('Unexpected error during approval!');
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  };

  const handleMassPublish = async (bulkPosts, userType, priority, time, type) => {
    let currentTime = dayjs(time ?? new Date());
    for (const post of bulkPosts) {
      handlePublish(post, userType, priority, new Date(currentTime));
      currentTime = currentTime + getProcessingTime(900000, 1800000);
    }
    if (type === 'bulk') {
      successToasty("It's been approved");
    }
  };

  const findPostType = (post) => {
    const { authorType, topic, topic_type, source, responseTo } = post;
    const topicTypesForPosting = [TopicType.USER_POST, TopicType.TWITTER_TRENDING, TopicType.IMAGE_POST];
    if (topicTypesForPosting.includes(topic_type) && authorType === 'user') {
      return 'Post';
    } else if (source === PostSources.TWITTER && topic === 'twitter_tweet' && responseTo) {
      return 'Reply';
    } else if (source === PostSources.LINKEDIN_COMMENT && responseTo) {
      return 'Reply'; // for linkedin comment
    } else if (topic_type !== TopicType.USER_POST && source === PostSources.SEQUENCE) {
      return 'Email';
    } else if (topic_type === TopicType.ADHOC && source === PostSources.CHROME_EXTENSION) {
      return 'Email';
    } else if (topic_type === TopicType.RECAP_EMAIL && source === PostSources.AI_NOTETAKER) {
      return 'Email';
    }
  };

  const handleDelete = (userPost) => {
    setIsDeleting(true);
    const endpoint = authSliceSelectedDelegation?.userId
      ? `deleteUserPost/${authSliceSelectedDelegation.userId}`
      : 'deleteUserPost';
    Http.postData(endpoint, { post: userPost })
      .then((res) => {
        if (res.success === true) {
          const filteredDeletedPosts = posts.filter((post) => post._id !== userPost._id);
          // Update the state of post in redux
          dispatch(setAuthSlicePosts(filteredDeletedPosts));
          // Update the local state of the updated post
          setPosts(filteredDeletedPosts);
          successToasty(`${findPostType(userPost)} deleted successfully!`);
        } else {
          errorToasty(`Failed to delete the ${findPostType(userPost)}!`);
        }
      })
      .catch((error) => {
        console.error(error);
        errorToasty('Failed to delete the post!');
      })
      .finally(() => {
        setIsDeleting(false);
      });

    closeModal();
  };

  const handleSimilarEditsYes = useCallback(() => {
    setSimilarEditsDialogOpen(false);
    processApplySimilarEdits(postItem, similarEditsMessageAnalysisId, originalMessage);
  }, [postItem, similarEditsMessageAnalysisId, originalMessage]);

  const handleSimilarEditsNo = useCallback(() => {
    setSimilarEditsDialogOpen(false);
    closeModal(); // Close the edit modal

    // We were holding off showing the approval until after the modals are closed
    successToasty(`${findPostType(postItem)} approved successfully!`);
  }, [postItem]);

  const handlePostApprove = (userPost, message, subject = null) => {
    const analyseChanges = userPost.message !== message;
    processApproval(userPost, message, subject, true, analyseChanges);
  };

  const processApplySimilarEdits = (userPost, analysisId, message) => {
    const endpoint = authSliceSelectedDelegation
      ? `applySimilarEdits/${authSliceSelectedDelegation?.userId}`
      : 'applySimilarEdits';

    Http.postData(endpoint, { post: userPost, analysisId: analysisId, originalMessage: message })
      .then((res) => {
        if (res.status && res.status !== 200) throw new Error(res?.statusText || 'An unexpected error occurred!');
        successToasty(`Approved! Similar edits will be applied shortly!`);
      })
      .catch((error) => {
        console.error(error);
        errorToasty(`Sorry, we were unable to apply your changes to similar items!`);
      })
      .finally(() => {
        closeModal();
      });
  };

  const processApproval = (userPost, message, subject = null, approved = false, analyseChanges = false) => {
    const params = {
      post: userPost,
      textToUpdate: message,
      subjectToUpdate: subject,
      approved: approved,
      analyseChanges: analyseChanges,
      subAction: userPost.subAction || null,
    };

    const endpoint = authSliceSelectedDelegation?.userId
      ? `updateUserPost/${authSliceSelectedDelegation.userId}`
      : 'updateUserPost';

    setIsLoading(true);
    return Http.postData(endpoint, params)
      .then((res) => {
        if (res.status && res.status !== 200) throw new Error(res?.statusText || 'An unexpected error occurred!');

        setOriginalMessage(userPost.message);
        const filteredProcessedPosts = posts.map((post) => {
          if (post._id?.toString() && post._id?.toString() === userPost._id?.toString()) {
            const updatedPost = { ...post };
            if (post.responseTo) {
              updatedPost.responseTo = { ...post.responseTo, message: message };
            } else {
              updatedPost.message = message;
            }
            if (subject) {
              updatedPost.subject = subject;
            }
            if (approved) {
              updatedPost.approved = approved;
            }
            return updatedPost;
          }
          return post;
        });
        // Update the state of post in redux
        dispatch(setAuthSlicePosts(filteredProcessedPosts));
        // Update the local state of the updated post
        setPosts(filteredProcessedPosts);

        if (res.ar?.userPrompt && res.ar.userPrompt.toLowerCase() !== 'no changes') {
          setSimilarEditsMessage(res.ar.userPrompt);
          setSimilarEditsMessageAnalysisId(res.ar.id);
          setSimilarEditsDialogOpen(true);
        } else {
          setSimilarEditsMessageAnalysisId(null);
          setSimilarEditsMessage(null);
          setSimilarEditsDialogOpen(false);

          approved && successToasty(`${findPostType(userPost)} approved successfully!`);
          closeModal();
        }

        return res;
      })
      .catch((error) => {
        console.error('Error in processApproval:', error);
        errorToasty(`Failed to approve the ${findPostType(userPost)}!`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSendToMessage = async (post, messagingPlatform) => {
    try {
      const updatedPost = {
        ...post,
        messagingPlatform,
      };

      const response = await processApproval(updatedPost, updatedPost.message, updatedPost.subject, false, false);

      console.log('Response in handleSendToMessage: ', response);

      const updatedPostFromResponse = response?.post;

      if (updatedPostFromResponse) {
        const updatedPosts = posts.map((p) => (p._id === updatedPostFromResponse._id ? updatedPostFromResponse : p));

        setPosts(updatedPosts);
        successToasty(`Send via ${messagingPlatform} updated successfully`);
      } else {
        console.warn('Non-sequence post handling not implemented yet.');
      }
    } catch (error) {
      errorToasty('Failed to update post');
    }
  };

  const handleApproveAllBtn = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (itemType, type) => {
    if (itemType === 'bulk') {
      const approvingPosts = filteringPosts(type);
      const userPost = approvingPosts.find((post) => post !== null && post.topic_type === TopicType.USER_POST);
      if (userPost?.length > 0) {
        setOpenPublishModal(true);
        setSchedulingPost(userPost);
        setSchedulingSize('bulk');
      } else {
        handleMassPublish(approvingPosts, approvingPosts.userType, 'immediate', new Date(), 'bulk');
      }
    }
    setAnchorEl(null);
  };

  const closeModal = () => setModalOpen(false);

  const handlePostAction = (post) => {
    const delegationAccessId = authSliceSelectedDelegation?.userId || null;
    if (!post) {
      return false;
    }

    switch (post.action) {
      case 'Approve':
        {
          handlePostApprove(post, post.message, post.subject);
        }
        break;
      case 'Publish':
        {
          const isUserPost = post !== null && post.topic_type === TopicType.USER_POST;
          isUserPost ? handlePublish(post) : handlePublish(post, null, null);
        }
        break;
      case 'Edit':
        setPostItem(post);
        setModalOpen(true);
        break;
      case 'Delete':
        handleDelete(post);
        break;
      case 'Schedule':
        const isPost = post !== null && post.topic_type === TopicType.USER_POST;
        if (isPost) {
          setOpenSchedulePostModal(true);
          setSchedulingPost(post);
          setSchedulingSize('single');
        }
        break;
      case 'RecommendApprove':
        recommendApproveDelegatePost({
          post,
          delegationAccessId,
        })
          .then((res) => {
            if (res) {
              successToasty('Post recommended for approval!');
              refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
            }
          })
          .catch((error) => {
            errorToasty('Failed to approve recommended post.');
            console.error(error);
          });
        break;
      case 'RecommendRemove':
        recommendDeclineDelegatePost({
          post,
          delegationAccessId,
        })
          .then((res) => {
            if (res) {
              successToasty('Post recommended for removal!');
              refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
            }
          })
          .catch((error) => {
            errorToasty('Failed to remove recommended post.');
            console.error(error);
          });
        break;
      default:
        break;
    }
  };

  const handleOnScheduledPublish = (posts, priority, time) => {
    handlePublish(posts, priority, time);
  };

  const fetchUserScheduledPosts = useCallback(async () => {
    try {
      const endpoint = authSliceSelectedDelegation
        ? `userScheduledPosts/${authSliceSelectedDelegation?.userId}`
        : 'userScheduledPosts';
      const response = await Http.getData(endpoint);
      if (response && response.scheduledPosts) {
        response.scheduledPosts.length === 0 ? setScheduledPosts([]) : setScheduledPosts(response.scheduledPosts);
      } else {
        console.warn('Unexpected response structure.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [authSliceSelectedDelegation]);

  useEffect(() => {
    // Refresh Post Queue list
    const loadPosts = async () => {
      setIsQueueLoading(true);
      await refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
      setIsQueueLoading(false);
    };

    loadPosts();
  }, [authSliceSelectedDelegation]);

  useEffect(() => {
    if (authSlicePosts) {
      setPosts(authSlicePosts);
    }

    // Refresh Post Queue list every 1 minute
    const refreshPostWithTimer = setInterval(() => {
      refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
    }, 60000);

    return () => clearTimeout(refreshPostWithTimer);
  }, [authSlicePosts]);

  useEffect(() => {
    let filteredPosts = [];
    if (posts && posts.length > 0) {
      filteredPosts = filteringPosts(activeButton);
      setFilterPosts(filteredPosts);
      setProgress(Math.round((posts.filter((p) => p.approved).length / posts.length) * 100));
    } else {
      setFilterPosts([]);
      setProgress(0);
    }
    fetchUserScheduledPosts();

    const uniqueSequenceNames = new Set();
    posts.forEach((post) => {
      if (post?.sequenceAttr?.sequenceName) {
        uniqueSequenceNames.add(post.sequenceAttr.sequenceName);
      }
    });

    setSequenceNames([...uniqueSequenceNames]);
  }, [posts, sequenceNameFilter, fetchUserScheduledPosts]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const handleEditToast = (message, post, isSuccess = true) => {
    const specificMessage = findPostType(post) + ' ' + message;
    setToastMessge(specificMessage);
    setShowToast(true);
    setToastSeverity(isSuccess ? 'success' : 'error');
  };
  const filteredContacts = useMemo(() => {
    return filterPosts?.filter((post) => {
      const hasRecommendationKey = post.recommendationKey;
      const hasContactDetails = post.contactDetails && Object.keys(post.contactDetails).length > 0;
      return hasRecommendationKey && hasContactDetails;
    });
  }, [filterPosts]);

  const filteredNonRecommendedPosts = useMemo(() => {
    return filterPosts?.filter((post) => {
      return !post.recommendationKey || !post.contactDetails;
    });
  }, [filterPosts]);

  const handleRefreshPosts = () => {
    refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
  };

  return (
    <Box>
      <Stack direction="row">
        <PublishModal
          open={openPublishModal}
          onClose={() => setOpenPublishModal(false)}
          onSubmit={handleOnScheduledPublish}
          posts={schedulingPost}
        />
        <SchedulePostModal
          open={openSchedulePostModal}
          onClose={() => setOpenSchedulePostModal(false)}
          onSubmit={handleOnScheduledPublish}
          posts={schedulingPost}
        />

        <Container
          style={{
            minWidth: '55%',
            maxWidth: '100%',
            height: '100%',
            minHeight: '100vh',
            paddingBottom: '32px',
            backgroundColor: theme.palette.primaryCL.White110,
          }}
        >
          {isEmpty ? (
            <EmptyState />
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  paddingTop: '28px',
                  paddingLeft: '14px',
                  paddingRight: '14px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '50px',
                  }}
                >
                  {enableDelegationFeature && delegationData.length > 0 ? (
                    <PDropdownVDeux
                      menu={{
                        headerText: 'Change Queue',
                        subheaderText: 'You can delegate on behalf of',
                        overrideDimensions: { minWidth: '360px', width: 'fit-content' },
                        checkType: 'radio',
                        menuList: [
                          {
                            label: 'My queue',
                            onSubmit: () => toggleDelegationSelection(null),
                            isSelected: !authSliceSelectedDelegation,
                          },
                          ...delegationData.flatMap((delegation) =>
                            delegation.access.map((access) => ({
                              label: access.name || access.email,
                              tooltip: access.email || 'No email found for user',
                              onSubmit: () => toggleDelegationSelection(access),
                              isSelected: authSliceSelectedDelegation?.userId === access.userId,
                            })),
                          ),
                        ],
                      }}
                      inputValue={
                        (authSliceSelectedDelegation
                          ? authSliceSelectedDelegation.name || authSliceSelectedDelegation.email
                          : user.name || user.email) + "'s queue"
                      }
                      displayAs="custom"
                      pVariant="secondary"
                      customNode={
                        <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
                          <PTypography size="h3" weight="bold">
                            {authSliceSelectedDelegation
                              ? (authSliceSelectedDelegation.name || authSliceSelectedDelegation.email) + "'s queue"
                              : 'Your queue'}
                          </PTypography>
                          <ExpandMoreIcon />
                        </Box>
                      }
                      autoCloseOnSelect={true}
                    />
                  ) : (
                    <PTypography size="h3" weight="bold">
                      Your queue
                    </PTypography>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    {sequenceFilterDropdownEnabled && (
                      <div
                        style={{
                          display: 'flex',
                          height: '40px',
                          padding: 'var(--14, 14px)',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 'var(--12, 12px)',
                          borderRadius: 'var(--14, 14px)',
                          background: 'Black/50',
                        }}
                      >
                        {MemoizedPDropdown}
                      </div>
                    )}
                    {
                      // Disabling until the underlying bug is identified & fixed
                      // https://www.notion.so/Approve-all-buttons-broken-4c193fdcbd1e442788053c27622e99ce
                      // <PButton pVariant="green" children="Approve" onClick={(e) => {handleApproveAllBtn(e)}}/>
                    }
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    sx={{
                      'filter': 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))',
                      'mt': 1,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '& .MuiMenu-paper': {
                        minWidth: '190px',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    {approvalList.map((item) => (
                      <MenuItem
                        key={item}
                        onClick={() => handleMassApprove(item)}
                        style={{ pointerEvents: availPostForApprove(item) ? 'auto' : 'none' }}
                      >
                        <Typography
                          sx={{
                            color: availPostForApprove(item)
                              ? theme.palette.primary.black
                              : theme.palette.action.disabled,
                            fontSize: '16px',
                            fontWeight: 500,
                            my: 0.5,
                            ml: 1,
                          }}
                        >
                          {item}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
                <div style={{ paddingTop: '14px' }}>
                  <PProgressBar additionalStyles={progressBarStyle} progress={progress} />
                </div>
              </div>
              {activeButton !== 'All' || filterPosts?.length > 0 ? (
                <>
                  <Stack direction="row" spacing={1} alignItems="flex-start" sx={{ width: '100%', my: 1 }}>
                    {queueTabButtons.map((type) => (
                      <PButton
                        key={type.value}
                        pVariant="plainBlack"
                        onClick={() => handleButtonClick(type.value)}
                        additionalStyles={customBtnStyle}
                        active={activeButton === type.value}
                      >
                        {type.name}
                      </PButton>
                    ))}
                  </Stack>
                  <Divider
                    orientation="horizontal"
                    flexItem
                    sx={{ height: 'auto', width: '100%', marginBottom: '10px' }}
                  />
                  <SetupCards />
                  <NewsCards />
                  <ContactCard
                    contactPosts={filteredContacts}
                    setPosts={setAuthSlicePosts}
                    refreshPost={handleRefreshPosts}
                    updateFilterPosts={setFilterPosts}
                  />
                  <div style={{ height: '100%', width: '100%' }}>
                    <ApprovalItems
                      posts={filteredNonRecommendedPosts}
                      handlePost={handlePostAction}
                      handleSendToMessage={handleSendToMessage}
                      updatePostCallback={updatePostCallback}
                    />
                  </div>
                </>
              ) : (
                <>
                  <PTypography
                    size="footnote"
                    weight="regular"
                    children={isQueueLoading ? 'Loading queue' : 'No posts available for approval.'}
                    sx={{ m: 2 }}
                  ></PTypography>
                  <SetupCards />
                  <NewsCards />
                </>
              )}

              {modalOpen && (
                <PostEdit
                  open={modalOpen}
                  onClose={closeModal}
                  post={postItem}
                  handlePublish={handlePublish}
                  handleDelete={handleDelete}
                  handlePostApprove={handlePostApprove}
                  isDeleting={isDeleting}
                  isLoading={isLoading}
                  handleEditToast={handleEditToast}
                  handlePost={handlePostAction}
                  processApproval={processApproval}
                />
              )}
              {/* {(activeButton === 'All' || activeButton === 'Replies') && <ReplyQueue
                posts={posts.filter(post => post !== null && post.authorType === 'contact')}
                handlePublish={handleImmediatePublish}
                handleMassPublish={handleMassPublish}
                handleDelete={handleDelete}
                isDeleting={isDeleting}
                setStatus={setStatus}
                curUser={curUser}
                isAdmin = {false}
                isReplying={isReplying}
                handlePostApprove={handlePostApprove}
              />} */}

              {/* {(activeButton === 'All' || activeButton === 'Posts') && <PostQueue
                posts={posts.filter(post => post !== null && post.authorType === 'user')}
                handlePublish={handleImmediatePublish}
                handleMassPublish={handleMassPublish}
                handleDelete={handleDelete}
                getProcessingTime={getProcessingTime}
                setStatus={setStatus}
                curUser={curUser}
                isDeleting={isDeleting}
                isPublishing={isPublishing}
                isAdmin = {false}
                handlePostApprove={handlePostApprove}
              />} */}
            </>
          )}
        </Container>
        <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
        {scheduledPosts?.length > 0 && (
          <Container style={{ minWidth: '44%', height: '100vh' }}>
            <Scheduled scheduledPosts={scheduledPosts} setPostItem={setPostItem} setModalOpen={setModalOpen} />
          </Container>
        )}

        <PYesNoDialog
          open={similarEditsDialogOpen}
          onYes={handleSimilarEditsYes}
          onNo={handleSimilarEditsNo}
          message="Apply edits to similar emails?"
          detailedMessage={similarEditsMessage}
          yesText="Apply to all"
          noText="Not right now"
        />
      </Stack>
      {showToast && (
        <div style={{ position: 'fixed', top: '20px', right: '20px' }}>
          <PToast text={toastMessge} severity={toastSeverity} />
        </div>
      )}
    </Box>
  );
};

export default ClientRetention;
