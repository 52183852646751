import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { PModal } from '@Library';
import { InteractionsCell } from './InteractionsCell';
import { cn } from '@/lib/utils';
import moment from 'moment';
import { useMemo } from 'react';

type InteractionType = {
  _id: string;
  messagingPlatform?: string;
  messagingTraffic?: string;
  actionDate?: string;
  user?: {
    name: string;
  };
  actionData?: {
    notes?: string;
  };
};

type RelationshipType = {
  contacts: Array<{
    name: string;
  }>;
};

type InteractionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  contactName: string;
  interactions: InteractionType[];
  notesFeatureEnabled: boolean;
};

// reverse the interactions array so the most recent interaction is at the top

export const InteractionsModal = ({ isOpen, onClose, contactName, interactions }: InteractionsModalProps) => {
  const orderedInteractions = useMemo(() => {
    return [...interactions].reverse();
  }, [interactions]);

  return (
    <PModal floating ultraWideContent open={isOpen} onClose={onClose} floatingDimensions={{ width: '800px' }}>
      <div className="w-full max-h-[80vh] flex flex-col">
        <div className="w-full flex flex-col">
          <div className="w-full flex justify-between items-center px-4 py-2 bg-white sticky top-0 z-10 border-b border-gray-300">
            <div>
              <span className="text-muted-foreground font-semibold text-lg">Interactions with</span>{' '}
              <span className="text-gray-600 font-bold text-lg">{contactName}</span>
            </div>
            <Button variant="ghost" size="sm" onClick={onClose}>
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>

        <div className="overflow-y-auto px-4 py-2" style={{ paddingRight: '1rem' }}>
          {orderedInteractions?.map((interaction) => (
            <div key={interaction._id} className={cn('py-3', 'border-b border-gray-300')}>
              <div className="flex justify-between items-center">
                <InteractionsCell interaction={interaction} />
                <span>
                  <span className="text-muted-foreground text-sm">on</span>{' '}
                  {moment(interaction?.actionDate).format('YYYY-MM-DD')}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PModal>
  );
};
