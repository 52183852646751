import { PModal } from '@Library';
import { X } from 'lucide-react';
import { styled } from '@mui/material/styles';
import { Select, MenuItem, CircularProgress } from '@mui/material';

import { Button } from '@/components/ui/button';
import { DialogFooter } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { RelationshipPayload } from '@/types/relationshipTypes';
import { sendEngagementEmail, generateEngagementEmail } from '@/helper/apiHelper';

import { useEffect, useState } from 'react';

// Styled MUI Select to match shadcn/ui styling
const StyledSelect = styled(Select)({
  'height': '40px',
  'backgroundColor': 'hsl(var(--background))',
  'border': '1px solid hsl(var(--input))',
  'borderRadius': 'var(--radius)',
  'color': 'hsl(var(--foreground))',
  'fontSize': '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover': {
    borderColor: 'hsl(var(--input))',
  },
  '&.Mui-focused': {
    borderColor: 'hsl(var(--ring))',
    boxShadow: '0 0 0 1px hsl(var(--ring))',
  },
  '& .MuiSelect-select': {
    padding: '8px 12px',
  },
});

// Styled MenuItem to match shadcn/ui styling
const StyledMenuItem = styled(MenuItem)({
  'fontSize': '14px',
  'color': 'hsl(var(--foreground))',
  '&:hover': {
    backgroundColor: 'hsl(var(--accent))',
  },
  '&.Mui-selected': {
    'backgroundColor': 'hsl(var(--accent))',
    '&:hover': {
      backgroundColor: 'hsl(var(--accent))',
    },
  },
});

type EngagementType = 'meeting' | 'checkin';

const engagementTypesStringMap: Record<EngagementType, string> = {
  meeting: 'Meeting',
  checkin: 'Check-in',
};

type EngageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  targetContact: RelationshipPayload;
};

export const EngageModal = ({ isOpen, onClose, targetContact }: EngageModalProps) => {
  const [subjectDrafts, setSubjectDrafts] = useState<{ [key in EngagementType]: string }>({
    meeting: '',
    checkin: '',
  });
  const [emailDrafts, setEmailDrafts] = useState<{ [key in EngagementType]: string }>({
    meeting: '',
    checkin: '',
  });
  const [isSending, setIsSending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedEngagementType, setSelectedEngagementType] = useState<EngagementType>('meeting');
  const [selectedEmail, setSelectedEmail] = useState(
    `${targetContact?.contactDetails?.name} <${targetContact?.contactDetails?.primaryEmail}>`,
  );

  useEffect(() => {
    setSelectedEmail(`${targetContact?.contactDetails?.name} <${targetContact?.contactDetails?.primaryEmail}>`);
  }, [targetContact]);

  const handleEngagementTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEngagementType(event.target.value as EngagementType);
  };

  const handleEmailChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedEmail(event.target.value as string);
  };

  useEffect(() => {
    if (!targetContact?.contactIds?.length) {
      // TODO: Some kind of error message to the user
      return;
    }

    const generateDrafts = async () => {
      if (isOpen) {
        const meetingGenerationParams = {
          type: 'meeting',
          contactId: targetContact.contactIds[0],
        };
        const checkinGenerationParams = {
          type: 'checkin',
          contactId: targetContact.contactIds[0],
        };

        // generate the subject and email drafts
        setIsGenerating(true);
        try {
          const [meetingResponse, checkinResponse] = await Promise.all([
            generateEngagementEmail(meetingGenerationParams),
            generateEngagementEmail(checkinGenerationParams),
          ]);

          setSubjectDrafts({
            meeting: meetingResponse.data.subject,
            checkin: checkinResponse.data.subject,
          });
          setEmailDrafts({
            meeting: meetingResponse.data.message,
            checkin: checkinResponse.data.message,
          });
        } catch (error) {
          console.error('Error generating engagement email:', error);
        } finally {
          setIsGenerating(false);
        }
      } else {
        setSubjectDrafts({
          meeting: '',
          checkin: '',
        });
        setEmailDrafts({
          meeting: '',
          checkin: '',
        });
      }
    };

    generateDrafts();
    setIsSending(false);
  }, [targetContact, isOpen]);

  const handleSendEmail = async () => {
    // TODO: "are you sure?" prompt

    setIsSending(true);

    try {
      const targetContactId = targetContact.contactIds[0];
      if (!targetContactId) {
        throw new Error('Target contact ID not found');
      }

      const emailAddress = selectedEmail.match(/<([^>]+)>/)?.[1];
      if (!emailAddress) {
        throw new Error('Email address not found');
      }

      const params = {
        emailAddress: emailAddress,
        subject: subjectDrafts[selectedEngagementType],
        message: emailDrafts[selectedEngagementType],
        contactId: targetContactId,
      };

      const response = await sendEngagementEmail(params);
      if (response?.success === true) {
        // close the modal without re-enabling the button if sending succeeds
        onClose();
      } else {
        // only set isSending to false if sending fails
        setIsSending(false);
      }
    } catch (error) {
      console.error('Error sending engagement email:', error);

      // only set isSending to false if sending fails
      setIsSending(false);
    }

    // we don't use a 'finally' block to set isSending to false because,
    // IF sending succeeds, we close the modal without re-enabling the 'send' button
  };

  return (
    <PModal
      floating
      ultraWideContent
      open={isOpen}
      onClose={onClose}
      floatingDimensions={{ width: '625px', maxWidth: '90vw', height: 'auto' }}
    >
      <div className="w-full gap-2 py-2">
        <div className="w-full space-y-1.5 flex justify-between items-center relative">
          <div>
            <span className="text-muted-foreground font-semibold text-lg mb-2">Compose Email</span>
          </div>
          <Button variant="ghost" size="sm" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </div>

        <hr className="my-2 border-gray-300" />

        <div className="grid gap-4 py-4">
          <div className="flex gap-4">
            <Label htmlFor="engagementType" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              Type:
            </Label>
            <StyledSelect
              id="engagementType"
              value={selectedEngagementType}
              onChange={handleEngagementTypeChange}
              className="w-full"
            >
              <StyledMenuItem value="meeting">Meeting</StyledMenuItem>
              <StyledMenuItem value="checkin">Check-in</StyledMenuItem>
            </StyledSelect>
          </div>
          <div className="flex gap-4">
            <Label htmlFor="to" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              To:
            </Label>
            <StyledSelect
              id="to"
              value={selectedEmail}
              onChange={handleEmailChange}
              className="w-full"
              disabled={isSending || isGenerating}
            >
              {targetContact?.contactDetails?.emails?.map((email) => (
                <StyledMenuItem key={email} value={`${targetContact?.contactDetails?.name} <${email}>`}>
                  {`${targetContact?.contactDetails?.name} <${email}>`}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className="flex gap-4">
            <Label htmlFor="subject" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              Subject:
            </Label>
            <div className="relative w-full">
              <Input
                id="subject"
                value={selectedEngagementType ? subjectDrafts[selectedEngagementType] : ''}
                onChange={
                  isSending || isGenerating
                    ? undefined
                    : (e) => setSubjectDrafts({ ...subjectDrafts, [selectedEngagementType]: e.target.value })
                }
                className="w-full"
                readOnly={isSending || isGenerating}
                disabled={isSending || isGenerating}
              />
              {isGenerating && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  className="absolute left-1/2"
                  style={{
                    marginTop: '-30px',
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex gap-4">
            <Label htmlFor="message" className="text-right" style={{ width: '100px', marginTop: '13px' }}>
              Message:
            </Label>
            <div className="relative w-full">
              <Textarea
                id="message"
                value={selectedEngagementType ? emailDrafts[selectedEngagementType] : ''}
                onChange={
                  isSending || isGenerating
                    ? undefined
                    : (e) => setEmailDrafts({ ...emailDrafts, [selectedEngagementType]: e.target.value })
                }
                className="w-full"
                rows={10}
                readOnly={isSending || isGenerating}
                disabled={isSending || isGenerating}
              />
              {isGenerating && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  className="absolute left-1/2 top-1/2"
                  style={{
                    marginTop: '-30px',
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button type="submit" onClick={handleSendEmail} disabled={isSending || isGenerating}>
            {isGenerating ? <CircularProgress size={20} color="inherit" /> : isSending ? 'Sending...' : 'Send Email'}
          </Button>
        </DialogFooter>
      </div>
    </PModal>
  );
};
