import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';

import PButton from './PButton';
import PTypography from './PTypography';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black100,
      };
      break;

    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};

function PContactListHeader({
  pVariant,
  onClick,
  onClick2,
  disabled1,
  disabled2,
  buttonText,
  buttonTheme,
  headerText,
  button2Text,
  button2Theme,
  headerComponent,
}) {
  const theme = useTheme();
  const variantStyles = getVariantStyles(theme, pVariant);

  return (
    <div
      style={{
        width: '100%',
        mx: 0,
        paddingTop: '16px',
        paddingBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {headerText &&
        // if headerComponent is not null, render it, else render headerText
        (headerComponent ? (
          headerComponent
        ) : (
          <PTypography weight="regular" size="h1">
            {headerText}
          </PTypography>
        ))}
      {/* // Backup moving these buttons to the assistant sidebar */}
      {/* <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                {buttonText && (
                    <PButton onClick={onClick} pVariant={buttonTheme} disabled={disabled1}>
                        {buttonText}
                    </PButton>
                )}
                {button2Text && (
                    <PButton onClick={onClick2} pVariant={button2Theme} disabled={disabled2}>
                        {button2Text}
                    </PButton>
                )}
            </div> */}
    </div>
  );
}

PContactListHeader.propTypes = {
  /** Postilize design variant, only primary for now, leaving in so we can easily update in future */
  pVariant: PropTypes.oneOf(['primary']),

  /** Header Text */
  headerText: PropTypes.string,

  /** Text for the button */
  buttonText: PropTypes.string,

  /** Theme for the button */
  buttonTheme: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'plain', 'black']),

  /** Text for the secondary button */
  button2Text: PropTypes.string,

  /** Theme for the secondary button */
  button2Theme: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'plain', 'black']),

  /** Click handler */
  onClick: PropTypes.func.isRequired,

  /** Click handler for secondary button */
  onClick2: PropTypes.func.isRequired,
};

PContactListHeader.defaultProps = {
  pVariant: 'primary',
  buttonTheme: 'secondary',
  button2Theme: 'primary',
};

export default PContactListHeader;
