import { useState, useEffect } from 'react';
import {
  ThumbsUp,
  MessageSquare,
  Share,
  Eye,
  ChevronDown,
  Target,
  Award,
  AlertTriangle,
  Users,
  TrendingUp,
  BarChart,
  Briefcase,
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { useDispatch, useSelector } from 'react-redux';
import Http from '../../http/httpClient';
import { generateAISearchPayload } from '@/helper/apiHelper';
import { Progress } from '@/components/ui/progress';
import { ChartComponent } from './chart';

function extractUnixTimestamp(urn) {
  if (!urn) {
    return null;
  }
  const postId = urn.replace(/\/$/, '');
  const asBinary = BigInt(postId).toString(2);
  const first41Chars = asBinary.slice(0, 41);
  const timestamp = parseInt(first41Chars, 2);
  return timestamp;
}

function unixTimestampToLocalDate(timestamp) {
  const dateObject = new Date(timestamp);
  const humanDateFormat = ('' + dateObject).substring(0, 25);
  return humanDateFormat;
}

const Stats = () => {
  const user = useSelector((state) => state.auth.user);
  const [postData, setPostData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [aiData, setAiData] = useState(null);
  const [dashboard, setDashboard] = useState(null);

  // Add this function to transform the data for the chart
  const prepareChartData = (posts) => {
    if (!posts?.length) return [];

    // Sort posts by timestamp
    const sortedPosts = [...posts].sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    // Get the first and last values for reference
    const firstValue = sortedPosts[0].impressions;
    const lastValue = sortedPosts[sortedPosts.length - 1].impressions;

    // Calculate boundary dates
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    const yearAgo = new Date();
    yearAgo.setDate(yearAgo.getDate() - 365);
    yearAgo.setHours(0, 0, 0, 0);

    // Create data points with slight variations (±10%)
    const data = [
      // Start point (365 days ago)
      {
        time: Math.floor(yearAgo.getTime() / 1000),
        value: Math.round(firstValue * (0.9 + Math.random() * 0.3)), // ±10% of first value
      },
      // Existing data points
      ...sortedPosts.map((post) => ({
        time: Math.floor(new Date(post.timestamp).getTime() / 1000),
        value: post.impressions,
      })),
      // End point (today)
      {
        time: Math.floor(today.getTime() / 1000),
        value: Math.round(lastValue * (0.9 + Math.random() * 0.2)), // ±10% of last value
      },
    ];

    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!user?._id) return;

      setIsLoading(true);
      setError(null);
      try {
        const [postsResponse, dashboardResponse] = await Promise.all([
          Http.getData(`user/linkedin-post-engagement`),
          Http.getData(`user/linkedin-dashboard-engagement`),
        ]);

        if (!postsResponse.success || !dashboardResponse.success) {
          throw new Error('Failed to fetch data');
        }

        // Process posts data
        const oneYearAgo = Date.now() - 365 * 24 * 60 * 60 * 1000;
        const dayAgo = Date.now() - 24 * 60 * 60 * 1000;
        const processedPosts = postsResponse.posts.map((post) => {
          const timestamp = extractUnixTimestamp(post.urn);
          if (timestamp) {
            const date = new Date(timestamp);
            return {
              ...post,
              timestamp,
              humanDate: date.toLocaleDateString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }),
            };
          }

          return post;
        });

        // i know is not the best way to do this but it works for now
        const filteredPosts = processedPosts.filter((post) => {
          // Ensure the post has a valid timestamp
          if (!post.timestamp) return false;

          // Include posts that are not older than one year
          if (post.timestamp <= oneYearAgo) return false;

          // Exclude posts with extremely high engagement rates (outliers)
          if (post.engagementRate >= 90) return false;

          // Exclude posts that are marked as deleted
          if (post.deleted) return false;

          // Exclude posts with zero engagement (no activity)
          if (post.engagementRate === 0) return false;

          // Exclude very recent posts (less than 24 hours old)
          if (post.timestamp >= dayAgo) return false;

          // If all conditions are met, include the post
          return true;
        });

        setPostData(filteredPosts);

        // Set dashboard data
        if (dashboardResponse.dashboardEngagements?.length > 0) {
          setDashboard(dashboardResponse.dashboardEngagements[0]);
        }
      } catch (error) {
        console.error('Error fetching LinkedIn data:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?._id]);

  if (isLoading) return <div>Loading...</div>;
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] p-6">
        <Card className="w-full max-w-md text-center">
          <CardHeader>
            <CardTitle>Unable to Load Data</CardTitle>
            <CardDescription>
              Please check back in 24 hours while we gather your LinkedIn engagement data. If an error has occurred, our
              engineers are working to resolve it.
            </CardDescription>
          </CardHeader>
        </Card>
      </div>
    );
  }
  if (!postData || postData.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] p-6">
        <Card className="w-full max-w-md text-center">
          <CardHeader>
            <CardTitle>No Data Available</CardTitle>
            <CardDescription>
              Please check back in 24 hours while we gather your LinkedIn engagement data.
            </CardDescription>
          </CardHeader>
        </Card>
      </div>
    );
  }

  // Aggregate data from all posts
  const aggregatedData = postData.reduce(
    (acc, post) => {
      return {
        likes: acc.likes + post.likes,
        comments: acc.comments + post.comments,
        shares: acc.shares + post.shares,
        impressions: acc.impressions + post.impressions,
        totalEngagementRate: acc.totalEngagementRate + post.engagementRate,
        hashtags: post.hashtags.reduce((hashtagMap, tag) => {
          if (!hashtagMap[tag]) {
            hashtagMap[tag] = {
              uses: 0,
              totalEngagement: 0,
              totalImpressions: 0,
            };
          }
          hashtagMap[tag].uses += 1;
          hashtagMap[tag].totalEngagement += post.engagementRate;
          hashtagMap[tag].totalImpressions += post.impressions;
          return hashtagMap;
        }, acc.hashtags),
        mentions: [...new Set([...acc.mentions, ...post.mentions.map((m) => m.username)])],
      };
    },
    {
      likes: 0,
      comments: 0,
      shares: 0,
      impressions: 0,
      totalEngagementRate: 0,
      hashtags: {},
      mentions: [],
    },
  );

  const avgEngagementRate = (aggregatedData.totalEngagementRate / postData.length).toFixed(2);

  const followers = Number(dashboard?.analytics?.find((item) => item.label === 'Followers')?.value.replace(/,/g, ''));
  const metrics = [
    {
      icon: ThumbsUp,
      label: 'Total Likes',
      value: aggregatedData.likes,
      substat: followers ? `1 out of ${Math.round(followers / aggregatedData.likes)} followers` : null,
    },
    {
      icon: MessageSquare,
      label: 'Total Comments',
      value: aggregatedData.comments,
      substat: followers ? `1 out of ${Math.round(followers / aggregatedData.comments)} followers` : null,
    },
    {
      icon: Share,
      label: 'Total Shares',
      value: aggregatedData.shares,
      substat: followers ? `1 out of ${Math.round(followers / aggregatedData.shares)} followers` : null,
    },
    {
      icon: Eye,
      label: 'Total Impressions',
      value: aggregatedData.impressions,
      substat: followers ? `${Math.round(aggregatedData.impressions / followers)} impressions per follower` : null,
    },
  ];

  const totalActions = 3;
  const completedActions = dashboard ? parseInt(dashboard.weeklySharingTracker.completed.split(' ')[0]) : 0;
  const progressPercentage = (completedActions / totalActions) * 100;

  console.log(`Total hashtags: ${Object.keys(aggregatedData.hashtags).length}`);

  // Update hashtag performance calculation to use post engagement rates directly
  const hashtagPerformance = Object.entries(aggregatedData.hashtags)
    .map(([tag, stats]) => {
      const uses = stats.uses || 0;
      const totalImpressions = stats.totalImpressions || 0;
      const engagementRate = (stats.totalEngagement / uses).toFixed(2);

      return {
        tag,
        uses,
        engagementRate,
        totalImpressions,
        avgImpressions: Math.round(totalImpressions / uses),
      };
    })
    .sort((a, b) => b.engagementRate - a.engagementRate) // Sort by engagement rate
    .slice(0, 6); // Cap at 6 hashtags

  return (
    <div className="p-6 max-w-7xl mx-auto space-y-6">
      {/* Welcome Message */}
      <div className="mb-8">
        <h1 className="text-4xl font-bold tracking-tight">Welcome back, {user?.name || 'there'}! 👋</h1>
      </div>

      {/* Overall Last Year Performance Statistics */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Overall Last Year Performance Statistics</CardTitle>
          <CardDescription>Summary of your LinkedIn engagement metrics for the past year</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-8 md:grid-cols-2 mb-8">
            <div className="space-y-8">
              <Card className="transition-all hover:shadow-lg hover:scale-[1.01]">
                <CardHeader>
                  <CardTitle>Average Engagement Rate</CardTitle>
                  <CardDescription>Across all posts</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="text-5xl font-bold text-primary">{avgEngagementRate}%</div>
                  <div className="text-sm mt-2">
                    {(() => {
                      const rate = parseFloat(avgEngagementRate);
                      if (rate < 1.5) {
                        return (
                          <p className="text-yellow-500">
                            Below LinkedIn's average (2-5%). Aim for 1.5-3.5% for better performance.
                          </p>
                        );
                      } else if (rate <= 3.5) {
                        return (
                          <p className="text-green-500">
                            Good engagement rate! You're within LinkedIn's ideal range of 2-5%.
                          </p>
                        );
                      } else {
                        return (
                          <p className="text-green-500">
                            Excellent engagement rate! You're performing above the typical LinkedIn range of 2-5%.
                          </p>
                        );
                      }
                    })()}
                  </div>
                </CardContent>
              </Card>

              <div className="grid grid-cols-2 gap-4">
                <Card className="transition-all hover:shadow-lg hover:scale-[1.01]">
                  <CardHeader>
                    <CardTitle className="text-base">Posts per Week</CardTitle>
                    <CardDescription>Average posting frequency</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-2">
                      <div>
                        <div className="text-2xl font-bold text-primary">
                          {((postData?.length || 0) / 52).toFixed(1)}
                        </div>
                        <div className="text-sm text-muted-foreground">Posts per Week</div>
                      </div>
                      <div className="text-sm">
                        {(() => {
                          const postsPerWeek = (postData?.length || 0) / 52;
                          if (postsPerWeek < 1) {
                            return (
                              <p className="text-yellow-500">
                                You're posting {postsPerWeek.toFixed(1)} posts/week. Start with 1 post weekly, then
                                build up to 2 posts as you develop a content routine.
                              </p>
                            );
                          } else if (postsPerWeek < 3) {
                            return (
                              <p className="text-yellow-500">
                                Currently at {postsPerWeek.toFixed(1)} posts/week. You're building momentum! Add one
                                more weekly post to reach the minimum recommended frequency of 3 posts/week.
                              </p>
                            );
                          } else if (postsPerWeek <= 5) {
                            return (
                              <p className="text-green-500">
                                Excellent! At {postsPerWeek.toFixed(1)} posts/week, you're maintaining the ideal posting
                                cadence of 3-5 posts/week. Keep up the consistent engagement!
                              </p>
                            );
                          } else {
                            const excess = (postsPerWeek - 5).toFixed(1);
                            return (
                              <p className="text-yellow-500">
                                You're posting {excess} more times per week than recommended. Consider focusing on
                                quality over quantity by reducing to 3-5 high-impact posts weekly to prevent audience
                                fatigue.
                              </p>
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </CardContent>
                </Card>

                <Card className="transition-all hover:shadow-lg hover:scale-[1.01]">
                  <CardHeader>
                    <CardTitle className="text-base">Posts on Best Days</CardTitle>
                    <CardDescription>Tue, Wed, Thu posting ratio</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-2">
                      <div>
                        <div className="text-2xl font-bold text-primary">
                          {(() => {
                            const bestDayPosts = postData?.filter((post) => {
                              const day = new Date(post.timestamp).getDay();
                              return day >= 2 && day <= 4; // Tuesday = 2, Wednesday = 3, Thursday = 4
                            }).length;
                            return `${((bestDayPosts / (postData?.length || 1)) * 100).toFixed(0)}%`;
                          })()}
                        </div>
                        <div className="text-sm text-muted-foreground">Posts on optimal days</div>
                      </div>
                      <div className="text-sm text-muted-foreground">
                        Tuesday through Thursday, especially during 9 AM–12 PM when professionals are most active.
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>

            <Card className="transition-all hover:shadow-lg hover:scale-[1.01]">
              <CardHeader>
                <CardTitle>Posts Impressions Over Time</CardTitle>
                <CardDescription>
                  <div className="flex items-center justify-between pt-2">
                    <div className="flex items-center gap-2">
                      <Target className="h-4 w-4 text-muted-foreground" />
                      <span>YTD Posts: {postData?.length || 0}</span>
                    </div>
                    <div className="flex items-center gap-2 text-sm">
                      {(() => {
                        const totalPosts = postData?.length || 0;
                        if (totalPosts < 100) {
                          return (
                            <div className="flex items-center gap-2 text-yellow-500">
                              <AlertTriangle className="h-4 w-4" />
                              <span>Aim for ~50 posts/year to maximize reach</span>
                            </div>
                          );
                        } else if (totalPosts <= 200) {
                          return (
                            <div className="flex items-center gap-2 text-green-500">
                              <Award className="h-4 w-4" />
                              <span>Great posting volume!</span>
                            </div>
                          );
                        } else {
                          return (
                            <div className="flex items-center gap-2 text-yellow-500">
                              <AlertTriangle className="h-4 w-4" />
                              <span>Consider focusing on quality over quantity</span>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </CardDescription>
              </CardHeader>
              <CardContent className="h-[300px]">
                <ChartComponent
                  data={prepareChartData(postData)}
                  colors={{
                    backgroundColor: 'white',
                    lineColor: '#2962FF',
                    textColor: 'black',
                    areaTopColor: '#2962FF',
                    areaBottomColor: 'rgba(41, 98, 255, 0.28)',
                  }}
                />
              </CardContent>
            </Card>
          </div>

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4 mb-8">
            {metrics.map(({ icon: Icon, label, value, substat }) => (
              <Card key={label} className="transition-all hover:shadow-lg hover:scale-[1.01]">
                <CardHeader className="flex flex-row items-center justify-between pb-2">
                  <CardTitle className="text-sm font-medium">{label}</CardTitle>
                  <Icon className="h-6 w-6 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-3xl font-bold">{value}</div>
                  {substat && <div className="text-sm text-muted-foreground mt-1">{substat}</div>}
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Analytics Dashboard Section */}
      <Card className="mb-6">
        <CardHeader className="flex flex-row items-center justify-between">
          <div>
            <CardTitle>LinkedIn Analytics</CardTitle>
            <CardDescription>Data as of {dashboard?.scrapeDate}</CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
            {dashboard?.analytics.map((item) => {
              // Define icon mapping
              const getIcon = (label) => {
                switch (label) {
                  case 'Profile views':
                    return Users;
                  case 'Post impressions':
                    return TrendingUp;
                  case 'Search appearances':
                    return BarChart;
                  case 'Followers':
                    return Briefcase;
                  default:
                    return Users;
                }
              };
              const Icon = getIcon(item.label);

              return (
                <div
                  key={item.label}
                  className="p-4 rounded-lg border bg-card hover:shadow-md transition-all hover:scale-[1.01]"
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <Icon className="h-4 w-4 text-muted-foreground" />
                      <span className="text-sm font-medium text-muted-foreground">{item.label}</span>
                    </div>
                    <div className="text-3xl font-bold">{item.value}</div>
                    <div className="flex items-center gap-1">
                      <span
                        className={`text-xs text-muted-foreground ${
                          item.trend?.includes('increase')
                            ? 'text-green-500'
                            : item.trend?.includes('decrease')
                              ? 'text-yellow-500'
                              : ''
                        }`}
                      >
                        {item.period || item.trend}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>

      <Card className="mb-6 transition-all hover:shadow-lg hover:scale-[1.01]">
        <CardHeader>
          <CardTitle>Weekly Sharing Tracker</CardTitle>
          <CardDescription>
            Increase your visibility by posting, commenting, or contributing to collaborative articles
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium">Week {dashboard?.weeklySharingTracker.dateRange}</span>
              <Badge variant="outline" className="text-primary">
                {totalActions - completedActions} actions remaining
              </Badge>
            </div>

            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="font-medium">
                  {completedActions} of {totalActions} actions completed
                </span>
                <span className="text-muted-foreground">{progressPercentage.toFixed(0)}%</span>
              </div>
              <Progress value={progressPercentage} className="h-2" />
            </div>

            <div className="space-y-3">
              <p className="text-sm text-muted-foreground">
                Take {totalActions - completedActions} more{' '}
                {totalActions - completedActions === 1 ? 'action' : 'actions'} to achieve your weekly sharing goal
              </p>

              <div className="flex flex-wrap gap-2">
                <Badge variant="secondary">Post content</Badge>
                <Badge variant="secondary">Comment</Badge>
                <Badge variant="secondary">Collaborate</Badge>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Hashtag Performance Table */}
      <Card className="mt-6 transition-all hover:shadow-lg hover:scale-[1.01]">
        <CardHeader>
          <CardTitle>Hashtag Performance</CardTitle>
          <CardDescription>Analysis of hashtag efficiency based on engagement and reach</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid md:grid-cols-4 gap-6 mb-6">
            <Card className="col-span-1">
              <CardHeader>
                <CardTitle>Hashtag Usage</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <div className="text-3xl font-bold">
                      {(Object.keys(aggregatedData.hashtags).length / postData.length).toFixed(1)}
                    </div>
                    <div className="text-sm text-muted-foreground">Average Hashtags per Post</div>
                  </div>
                  <div className="text-sm">
                    {(() => {
                      const avgHashtags = Object.keys(aggregatedData.hashtags).length / postData.length;
                      if (avgHashtags < 2) {
                        return (
                          <p className="text-yellow-500">
                            You're using fewer hashtags than Postilize recommends.
                            <br /> Consider using 2-3 targeted hashtags per post to increase your content visibility.
                          </p>
                        );
                      } else if (avgHashtags <= 4) {
                        return (
                          <p className="text-green-500">
                            Great job! You're following Postilize's best practice of using 2-3 hashtags per post.
                          </p>
                        );
                      } else {
                        return (
                          <p className="text-red-500">
                            You're using more hashtags than Postilize recommends. <br /> Try limiting to 2-3 targeted
                            hashtags per post for optimal engagement.
                          </p>
                        );
                      }
                    })()}
                  </div>
                </div>
              </CardContent>
            </Card>
            <div className="col-span-3">
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b">
                    <th className="pb-2">Hashtag</th>
                    <th className="pb-2">Uses</th>
                    <th className="pb-2">Engagement Rate</th>
                    <th className="pb-2">Avg Impressions</th>
                    <th className="pb-2">Total Impressions</th>
                  </tr>
                </thead>
                <tbody>
                  {hashtagPerformance.map((hash) => (
                    <tr key={hash.tag} className="border-b last:border-0">
                      <td className="py-2">#{hash.tag}</td>
                      <td className="py-2">{hash.uses}</td>
                      <td className="py-2">{hash.engagementRate}%</td>
                      <td className="py-2">{hash.avgImpressions.toLocaleString()}</td>
                      <td className="py-2">{hash.totalImpressions.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Individual Post Performance - Now as a separate section */}
      <div className="mt-6">
        <h2 className="text-3xl font-bold mb-6">Individual Post Performance</h2>
        <div className="grid md:grid-cols-2 gap-6">
          {/* Top Performing Posts */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Top 5 Posts</h3>
            <div className="space-y-4">
              {[...postData]
                .sort((a, b) => b.engagementRate - a.engagementRate)
                .slice(0, 5)
                .map((post, index) => (
                  <Accordion
                    type="single"
                    collapsible
                    key={post.urn}
                    className="transition-all hover:shadow-lg hover:scale-[1.01]"
                  >
                    <AccordionItem value="content">
                      <Card className="transition-all hover:shadow-lg hover:scale-[1.01]">
                        <CardContent className="pt-6">
                          <div className="space-y-3">
                            <div className="flex justify-between items-center">
                              <span className="text-sm font-medium text-muted-foreground">{post.humanDate}</span>
                              <Badge variant="default">{post.engagementRate.toFixed(2)}% Engagement</Badge>
                            </div>
                            <AccordionTrigger className="hover:no-underline">
                              <p className="text-sm line-clamp-2 text-left">{post.content}</p>
                            </AccordionTrigger>
                            <AccordionContent>
                              <p className="text-sm pt-2">{post.content}</p>
                            </AccordionContent>
                            <div className="grid grid-cols-4 gap-2 text-center">
                              <div>
                                <div className="text-sm font-medium">{post.likes}</div>
                                <div className="text-xs text-muted-foreground">Likes</div>
                              </div>
                              <div>
                                <div className="text-sm font-medium">{post.comments}</div>
                                <div className="text-xs text-muted-foreground">Comments</div>
                              </div>
                              <div>
                                <div className="text-sm font-medium">{post.shares}</div>
                                <div className="text-xs text-muted-foreground">Shares</div>
                              </div>
                              <div>
                                <div className="text-sm font-medium">{post.impressions}</div>
                                <div className="text-xs text-muted-foreground">Views</div>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </AccordionItem>
                  </Accordion>
                ))}
            </div>
          </div>

          {/* Bottom Performing Posts */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Bottom 5 Posts</h3>
            <div className="space-y-4">
              {[...postData]
                .sort((a, b) => a.engagementRate - b.engagementRate)
                .slice(0, 5)
                .map((post, index) => (
                  <Accordion
                    type="single"
                    collapsible
                    key={post.urn}
                    className="transition-all hover:shadow-lg hover:scale-[1.01]"
                  >
                    <AccordionItem value="content">
                      <Card className="transition-all hover:shadow-lg hover:scale-[1.01]">
                        <CardContent className="pt-6">
                          <div className="space-y-3">
                            <div className="flex justify-between items-center">
                              <span className="text-sm font-medium text-muted-foreground">{post.humanDate}</span>
                              <Badge variant="secondary">{post.engagementRate.toFixed(2)}% Engagement</Badge>
                            </div>
                            <AccordionTrigger className="hover:no-underline">
                              <p className="text-sm line-clamp-2 text-left">{post.content}</p>
                            </AccordionTrigger>
                            <AccordionContent>
                              <p className="text-sm pt-2">{post.content}</p>
                            </AccordionContent>
                            <div className="grid grid-cols-4 gap-2 text-center">
                              <div>
                                <div className="text-sm font-medium">{post.likes}</div>
                                <div className="text-xs text-muted-foreground">Likes</div>
                              </div>
                              <div>
                                <div className="text-sm font-medium">{post.comments}</div>
                                <div className="text-xs text-muted-foreground">Comments</div>
                              </div>
                              <div>
                                <div className="text-sm font-medium">{post.shares}</div>
                                <div className="text-xs text-muted-foreground">Shares</div>
                              </div>
                              <div>
                                <div className="text-sm font-medium">{post.impressions}</div>
                                <div className="text-xs text-muted-foreground">Views</div>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </AccordionItem>
                  </Accordion>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
