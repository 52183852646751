import React, { useMemo, useState, useEffect } from 'react';
import { Mail, Linkedin, ArrowUpRight, ArrowDownLeft, User, Plus, Calendar, Video } from 'lucide-react';
import moment from 'moment';

export const InteractionIcons = ({ interaction }: { interaction: any }) => {
  const messagingPlatform = interaction?.messagingPlatform;
  const direction = interaction?.messagingTraffic;
  const action = interaction?.action;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderInteractionIcon = () => {
    switch (messagingPlatform) {
      case 'email':
        return <Mail className="h-4 w-4 text-gray-500" />;
      case 'linkedin':
        return <Linkedin className="h-4 w-4 text-gray-500" />;
      case 'calendar':
        return <Calendar className="h-4 w-4 text-gray-500" />;
      case 'meeting':
        return <Video className="h-4 w-4 text-gray-500" />;
      case 'none':
        return action === 'addedToSequence' ? <User className="h-4 w-4 text-gray-500" /> : null;
      default:
        return null;
    }
  };

  const renderDirectionIcon = () => {
    if (direction === 'incoming') {
      return <ArrowDownLeft className="h-4 w-4 text-green-500" />;
    } else if (direction === 'outgoing') {
      return <ArrowUpRight className="h-4 w-4 text-blue-500" />;
    } else if (action === 'addedToSequence') {
      return <Plus className="h-4 w-4 text-green-500" />;
    }
    return null;
  };

  const formatTime = (time: string) => {
    if (!time) return null;
    if (window.innerWidth < 1200) {
      return time
        .replace('a month', '1mo')
        .replace('a week', '1wk')
        .replace('a year', '1yr')
        .replace('a day', '1d')
        .replace(' month', 'mo')
        .replace(' week', 'wk')
        .replace(' year', 'yr')
        .replace(' days', 'd');
    }
    return time;
  };

  const userNameDisplay = useMemo(() => {
    const name =
      interaction?.user?.name || interaction?.userName
        ? windowWidth < 1200
          ? (interaction?.user?.name || interaction?.userName)?.split(' ')[0]
          : interaction?.user?.name || interaction?.userName
        : null;
    if (name) {
      return <span className="text-muted-foreground">&nbsp;with {name}</span>;
    }
    return null;
  }, [interaction?.user?.name, interaction?.userName, windowWidth]);

  return (
    <div className="flex items-center text-sm">
      <div className="flex items-center gap-2">
        <div className="flex items-center">
          {renderInteractionIcon()}
          {renderDirectionIcon()}
        </div>
        <div>
          {(interaction?.actionDate &&
            moment(interaction?.actionDate).isValid() &&
            formatTime(moment(interaction?.actionDate).fromNow())) ||
            'None'}
        </div>
      </div>
      {userNameDisplay}
    </div>
  );
};
