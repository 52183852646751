// Turns any nested object of objects into an array of all 'leaf' values inside the nested structure
const flattenObjectValues = (obj) => {
  if (obj === null || obj === undefined) return [obj];
  return Object.values(obj).flatMap((val) =>
    typeof val === 'object' && val !== null ? flattenObjectValues(val) : val,
  );
};

const flattenObjectKeys = (obj) => {
  if (obj === null || obj === undefined) return [obj];
  return Object.keys(obj).flatMap((key) =>
    typeof obj[key] === 'object' && obj[key] !== null ? flattenObjectKeys(obj[key]) : key,
  );
};

// Freezes any nested object of objects
const deepFreeze = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) deepFreeze(obj[key]);
  });
  return Object.freeze(obj);
};

// A class that allows for direct access to properties of an object and a flattened array of its values
// It also freezes the object and prevents modification
// Since it is both frozen and flattened, it is called an 'IceSheet'
// We don't store the keys because they are the same as the object keys
class IceSheet {
  constructor(obj) {
    this.data = deepFreeze(obj);
    this.flatValues = flattenObjectValues(obj);
    this.flatKeys = flattenObjectKeys(obj);
    Object.freeze(this);

    // Return a proxy to allow direct access to properties
    return new Proxy(this, {
      get(target, prop) {
        if (prop === 'flatValues') {
          return target.getFlatValues();
        }
        if (prop === 'flatKeys') {
          return target.getFlatKeys();
        }
        if (prop in target.data) {
          return target.data[prop];
        }
        return undefined; // Ensure undefined is returned for non-existent properties
      },
      set() {
        throw new Error('Cannot modify a frozen object');
      },
    });
  }

  getFlatValues() {
    return this.flatValues;
  }

  getFlatKeys() {
    return this.flatKeys;
  }
}

module.exports = {
  deepFreeze,
  flattenObjectValues,
  IceSheet,
};
