import * as React from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Box, Typography, Checkbox } from '@mui/material';
import Config from '@/clientConfig/config';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomModal from '@/pages/login/components/confirmModal';
import { handleAuthentication } from '@/helper/authUtility';
import { useTheme } from '@mui/material';
import { ReactComponent as LoginLogo } from '@/assets/Icons/LoginLogo.svg';
import TOSModal from '@/pages/login/components/tosModal';
import PrivacyModal from '@/pages/login/components/privacyModal';
import { lightTheme } from '@/themes/light.theme';

export const loginTheme = createTheme({
  palette: {
    primaryCL: lightTheme.palette.primaryCL,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          background: #3063EA
        }
      `,
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          'color': lightTheme.palette.primaryCL.Black200,
          '&.Mui-checked': {
            color: lightTheme.palette.primaryCL.Black200,
          },
        },
      },
    },
  },
});

const containerStyle = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const boxStyle = (theme) => ({
  backgroundColor: theme.palette.primary.lightGrey,
  px: 10,
  py: 11,
  borderRadius: 5,
  minWidth: 300,
});

const titleStyle = (theme) => ({
  fontSize: '35px',
  fontWeight: 700,
  color: theme.palette.primary.grey,
  lineHeight: '1',
  mt: 1,
});

const checkboxContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  mt: 2,
};

const termsTextStyle = (theme) => ({
  'fontWeight': 500,
  'fontSize': '14px',
  'color': theme.palette.primary.grey,
  '& a': {
    'color': 'inherit',
    'textDecoration': 'none',
    'paddingBottom': '0px',
    '&:hover': {
      borderColor: 'transparent',
    },
  },
});

const linkStyle = (theme) => ({
  display: 'inline-block',
  fontSize: '14px',
  fontWeight: 800,
  color: theme.palette.primary.grey,
  cursor: 'pointer',
});

export const LoginLayout = ({ children, termsAccepted, handleCheckboxChange }) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState(false);
  const [validateModalOpen, setValidateModalOpen] = React.useState(false);
  const [alertText, setAlertText] = React.useState('');
  const [openTOSModal, setOpenTOSModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

  const handleOpenTOSModal = () => setOpenTOSModal(true);
  const handleCloseTOSModal = () => setOpenTOSModal(false);
  const handleOpenPrivacyModal = () => setOpenPrivacyModal(true);
  const handleClosePrivacyModal = () => setOpenPrivacyModal(false);

  React.useEffect(() => {
    console.log(openModal);
  }, [openModal]);

  const handleModalAction = (action) => {
    setValidateModalOpen(false);
    setAlertText('');
  };

  return (
    <ThemeProvider theme={loginTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={containerStyle}>
        <Box sx={boxStyle(theme)}>
          {validateModalOpen && <CustomModal clickAction={handleModalAction} alertText={alertText} />}

          <LoginLogo width={150} height={25} />

          <Typography sx={titleStyle(theme)}>
            <span style={{ color: theme.palette.primary.blue }}>AI-powered</span> customer acquisition and retention
            tools.
          </Typography>

          {children}

          <Box sx={checkboxContainerStyle}>
            <Checkbox
              checked={!!termsAccepted}
              onChange={(e) => handleCheckboxChange(e.target.checked)}
              name="acceptTerms"
              color="primary"
            />
            <Typography sx={termsTextStyle(theme)}>
              By signing in, I agree to the{' '}
              <Typography sx={linkStyle(theme)}>
                <a
                  href="https://www.postilize.com/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  TOS
                </a>
              </Typography>{' '}
              and{' '}
              <Typography sx={linkStyle(theme)}>
                <a
                  href="https://www.postilize.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  Postilize Privacy Policy
                </a>
              </Typography>
            </Typography>
          </Box>

          <TOSModal open={openTOSModal} close={handleCloseTOSModal} />
          <PrivacyModal open={openPrivacyModal} close={handleClosePrivacyModal} />
        </Box>
      </Container>
      <Outlet />
    </ThemeProvider>
  );
};
