import { Button } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { memo } from 'react';

type SlideToggleProps = {
  leftOption: {
    label: string;
    icon: React.ReactNode;
  };
  rightOption: {
    label: string;
    icon: React.ReactNode;
  };
  value: string;
  onChange: (_value: string) => void;
  variant: 'tall' | 'short';
};

export const SlideToggle = ({ leftOption, rightOption, value, onChange, variant }: SlideToggleProps) => {
  const isTall = variant !== 'short';

  return (
    <div
      className={`
        relative flex items-center bg-gray-100 rounded-full w-[320px] p-[1px]
        ${isTall ? 'h-10' : 'h-7'}
      `}
    >
      <motion.div
        className={`
          absolute bg-white rounded-full shadow-[0_1px_3px_0_rgba(0,0,0,0.1),0_1px_2px_-1px_rgba(0,0,0,0.1)]
          ${isTall ? 'h-9' : 'h-6'} w-[156px]
        `}
        initial={false}
        animate={{
          x: value === leftOption.label ? 1 : 161,
        }}
        transition={{
          type: 'spring',
          stiffness: 500,
          damping: 30,
        }}
      />
      <div className="grid grid-cols-2 w-full relative">
        <Button
          variant="ghost"
          onClick={() => onChange(leftOption.label)}
          className={`
            relative z-10 flex items-center justify-center gap-2 rounded-full h-full
            hover:bg-transparent transition-colors duration-200
            ${value === leftOption.label ? 'text-gray-900' : 'text-gray-500 hover:text-gray-900'}
            ${isTall ? 'px-4 py-2 text-sm' : 'px-3 py-1 text-xs'}
          `}
        >
          {leftOption.icon}
          <span className="truncate">{leftOption.label}</span>
        </Button>
        <Button
          variant="ghost"
          onClick={() => onChange(rightOption.label)}
          className={`
            relative z-10 flex items-center justify-center gap-2 rounded-full h-full
            hover:bg-transparent transition-colors duration-200
            ${value === rightOption.label ? 'text-gray-900' : 'text-gray-500 hover:text-gray-900'}
            ${isTall ? 'px-4 py-2 text-sm' : 'px-3 py-1 text-xs'}
          `}
        >
          {rightOption.icon}
          <span className="truncate">{rightOption.label}</span>
        </Button>
      </div>
    </div>
  );
};

SlideToggle.displayName = 'SlideToggle';
