'use client';

import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { StageBadge } from './stage-badge';
import { ChevronDown, Pencil } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { NO_STAGE_DEFINITION } from '@postilize/shared/constants/ERM';
import { useSelector } from 'react-redux';

type StagePickerProps = {
  client: any;
  currentStageId: string;
  onBaseBadgeClick: () => void;
  onEditStagesClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onOpenChange: (open: boolean) => void;
  onStageSelect: (clientId: string, stageId: string) => void;
  open: boolean;
  stages: any[];
};

export const StagePicker = React.forwardRef<HTMLElement, StagePickerProps>(
  ({ client, currentStageId, onBaseBadgeClick, onEditStagesClick, onOpenChange, onStageSelect, open, stages }, ref) => {
    const currentStage = stages.find((stage) => stage._id === currentStageId) || NO_STAGE_DEFINITION;

    const handleEditStagesClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      onEditStagesClick(e);
    };

    const currentUserId = useSelector((state) => state.auth.user._id);
    const adminIds = useSelector((state) => state.auth.company.company.admins);

    const isAdmin = adminIds.includes(currentUserId);

    return (
      <div ref={ref}>
        <TooltipProvider>
          <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
              <div
                className="flex items-center cursor-pointer"
                role="combobox"
                aria-label="Select a stage"
                aria-expanded="false"
                onClick={onBaseBadgeClick}
              >
                <StageBadge
                  stageId={currentStage._id}
                  initialText={currentStage.name}
                  backgroundColor={currentStage.backgroundColor}
                  textColor={currentStage.textColor}
                  isEditing={false}
                  editable={false}
                  onBlur={() => {}}
                  onChange={() => {}}
                  onKeyDown={() => {}}
                />
                <ChevronDown className="ml-1 h-4 w-4 shrink-0 opacity-50" />
              </div>
            </PopoverTrigger>
            <PopoverContent className="min-w-fit min-h-fit p-0" align="start">
              <div className="relative">
                <div
                  className="max-h-[500px] max-w-full overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
                  style={{
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#D1D5DB #F3F4F6',
                  }}
                >
                  <div className="p-1">
                    {stages.map((stage) => (
                      <Tooltip key={stage._id}>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            className="w-full justify-start mb-1 py-1 px-2"
                            onClick={() => onStageSelect(client._id, stage._id)}
                          >
                            <StageBadge
                              initialText={stage.name}
                              backgroundColor={stage.backgroundColor}
                              textColor={stage.textColor}
                            />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right">
                          Assign the pipeline stage <b>{stage.name}</b> to <b>{client.name}</b>
                        </TooltipContent>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              </div>
              {isAdmin && (
                <div className="p-1 border-t">
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        className="w-full justify-start font-normal"
                        onClick={handleEditStagesClick}
                      >
                        <Pencil className="mr-2 h-4 w-4" />
                        Edit Stages
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">Open stage manager</TooltipContent>
                  </Tooltip>
                </div>
              )}
            </PopoverContent>
          </Popover>
        </TooltipProvider>
      </div>
    );
  },
);
