import React, { useState, useMemo, useCallback, forwardRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Handshake, Send, Building2, User } from 'lucide-react';
import { debounce } from 'lodash';

import { SortableHeader } from './SortableHeader';
import { InteractionsCell } from './InteractionsCell';
import { EngageModal } from './EngageModal';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import {
  changeContactFilterLastInteraction,
  changeContactFilterSearchTerm,
  changeContactMineOnly,
  changeContactPage,
  changeContactSortCriteria,
  changeRelationshipFilterLastInteraction,
  changeRelationshipFilterSearchTerm,
  changeRelationshipPage,
  changeRelationshipSortCriteria,
  changeContactInteractions,
  setInteractionsModalOpen,
} from '@/redux/slices/ERMDashboard';
import { useIsFeatureFlagEnabled } from '@/hooks/useFeatureFlags';
import { ERMPagination } from './ERMPagination';
import { FILTER_ICON_MAP } from './ClientTable';
import { SlideToggle } from '@/v0/SlideToggle';
import { StrengthCell } from './StrengthCell';

type RelationshipTableContext = 'relationship' | 'contact';

export const RelationshipTable = forwardRef<HTMLElement, { context: RelationshipTableContext }>(({ context }, ref) => {
  const dispatch = useDispatch();
  const userCompany = useSelector((state) => state.auth.company.company);
  const selectedClient = useSelector((state) => state.dashboard.selectedClient);
  const mineOnly = useSelector((state) => state.contactTable.queryParams.filters.mineOnly);
  const [isEngageModalOpen, setEngageModalOpen] = useState(false);
  const [activeRelationship, setActiveRelationship] = useState<RelationshipPayload | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const tableState = useSelector((state) => (context === 'contact' ? state.contactTable : state.relationshipTable));

  const { page, limit: itemsPerPage } = useSelector((state) =>
    context === 'contact' ? state.contactTable.queryParams : state.relationshipTable.queryParams,
  );

  const relationships = useMemo(() => {
    if (context === 'contact') {
      return tableState.data.cacheByPage[page] || [];
    }
    return tableState.data.cache[selectedClient?._id]?.[page] || [];
  }, [context, tableState.data.cache, tableState.data.cacheByPage, selectedClient?._id, page]);

  const total = tableState.data.total;

  const filters = tableState.queryParams.filters;
  const shouldShowHidden = filters.showHidden;
  const isLoading =
    context === 'contact' ? tableState.loading.loadingStateByPage[page]?.loading : tableState.loading.loading;
  const sortCriteria = tableState.queryParams.sortCriteria;
  const isStrengthFeatureEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard.strength');

  useEffect(() => {
    setSearchTerm('');
  }, [selectedClient?._id]);

  const handleEngageClick = useCallback((relationship: RelationshipPayload) => {
    setActiveRelationship(relationship);
    setEngageModalOpen(true);
  }, []);

  const handleInteractionsClick = useCallback(
    (relationship: RelationshipPayload) => {
      dispatch(changeContactInteractions({ relationship }));
      dispatch(setInteractionsModalOpen(true));
    },
    [dispatch],
  );

  const handleSort = useCallback(
    (criteria: { key: string; direction: 'asc' | 'desc' }) => {
      dispatch(
        context === 'contact'
          ? changeContactSortCriteria(criteria)
          : changeRelationshipSortCriteria(selectedClient?._id, criteria),
      );
    },
    [dispatch, context, selectedClient?._id],
  );

  const debouncedDispatch = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(
          context === 'contact'
            ? changeContactFilterSearchTerm(value)
            : changeRelationshipFilterSearchTerm(selectedClient?._id, value),
        );
      }, 500),
    [dispatch, context, selectedClient?._id],
  );

  const handleNameSearchTermChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      debouncedDispatch(value);
    },
    [debouncedDispatch],
  );

  const handleToggleLastInteractionFilter = useCallback(
    (interaction: string) => {
      const updatedInteractions = {
        ...filters.lastInteraction,
        [interaction]: !filters.lastInteraction[interaction],
      };
      dispatch(
        context === 'contact'
          ? changeContactFilterLastInteraction(updatedInteractions)
          : changeRelationshipFilterLastInteraction(selectedClient?._id, updatedInteractions),
      );
    },
    [filters.lastInteraction, dispatch, context, selectedClient?._id],
  );

  // ------------------------------
  // Handlers: Toggle All Filters
  // ------------------------------
  const handleToggleAllLastInteractionFilters = useCallback(
    (checked: boolean) => {
      const updatedInteractions = Object.fromEntries(
        Object.keys(filters.lastInteraction).map((interaction) => [interaction, checked]),
      );
      dispatch(
        context === 'contact'
          ? changeContactFilterLastInteraction(updatedInteractions)
          : changeRelationshipFilterLastInteraction(selectedClient?._id, updatedInteractions),
      );
    },
    [dispatch, filters.lastInteraction, context, selectedClient?._id],
  );

  const shouldRenderStrengthColumn = useMemo(
    () => !shouldShowHidden && isStrengthFeatureEnabled,
    [shouldShowHidden, isStrengthFeatureEnabled],
  );

  const loadingOrEmptyMessage = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center mt-4">
          <CircularProgress size={32} color="inherit" />
        </div>
      );
    }

    if (relationships.length === 0) {
      return (
        <div className="flex justify-center items-center mt-4">
          <p className="text-muted-foreground">
            No relationships found{context === 'contact' ? '' : ' for this client'}.
          </p>
        </div>
      );
    }

    return null;
  }, [context, isLoading, relationships.length]);

  const renderedRelationships = useMemo(() => {
    return (
      <tbody className="[&_tr:last-child]:border-0">
        <TooltipProvider>
          {relationships.map((relationship) => {
            const lastInteraction = relationship.allInteractions[relationship.allInteractions.length - 1];

            return (
              <tr
                key={relationship?._id}
                className={cn(
                  'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
                  // isSelected && 'bg-gray-200/50',
                )}
              >
                <td className="p-2 align-middle">
                  <span
                    className="cursor-pointer font-semibold hover:underline max-w-full whitespace-nowrap"
                    onClick={() => handleInteractionsClick(relationship)}
                  >
                    {relationship?.contactDetails?.name}
                  </span>
                </td>
                <td className={cn('p-2 align-middle text-sm w-[25%]')}>{relationship?.contactDetails?.title || ''}</td>
                {context === 'contact' && (
                  <td className={cn('p-2 align-middle text-sm w-[25%]')}>
                    {relationship?.contactDetails?.company || ''}
                  </td>
                )}
                {shouldRenderStrengthColumn && (
                  <td className="p-2 align-middle strength-column">
                    <StrengthCell
                      score={relationship?.relationshipScore || 0}
                      scoreChange={relationship?.scoreChange}
                    />
                  </td>
                )}
                <td className="p-2 align-middle max-w-full overflow-ellipsis truncate whitespace-nowrap">
                  <InteractionsCell interaction={lastInteraction} />
                </td>
                <td className="p-2 align-middle actions-column">
                  <div className="flex items-center justify-between gap-2 group">
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => handleInteractionsClick(relationship)}
                          className="action-button"
                        >
                          <Handshake className="h-4 w-4 icon" />
                          <span className="sr-only">Interactions</span>
                          <span className="hidden text">Interactions</span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Interactions</TooltipContent>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          size="sm"
                          variant="outline"
                          onClick={() => handleEngageClick(relationship)}
                          className="action-button"
                        >
                          <Send className="h-4 w-4 icon" />
                          <span className="sr-only">Engage</span>
                          <span className="hidden text">Engage</span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>Engage</TooltipContent>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })}
        </TooltipProvider>
      </tbody>
    );
  }, [relationships, context, shouldRenderStrengthColumn, handleInteractionsClick, handleEngageClick]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (context === 'contact') {
        dispatch(changeContactPage(newPage));
      } else {
        dispatch(changeRelationshipPage(selectedClient?._id, newPage));
      }
    },
    [context, dispatch, selectedClient?._id],
  );

  const toggleValue = mineOnly ? 'Only Me' : `All ${userCompany?.name}`;

  const handleToggleChange = useCallback(
    (newValue: string) => {
      dispatch(changeContactMineOnly(newValue === 'Only Me'));
    },
    [dispatch],
  );

  const headerComponent = useMemo(() => {
    const wrapper = (component: React.ReactNode) => (
      <div className="flex items-center justify-between my-3 mx-4">
        <div className="flex items-center gap-3 bg-white">
          <h2 className="text-lg font-semibold text-muted-foreground">Viewing contacts for</h2>
          {component}
        </div>
      </div>
    );
    if (context === 'contact') {
      return wrapper(
        <div className="flex-1 max-w-md">
          <SlideToggle
            leftOption={{ label: `All ${userCompany?.name}`, icon: <Building2 className="h-4 w-4" /> }}
            rightOption={{ label: 'Only Me', icon: <User className="h-4 w-4" /> }}
            value={toggleValue}
            onChange={handleToggleChange}
            variant="short"
          />
        </div>,
      );
    }

    if (context === 'relationship') {
      return wrapper(`Relationships for ${selectedClient?.name}`);
    }

    return null;
  }, [context, userCompany?.name, toggleValue, selectedClient?.name, handleToggleChange]);

  return (
    <div className="rounded-lg pb-4" ref={ref}>
      {headerComponent}

      <div className="p-2 relative w-full overflow-auto pb-2.5">
        <style jsx>{`
          @media (min-width: 1500px) {
            .action-button .icon {
              display: none;
            }
            .action-button .text {
              display: inline;
            }
            .actions-column {
              width: 200px;
            }
          }
          @media (max-width: 1499px) {
            .actions-column {
              width: 100px;
            }
          }
          @media (min-width: 1200px) {
            .strength-column {
              width: 124px;
            }
          }
          @media (max-width: 1199px) {
            .strength-column {
              width: 70px;
            }
          }
        `}</style>

        <table className="p-2 pb-2.5 w-full caption-bottom text-sm">
          <thead className="[&_tr]:border-b">
            <tr className="border-b transition-colors data-[state=selected]:bg-muted">
              <SortableHeader
                label="Name"
                sortKey="contacts.name"
                currentSort={sortCriteria}
                onSort={handleSort}
                className="max-w-full"
                onNameTextFilterChange={handleNameSearchTermChange}
                searchValue={searchTerm}
              />

              <SortableHeader
                label="Position"
                sortKey="contacts.title"
                currentSort={sortCriteria}
                onSort={handleSort}
                className="w-[1px] whitespace-nowrap"
              />
              {context === 'contact' && (
                <SortableHeader
                  label="Company"
                  sortKey="contacts.company"
                  currentSort={sortCriteria}
                  onSort={handleSort}
                  className="w-[1px] whitespace-nowrap"
                />
              )}
              {shouldRenderStrengthColumn && (
                <SortableHeader
                  label="Strength"
                  sortKey="relationshipScore"
                  currentSort={sortCriteria}
                  onSort={handleSort}
                  className="strength-column"
                />
              )}
              <SortableHeader
                label="Last Interaction"
                sortKey="lastInteraction"
                currentSort={sortCriteria}
                onSort={handleSort}
                className="w-[1px] whitespace-nowrap"
                filterOptions={filters.lastInteraction}
                onFilterChange={handleToggleLastInteractionFilter}
                onToggleAll={handleToggleAllLastInteractionFilters}
                filterIcons={FILTER_ICON_MAP}
                enableDateFilter={false}
              />
              <th className="align-middle font-medium h-10 px-2 text-left text-muted-foreground actions-column">
                Actions
              </th>
            </tr>
          </thead>
          {renderedRelationships}
        </table>
        {loadingOrEmptyMessage}
      </div>

      <ERMPagination
        currentPage={page}
        totalPages={Math.ceil(total / itemsPerPage)}
        onPageChange={handlePageChange}
        ref={ref}
      />

      <EngageModal
        isOpen={isEngageModalOpen}
        onClose={() => setEngageModalOpen(false)}
        targetContact={activeRelationship}
      />
    </div>
  );
});
