import type { Middleware } from '@reduxjs/toolkit';

type StatePersistenceConfig = {
  [sliceName: string]: {
    keys: string[];
    actions: string[];
    defaultState: any;
  };
};

const statePersistenceConfig: StatePersistenceConfig = {};

export const registerStatePersistence = ({
  slice,
  keys,
  actions,
  defaultState,
}: {
  slice: string;
  keys: string[];
  actions: string[];
  defaultState: any;
}) => {
  statePersistenceConfig[slice] = { keys, actions, defaultState };
};

// Helper function to set nested values
const setNestedValue = (obj, path, value) => {
  const keys = path.split('.');
  let current = obj;
  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      current[key] = value;
    } else {
      current[key] = current[key] || {};
      current = current[key];
    }
  });
};

// Helper function to perform a deep merge of two objects
const deepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  return { ...target, ...source };
};

export const saveStateMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  const actionsToPersist = Object.values(statePersistenceConfig).flatMap((config) => config.actions);

  if (actionsToPersist.includes(action.type)) {
    const state = storeAPI.getState();
    const persistedState = {};

    for (const [slice, { keys }] of Object.entries(statePersistenceConfig)) {
      persistedState[slice] = keys.reduce((acc, part) => {
        const keysParts = part.split('.');
        let value = state[slice];
        for (const key of keysParts) {
          value = value[key];
        }
        setNestedValue(acc, part, value);
        return acc;
      }, {});
    }

    localStorage.setItem('Postilize', JSON.stringify(persistedState));
  }

  return result;
};

export const loadPersistedState = () => {
  const persistedState = JSON.parse(localStorage.getItem('Postilize') || '{}');
  const mergedState = {};

  for (const [slice, { defaultState }] of Object.entries(statePersistenceConfig)) {
    mergedState[slice] = deepMerge(defaultState, persistedState[slice] || {});
  }

  return mergedState;
};
