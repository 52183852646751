import React from 'react';
import { Users } from 'lucide-react';

export const CountDisplay: React.FC<{ count: number }> = ({ count }) => {
  return (
    <div className="inline-flex items-center gap-1 ml-1 text-gray-700 hover:text-gray-900 transition-colors duration-150">
      <Users className="w-[13px] h-[13px]" />
      <span
        className="text-[11px] leading-none font-medium"
        style={{
          letterSpacing: '-0.01em',
        }}
      >
        {count}
      </span>
    </div>
  );
};
