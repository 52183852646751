import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

type ViewType = 'active' | 'hidden';

interface ClientFilterProps {
  value: ViewType;
  onChange: (value: ViewType) => void;
}

export function ClientFilter({ value, onChange }: ClientFilterProps) {
  return (
    <div className="flex items-center gap-6">
      <nav className="flex items-center space-x-6 text-sm font-medium">
        {['active', 'hidden'].map((type) => (
          <button
            key={type}
            onClick={() => onChange(type as ViewType)}
            className={cn(
              'relative px-1 py-2 text-sm transition-colors hover:text-foreground',
              value === type ? 'text-foreground' : 'text-muted-foreground',
            )}
          >
            <span className="capitalize">{type}</span>
            {value === type && (
              <motion.div
                layoutId="activeSection"
                className="absolute left-0 right-0 -bottom-px h-0.5 bg-primary"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              />
            )}
          </button>
        ))}
      </nav>
      <div className="h-4 w-px bg-border hidden md:block" />
      <div className="text-sm text-muted-foreground hidden md:block">
        {value === 'active' ? 'Showing active clients' : 'Showing hidden clients'}
      </div>
    </div>
  );
}
