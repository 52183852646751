import { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { PContactList } from '@Library';
import { ReactComponent as Search } from '@Assets/Icons/Search.svg';
import Http from '@/http/httpClient';
import {
  SequenceMailableStepIds,
  SequenceStepStatus,
  TwitterStepIds,
  RULE_ENGINE_CODES,
} from '@/constants/constant.js';
import { truncateText, rollIndex } from '@/helper/utilities.js';
import { formatTimeUntilNextStep, formatTimeToolTip } from './helpers';

function customSort(contact) {
  if (!contact.nextStepExecTime) {
    return contact.status === 'active' ? 1 : 0;
  } else {
    return contact.status === 'paused' ? 0 : -1;
  }
}

const getStepTypeWord = (stepType) => {
  switch (stepType) {
    case 'share':
      return 'reshared';
    case 'connect':
      return `request to connect`;
    case 'follow':
    case 'comment':
      return `${stepType}ed`;
    default:
      return `${stepType}d`;
  }
};

const getTarget = (stepType) => {
  switch (stepType) {
    case 'connect':
      return `on social media`;
    case 'follow':
      return `them on social media`;
    default:
      return `their posts`;
  }
};

export default function DelegationSequenceReview(props) {
  let { contacts, contacts2, listItem, handleSequenceModification } = props;

  // State declarations
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [activeButton, setActiveButton] = useState('All');
  const [sortFields, setSortFields] = useState(['hasNull', 'name', 'title', 'company']);
  const [searchContact, setSearchContact] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Memoized values
  const sequenceContacts = useMemo(() => contacts, [contacts]);
  const sequenceContacts2 = useMemo(() => contacts2, [contacts2]);
  const fuse = useMemo(
    () =>
      new Fuse(sequenceContacts, {
        keys: ['name', 'company', 'title'],
        threshold: 0.4,
        location: 0,
        distance: 100,
        findAllMatches: true,
        minMatchCharLength: 1,
      }),
    [sequenceContacts],
  );
  const fuse2 = useMemo(
    () =>
      new Fuse(sequenceContacts2, {
        keys: ['name', 'company', 'title'],
        threshold: 0.4,
        location: 0,
        distance: 100,
        findAllMatches: true,
        minMatchCharLength: 1,
      }),
    [sequenceContacts2],
  );

  // Handler functions
  const handleButtonClick = (action) => {
    if (['Remove', 'Pause', 'Unpause'].includes(action)) {
      handleContactAction(action, selectedContacts);
    } else if (['All', 'Upcoming', 'Recent', 'Active', 'Paused'].includes(action)) {
      setActiveButton(action);
    }
  };

  const handleToggleAll = (newChecked) => {
    if (newChecked) {
      setSelectedContacts(preparedContacts.map((contact) => contact._id));
    } else {
      setSelectedContacts([]);
    }
  };

  const handleContactAction = (action, contactIds) => {
    setIsLoading(true);
    const sequenceData = { sequenceId: listItem._id, contactIds: contactIds };
    Http.postData('updateSequenceContactSettings', {
      type: action,
      sequenceData,
      pausedType: 'Manually paused',
    })
      .then((res) => {
        if (res && res.status) {
          if (action === 'Remove') setSelectedContacts([]);
          handleSequenceModification();
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSearchSequenceContact = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchContact(value);
    if (value === '') {
      setFilteredContacts(sequenceContacts);
    } else {
      const results = fuse.search(value).map(({ item }) => item);
      setFilteredContacts(results);
    }
  };

  const handleClearSearch = () => {
    setSearchContact('');
    setFilteredContacts(sequenceContacts);
  };

  const handleContactSelection = (contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      const isSelected = prevSelectedContacts.includes(contactId);
      return isSelected ? prevSelectedContacts.filter((id) => id !== contactId) : [...prevSelectedContacts, contactId];
    });
  };

  const getContactStepStatus = (contact) => {
    let status = '';
    if (contact?.nextStepExecTime) {
      const stepDetails = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);

      let emailTemplate = stepDetails?.emailTemplate;
      if (stepDetails?.id === 'checkin.recurring') {
        const nextStepStatus = contact.stepStatus;
        const validRecurrenceIndex = rollIndex(nextStepStatus?.recurrenceIndex, stepDetails?.emailTemplates?.length);
        emailTemplate = stepDetails?.emailTemplates?.[validRecurrenceIndex];
      }
      const preciseSubject = truncateText(emailTemplate?.subjectLine, 30);
      status = preciseSubject;
    } else if (contact?.lastCompletedStepId) {
      const lastStep = contact?.stepStatus?.id?.toString() === contact.lastCompletedStepId?.toString();
      if (
        lastStep &&
        SequenceMailableStepIds.includes(contact.stepStatus?.stepType) &&
        contact.stepStatus?.status === 'failed'
      ) {
        status = 'Bounced';
      }
    }

    const isTwiiiterStep = TwitterStepIds.includes(contact?.stepStatus?.stepType);
    if (isTwiiiterStep) {
      const stepType = contact?.stepStatus?.stepType;
      status = `Auto-${getStepTypeWord(stepType)} ${getTarget(stepType)}`;
    }

    return status;
  };

  // useEffect hooks
  useEffect(() => {
    const sortingFieldsMap = {
      All: ['hasNull', 'name', 'title', 'company'],
      Role: ['hasNull', 'title', 'name', 'company'],
      Company: ['hasNull', 'company', 'name', 'title'],
      Channel: [],
    };

    const sortingFields = sortingFieldsMap[activeButton] || ['hasNull', 'name', 'title', 'company'];
    setSortFields(sortingFields);
    setSelectedContacts([]);
  }, [activeButton]);

  useEffect(() => {
    const searchQuery = searchContact.toLowerCase();

    let contacts = [];
    if (activeButton === 'All') {
      contacts = searchQuery ? fuse2.search(searchQuery).map((contact) => contact.item) : sequenceContacts2;
    } else {
      contacts = searchQuery ? fuse.search(searchQuery).map((contact) => contact.item) : sequenceContacts;
    }

    const preliminaryFiltered = contacts?.filter((contact) => {
      if (contact === null) return false;

      const isStatusActive = contact.status === 'active';
      const isStatusPaused = contact.status === 'paused';
      const stepStatus = contact.stepStatus;
      const stepType = stepStatus?.stepType;
      const startDate = new Date(stepStatus?.startDate);
      const currentDate = new Date();
      const ONE_DAYms = 1000 * 60 * 60 * 24;

      switch (activeButton) {
        case 'All':
          return true;

        case 'Upcoming':
          return (
            isStatusActive &&
            ![SequenceStepStatus.MANUALLY_DELETED].includes(stepStatus?.status) &&
            ((contact.nextStepId === stepStatus?.id &&
              SequenceMailableStepIds.includes(stepStatus?.stepType) &&
              startDate.getTime() + ONE_DAYms > currentDate.getTime()) ||
              (contact.nextStepId === stepStatus?.id && !SequenceMailableStepIds.includes(stepStatus?.stepType)) ||
              (contact.nextStepId === null && TwitterStepIds.includes(stepType) && startDate > currentDate))
          );

        case 'Recent':
          return (
            isStatusActive &&
            ([
              'triggered',
              'failed',
              'skipped',
              'notapproved',
              'limitexceeded',
              SequenceStepStatus.MANUALLY_DELETED,
            ].includes(stepStatus?.status) ||
              (TwitterStepIds.includes(stepType) && startDate < currentDate))
          );

        case 'Paused':
          return isStatusPaused;

        case 'Active':
          return (
            isStatusActive &&
            ![
              'triggered',
              'failed',
              'skipped',
              'notapproved',
              'limitexceeded',
              SequenceStepStatus.MANUALLY_DELETED,
            ].includes(stepStatus?.status) &&
            !TwitterStepIds.includes(stepType)
          );

        case 'Expired':
          return (
            isStatusActive &&
            stepStatus?.status === SequenceStepStatus.NOTAPPROVED &&
            startDate.getTime() < currentDate.getTime() - ONE_DAYms
          );

        default:
          return false;
      }
    });

    let filtered = [];
    if (activeButton === 'Paused') {
      const uniqueIds = new Set();
      filtered = preliminaryFiltered.filter((contact) => {
        const isDuplicate = uniqueIds.has(contact._id);
        uniqueIds.add(contact._id);
        return !isDuplicate;
      });
    } else {
      filtered = preliminaryFiltered;
    }

    const sortedContacts = _.orderBy(filtered, activeButton === 'Active' ? customSort : sortFields, ['asc']);
    setFilteredContacts([...sortedContacts]);
  }, [searchContact, sortFields, sequenceContacts, props.refreshKey, activeButton, fuse2, sequenceContacts2, fuse]);

  const preparedContacts = filteredContacts
    .map((contact) => {
      if (!contact) return null;

      const currentStep = listItem?.template?.steps?.find((s) => s._id === contact?.stepStatus?.id);

      const stepIndex = listItem?.template?.steps?.findIndex((s) => s._id === contact?.stepStatus?.id) + 1;
      const totalSteps = listItem?.template?.steps?.length;

      const emailSubject = currentStep?.emailTemplate?.subjectLine;
      const stepSubtitle = `Step ${stepIndex}/${totalSteps}`;
      const stepStatus = getContactStepStatus(contact);

      return {
        _id: contact._id,
        name: contact.name,
        photo_url: contact.photo_url,
        role: contact.title,
        company: contact.company,
        time: formatTimeUntilNextStep(contact, currentStep, listItem),
        timeToolTip: formatTimeToolTip(contact, currentStep),
        // seeEmail: () => handleSeeEmail(contact),
        emailSubject: emailSubject || null,
        stepSubtitle: emailSubject ? stepSubtitle : stepStatus,
        checked: selectedContacts.includes(contact._id),
        onCheck: () => handleContactSelection(contact._id),
        // onClickName: () => handleContactClick(contact),
        isLinkedInMail: contact?.stepStatus?.stepType === 'linkedInEmail',
      };
    })
    .filter(Boolean);

  const uniqueContactIDs = [...new Set(preparedContacts.map((contact) => contact._id))];
  const uniqueSelectedIDs = [...new Set(selectedContacts)];

  return (
    <>
      <PContactList
        disableCheckbox
        headerText={listItem.name}
        inputPlaceHolder="Search name, role, company"
        inputIcon={Search}
        inputOnChange={handleSearchSequenceContact}
        onClear={handleClearSearch}
        activeFilter={activeButton}
        filterAction={handleButtonClick}
        filterChange={setActiveButton}
        filterToggleAll={handleToggleAll}
        filterTotalCount={uniqueContactIDs.length}
        filterSelectedCount={uniqueSelectedIDs.length}
        contacts={preparedContacts}
        loadingState={isLoading}
      />
    </>
  );
}
