import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Check, Edit, X, ChevronRight, Linkedin, Mail } from 'lucide-react';
import { TopicType } from '@/constants/constant';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { GetCookie } from '../../../helper/cookieManager';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';

const PPostCardCommonContent = ({
  children,
  avatarNode = null,
  headerNode = null,
  post,
  setPost,
  handleSendToMessage,
  errorNote,
}) => {
  const featureFlags = useFeatureFlags();
  const contactDetails = post?.sequenceAttr?.contactDetails || {};
  const contact = useMemo(() => post.sequenceAttr?.contactDetails || {}, [post.sequenceAttr?.contactDetails]);

  const user = GetCookie('user');
  const isLinkedInPrimary = user?.providers?.find((p) => p.provider === 'linkedin' && p.primary);
  const messagePlatformEnabled = useIsFeatureFlagEnabled('ui.sendToMessagePlatform') && !isLinkedInPrimary;

  const contactOptions = useMemo(
    () => [
      {
        key: 'email',
        name: 'Email',
        isEnabled: !!contactDetails.email,
        icon: Mail,
      },
      {
        key: 'linkedin',
        name: 'LinkedIn',
        isEnabled: !!contactDetails.linkedin,
        icon: Linkedin,
      },
      // {
      //   key: 'imessage',
      //   name: 'iMessage',
      //   isEnabled: !!contactDetails.phone,
      //   icon: Linkedin,
      // },
    ],
    [contactDetails],
  );

  const defaultPlatform = useMemo(() => {
    if (
      post.messagingPlatform &&
      contactOptions.some((option) => option.name === post.messagingPlatform && option.isEnabled)
    ) {
      return post.messagingPlatform;
    } else if (post.topic_type === TopicType.LINKEDIN_EMAIL && contact.linkedin) {
      return 'LinkedIn';
    } else {
      const firstEnabledOption = contactOptions.find((option) => option.isEnabled);
      return firstEnabledOption ? firstEnabledOption.name : contactOptions[0].name;
    }
  }, [post.messagingPlatform, post.topic_type, contact.email, contact.linkedin]);

  useEffect(() => {
    const isValidPlatform = contactOptions.some((option) => option.name === selectedPlatform && option.isEnabled);
    if (!isValidPlatform) {
      if (!userSelectedPlatform.current) {
        if (selectedPlatform !== defaultPlatform) {
          setSelectedPlatform(defaultPlatform);
        }
      } else {
        const firstEnabledOption = contactOptions.find((option) => option.isEnabled);
        const newSelectedPlatform = firstEnabledOption ? firstEnabledOption.name : contactOptions[0].name;

        if (selectedPlatform !== newSelectedPlatform) {
          setSelectedPlatform(newSelectedPlatform);
        }
      }
    }
  }, [contact.email, contact.linkedin]);

  const [selectedPlatform, setSelectedPlatform] = useState(defaultPlatform);
  const userSelectedPlatform = useRef(false);

  useEffect(() => {
    if (isLinkedInPrimary) {
      setSelectedPlatform('LinkedIn');
      handleSendToMessage?.(post, 'LinkedIn', setPost);
    }
  }, [isLinkedInPrimary, post, setPost, handleSendToMessage]);

  useEffect(() => {
    const isValidPlatform = contactOptions.some((option) => option.name === selectedPlatform && option.isEnabled);
    if (!isValidPlatform) {
      if (!userSelectedPlatform.current) {
        setSelectedPlatform(defaultPlatform);
      } else {
        const firstEnabledOption = contactOptions.find((option) => option.isEnabled);
        setSelectedPlatform(firstEnabledOption ? firstEnabledOption.name : 'Email');
      }
    }
  }, [contactDetails, selectedPlatform, defaultPlatform]);

  const handlePlatformSelect = (optionName) => {
    userSelectedPlatform.current = true;
    setSelectedPlatform(optionName);
    handleSendToMessage(post, optionName, setPost);
  };

  const handleDropdownClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <Stack direction="row" spacing="12px" maxWidth="100%">
      {avatarNode}
      <Stack direction="column" spacing="4px" justifyContent="space-between" width="100%">
        <Stack direction="row" spacing="12px" justifyContent="space-between" width="100%">
          {headerNode}
          <div className="flex items-center">
            {errorNote}
            {messagePlatformEnabled &&
              (post.topic_type === TopicType.LINKEDIN_EMAIL || post.topic_type === TopicType.MEETING) && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm" onClick={handleDropdownClick}>
                      {contactOptions.find((opt) => opt.name === selectedPlatform)?.icon &&
                        React.createElement(contactOptions.find((opt) => opt.name === selectedPlatform).icon, {
                          className: 'h-5 w-5',
                        })}
                      <div className="ml-2">{selectedPlatform}</div>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {contactOptions.map((option) => (
                      <DropdownMenuItem
                        key={option.name}
                        onClick={() => {
                          if (option.isEnabled) {
                            handlePlatformSelect(option.name);
                          }
                        }}
                        disabled={!option.isEnabled}
                      >
                        <div className="flex items-center w-full">
                          {React.createElement(option.icon, {
                            className: 'mr-2 h-5 w-5',
                          })}
                          <div>{option.name}</div>
                        </div>
                        {selectedPlatform === option.name && <Check className="ml-auto h-4 w-4" />}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
          </div>
        </Stack>
        <Box sx={{ maxWidth: 'calc(100% - 43px)' }}>{children}</Box>
      </Stack>
    </Stack>
  );
};

PPostCardCommonContent.propTypes = {
  avatarNode: PropTypes.node,
  headerNode: PropTypes.node,
  post: PropTypes.object.isRequired,
  setPost: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  handleSendToMessage: PropTypes.func.isRequired,
  errorNote: PropTypes.node,
};

export default PPostCardCommonContent;
