import { useState, useEffect } from 'react';

import { Container, Stack, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material';
import Http from '../../http/httpClient';
import ListModal from '../setup/simpleSetupComponents/listModal';

import ListDisplay from './components/listDisplay';
import EditListModal from './components/editListModal';
import { SetCookie, GetCookie } from '../../helper/cookieManager';
import { getDefaultStrategies } from '../../helper/apiHelper';
import PTypography from '@Library/PTypography';
import PButton from '@Library/PButton';

export default function Lists(props) {
  const theme = useTheme();
  const [currPage, setCurrPage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createdListId, setCreatedListId] = useState(null);
  const [existingListId, setExistingListId] = useState(null);
  const [availableList, setAvailableList] = useState([]);
  const [listContacts, setListContacts] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [progress, setProgress] = useState({});
  const [targetAudience, setTargetAudience] = useState({});

  const handleListItemClick = (pageContent) => {
    setCurrPage(pageContent);
  };

  const closeModal = () => setModalOpen(false);
  const editCloseModal = () => setEditModalOpen(false);

  const fetchListData = (listId) => {
    setProgress((prev) => ({ ...prev, getList: true }));
    Http.getData('getLists', { listId })
      .then(function (response) {
        if (response && response.status) {
          setAvailableList(response.lists);
          const selectedItem = listId
            ? response.lists.find((item) => item._id.toString() === listId.toString())
            : response.lists[0];
          setActiveItem(selectedItem);
          setProgress((prev) => ({ ...prev, getList: false }));

          // Update the user cookie
          let userCookie = GetCookie('user');
          if (userCookie && userCookie.providers) {
            const providers = userCookie.providers.filter((p) => p !== null && p.isInitialEnrichDone === false);
            for (const p of providers) {
              const providerToUpdate = response.lists?.some((item) => {
                return item.providers.some(
                  (provider) => provider.enrichmentCompleted && provider.provider === p.provider,
                );
              });

              if (providerToUpdate) {
                const cookiePro = userCookie.providers.find((provider) => provider.provider === p.provider);
                if (cookiePro) {
                  cookiePro.isInitialEnrichDone = true;
                  SetCookie('user', JSON.stringify(userCookie));
                }
              }
            }
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const fetchStrategies = async () => {
    getDefaultStrategies()
      .then((response) => {
        if (response && response.status) {
          setTargetAudience(response.targetAudience);
        }
      })
      .catch((error) => {
        console.error('Error : ', error);
      });
  };

  useEffect(() => {
    fetchListData();
    fetchStrategies();
  }, []);

  useEffect(() => {
    if (!createdListId) {
      return;
    }

    fetchListData(createdListId);
  }, [createdListId]);

  useEffect(() => {
    if (!activeItem) {
      return;
    }

    setProgress((prev) => ({ ...prev, getContacts: true }));
    Http.getData('getListContacts', {
      listId: activeItem._id,
      newList: createdListId && createdListId === activeItem._id,
    })
      .then(function (response) {
        if (response && response.status) {
          setListContacts(response.contacts);
          setProgress((prev) => ({ ...prev, getContacts: false }));
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setCreatedListId(null);
      });
  }, [activeItem]);

  const handleChange = (id) => {
    if (id === null) {
      setActiveItem(null);
      setProgress({});
      setAvailableList([]);
      return;
    }
    const selectedItem = availableList.find((item) => item._id.toString() === id.toString());
    setActiveItem(selectedItem);
  };

  const handleEditedList = (id, editedName) => {
    Http.postData(`updateList/${id}`, { name: editedName })
      .then((res) => {
        if (res.status) {
          const modifiedLists = availableList.map((item) => {
            if (item !== null && item._id === id) {
              item.name = editedName;
            }
            return item;
          });
          setAvailableList([...modifiedLists]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        fetchListData(id);
        editCloseModal();
      });
  };

  const handleDeleteList = (id) => {
    Http.postData(`deleteList/${id}`)
      .then((res) => {
        console.log(res);
        if (res && res.status) {
          const filterdList = availableList.filter((listDetail) => listDetail._id !== id);
          const selectedItem = filterdList.length !== 0 ? filterdList[0]._id : null;
          handleChange(selectedItem);
          setAvailableList(filterdList);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        editCloseModal();
      });
  };

  const handleListModification = () => {
    setActiveItem({ ...activeItem });
  };

  return (
    <>
      {modalOpen && (
        <ListModal
          open={modalOpen}
          onClose={closeModal}
          setCreatedListId={setCreatedListId}
          targetAudience={targetAudience}
        />
      )}
      {editModalOpen && (
        <EditListModal
          open={editModalOpen}
          onClose={editCloseModal}
          editList={activeItem}
          handleEditedList={handleEditedList}
          handleDeleteList={handleDeleteList}
        />
      )}
      <Stack direction="row">
        <div style={{ height: '100vh' }}>
          <Container
            style={{
              minWidth: '325px',
              Width: '325px',
              height: '100%',
              paddingRight: '0px',
              paddingLeft: '0px',
              overflow: 'scroll',
            }}
          >
            <PTypography sx={{ my: 2, ml: 3 }} size="h2" weight="regular">
              Your lists
            </PTypography>
            {progress.getList ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '50px',
                  height: '100%',
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <List sx={{ flexGrow: 1 }}>
                {availableList.map((item) => (
                  <div key={item.name}>
                    <ListItemButton
                      onClick={() => {
                        handleChange(item._id);
                      }}
                      sx={{
                        'color': activeItem._id === item._id ? theme.palette.primary.blue : theme.palette.primary.grey,
                        'backgroundColor':
                          activeItem._id === item._id ? theme.palette.primary.lightGrey : theme.palette.primary.white,
                        'fontWeight': 500,
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        '&:hover': {
                          backgroundColor: theme.palette.primary.lightBlue,
                        },
                        'minHeight': '64px',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemText primary={item.name} sx={{ fontWeight: 500, paddingLeft: '24px' }} />
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {activeItem._id === item._id && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditModalOpen(true);
                            }}
                            sx={{
                              color: theme.palette.primary.blue,
                              textTransform: 'none',
                              fontWeight: 500,
                              fontSize: '14px',
                              py: 1,
                              px: 2,
                              mx: 2,
                              borderRadius: 2,
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </ListItemButton>
                    <Divider />
                  </div>
                ))}
              </List>
            )}

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PButton onClick={() => setModalOpen(true)} pVariant="secondary">
                Add list
              </PButton>
            </div>
          </Container>
        </div>
        <Divider orientation="vertical" flexItem />

        <div style={{ height: '100vh', width: '100%' }}>
          <div
            style={{
              minWidth: '65%',
              height: '100%',
              paddingLeft: '0px',
              paddingRight: '0px',
              overflow: 'scroll',
            }}
          >
            {progress.getContacts ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  alignItems: 'center',
                  marginTop: '50%',
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            ) : (
              progress.getContacts === false && (
                <ListDisplay
                  contacts={listContacts}
                  setCreatedListId={setCreatedListId}
                  listItem={activeItem}
                  handleListModification={handleListModification}
                />
              )
            )}
          </div>
        </div>
      </Stack>
    </>
  );
}
