import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GetCookie, RemoveCookie } from '@/helper/cookieManager';
import { UserConst } from '@/constants/userConst';
import Http from '@/http/httpClient';

export const useCalendlyRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const checkAndRedirect = async () => {
      const user = GetCookie('user');

      if (!user) return;

      // Check if user needs to verify email first
      const isLinkedInPrimary = user.providers?.some(
        (provider) => provider.primary && provider.provider === 'linkedin',
      );

      if (isLinkedInPrimary && !user.identityEmail?.verified) {
        // Don't redirect - let the email verification modal handle the flow
        return;
      }

      if (user.userType === UserConst.UserTypes.SIGNUP_USER || user.userType === UserConst.UserTypes.PRECREATED_USER) {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const yearAndMonth = `${year}-${month}`;
        const calendlyURL = `https://calendly.com/jody-glidden-pqw/15-minute-meeting?month=${yearAndMonth}`;

        try {
          await Http.postData('/auth/logout');
        } catch (err) {
          console.error('Logout error:', err);
        } finally {
          RemoveCookie('user');
          window.location.href = calendlyURL;
        }
      }
    };

    checkAndRedirect();
  }, [location.pathname]);
};
