import React, { useEffect, useRef, useState } from 'react';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import Levenshtein from 'fast-levenshtein';

interface LocationAutocompleteProps {
  onSelect: (location: string) => void;
}

export function LocationAutocomplete({ onSelect }: LocationAutocompleteProps) {
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const autoCompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    // Check if the API is already loaded
    if (window.google?.maps?.places) {
      initializeServices();
      return;
    }

    // If not loaded, wait for it to load
    const checkGoogleMapsLoaded = setInterval(() => {
      if (window.google?.maps?.places) {
        clearInterval(checkGoogleMapsLoaded);
        initializeServices();
      }
    }, 100);

    // Cleanup
    return () => clearInterval(checkGoogleMapsLoaded);
  }, []);

  const initializeServices = () => {
    autoCompleteService.current = new google.maps.places.AutocompleteService();
    const dummyElement = document.createElement('div');
    placesService.current = new google.maps.places.PlacesService(dummyElement);
    setIsApiLoaded(true);
  };

  const handleSearch = async (value: string) => {
    if (!value.trim() || !autoCompleteService.current) return;

    try {
      // Original Google Places API request
      const request: google.maps.places.AutocompletionRequest = {
        input: value,
        types: ['(regions)'],
        componentRestrictions: { country: [] },
      };

      const response = await autoCompleteService.current.getPlacePredictions(request);
      let allPredictions = [...(response?.predictions || [])];

      // Add continent predictions based on Levenshtein distance
      const continents = {
        'north america': { description: 'North America (Region)', place_id: 'continent_na' },
        'south america': { description: 'South America (Region)', place_id: 'continent_sa' },
        'emea': { description: 'EMEA - Europe, Middle East, and Africa (Region)', place_id: 'continent_emea' },
        'asia': { description: 'Asia (Region)', place_id: 'continent_as' },
        'oceania': { description: 'Oceania (Region)', place_id: 'continent_oc' },
      };

      const normalizedInput = value.toLowerCase();

      Object.entries(continents).forEach(([continent, prediction]) => {
        const distance = Levenshtein.get(normalizedInput, continent);
        const similarity = 1 - distance / Math.max(normalizedInput.length, continent.length);

        if (similarity > 0.5 || continent.includes(normalizedInput)) {
          allPredictions.push({
            ...prediction,
            matched_substrings: [],
            structured_formatting: {
              main_text: prediction.description,
              main_text_matched_substrings: [],
              secondary_text: '',
            },
            terms: [{ offset: 0, value: prediction.description }],
            types: ['continent'],
          });
        }
      });

      setPredictions(allPredictions);
    } catch (error) {
      console.error('Error fetching predictions:', error);
      setPredictions([]);
    }
  };

  const handleSelect = (prediction: google.maps.places.AutocompletePrediction) => {
    console.log('prediction', prediction);
    // Handle continent selections
    if (prediction.place_id.startsWith('continent_')) {
      const locationName = prediction.description.split(' (')[0];
      onSelect(locationName);
      setInputValue('');
      setPredictions([]);
      return;
    }

    // Original Google Places handling
    if (!placesService.current) return;
    console.log('placesService.current', placesService.current);
    placesService.current.getDetails(
      {
        placeId: prediction.place_id,
        fields: ['address_components'],
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place?.address_components) {
          const firstComponent = place.address_components.find(
            (component) =>
              component.types.includes('locality') ||
              component.types.includes('administrative_area_level_3') ||
              component.types.includes('administrative_area_level_2'),
          );
          const stateComponent = place.address_components.find((component) =>
            component.types.includes('administrative_area_level_1'),
          );

          const countryComponent = place.address_components.find((component) => component.types.includes('country'));

          const locationParts = [];
          if (firstComponent) locationParts.push(firstComponent.long_name);
          if (stateComponent) {
            if (!firstComponent) locationParts.push(stateComponent.long_name);
            else locationParts.push(stateComponent.short_name);
          }
          if (countryComponent) {
            if (!firstComponent && !stateComponent) locationParts.push(countryComponent.long_name);
            else locationParts.push(countryComponent.short_name);
          }

          const locationName = locationParts.join(', ');
          if (locationName) {
            onSelect(locationName);
            setInputValue('');
            setPredictions([]);
          }
        }
      },
    );
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" className="w-full justify-start text-left font-normal">
          <span className="text-muted-foreground">Enter location (city, state, or country)</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0" style={{ zIndex: 9999 }}>
        <Command>
          <CommandInput
            placeholder="Search locations..."
            value={inputValue}
            onValueChange={(value) => {
              setInputValue(value);
              handleSearch(value);
            }}
          />
          <CommandEmpty>No location found.</CommandEmpty>
          <CommandGroup>
            {predictions.map((prediction) => (
              <CommandItem key={prediction.place_id} onSelect={() => handleSelect(prediction)}>
                {prediction.description}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
