const { IceSheet } = require('../utils/objectUtils');
const { InteractionType } = require('./interactions');

/**
 * @typedef {Object} InteractionFilterOptionLabels
 * @property {Object} LINKEDIN
 * @property {string} LINKEDIN.INCOMING
 * @property {string} LINKEDIN.OUTGOING
 * @property {Object} EMAIL
 * @property {string} EMAIL.INCOMING
 * @property {string} EMAIL.OUTGOING
 * @property {Object} CALENDAR
 * @property {string} CALENDAR.INCOMING
 * @property {string} CALENDAR.OUTGOING
 * @property {string} ADDED_CONTACT
 */

const INTERACTION_FILTER_OPTION_LABELS = new IceSheet({
  LINKEDIN: {
    INCOMING: 'Incoming LinkedIn InMails',
    OUTGOING: 'Outgoing LinkedIn InMails',
  },
  EMAIL: {
    INCOMING: 'Incoming Emails',
    OUTGOING: 'Outgoing Emails',
  },
  CALENDAR: {
    INCOMING: 'Calendar Meetings',
    OUTGOING: 'Calendar Meetings',
  },
  ADDED_CONTACT: 'Added as Contact',
});

/**
 * @enum {string}
 */
const InteractionLabel = {
  LINKEDIN_INCOMING: 'Incoming LinkedIn InMails',
  LINKEDIN_OUTGOING: 'Outgoing LinkedIn InMails',
  EMAIL_INCOMING: 'Incoming Emails',
  EMAIL_OUTGOING: 'Outgoing Emails',
  // CALENDAR_INCOMING: 'Incoming Calendar Meetings',
  // CALENDAR_OUTGOING: 'Outgoing Calendar Meetings',
  CALENDAR_MEETING: 'Calendar Meetings',
  ADDED_CONTACT: 'Added as Contact',
};

const INTERACTION_FILTER_OPTIONS = new IceSheet({
  [InteractionLabel.LINKEDIN_INCOMING]: InteractionType.LINKEDIN_INCOMING,
  [InteractionLabel.LINKEDIN_OUTGOING]: InteractionType.LINKEDIN_OUTGOING,
  [InteractionLabel.EMAIL_INCOMING]: InteractionType.EMAIL_INCOMING,
  [InteractionLabel.EMAIL_OUTGOING]: InteractionType.EMAIL_OUTGOING,
  // The calendar ones have the same values.
  [InteractionLabel.CALENDAR_MEETING]: InteractionType.CALENDAR_MEETING,
  [InteractionLabel.ADDED_CONTACT]: InteractionType.INTERNAL_ADDED_CONTACT,
});

/**
 * @enum {string}
 */
const EngagementType = {
  CHECKIN: 'checkin',
  MEETING: 'meeting',
};

/**
 * @enum {string}
 */
const SortKeys = {
  LAST_INTERACTION: 'lastInteraction',
  NAME: 'name',
  RELATIONSHIP_SCORE: 'relationshipScore',
  STAGE: 'stage',
  COMMUNICATIONS_COUNT: 'commsCount',
};

/**
 * @enum {string}
 */
const VisibilityActions = {
  HIDE: 'hide',
};

const NO_STAGE_DEFINITION = {
  _id: 'no-stage',
  name: 'No stage',
  textColor: 'white',
  backgroundColor: 'black',
};

module.exports = {
  INTERACTION_FILTER_OPTION_LABELS,
  INTERACTION_FILTER_OPTIONS,
  NO_STAGE_DEFINITION,
  EngagementType,
  SortKeys,
  VisibilityActions,
  InteractionLabel,
};
