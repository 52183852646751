import React from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { AssistantItem } from './AssistantItem';
import { Assistant } from '@/types/assistant';

type TypeAccordionProps = {
  type: string;
  assistants: Assistant[];
  onPauseToggle: (assistant: Assistant) => void;
  onDeleteClick: (assistant: Assistant) => void;
  onRename: (id: string, newName: string) => Promise<void>;
  onAssistantClick: (assistantId: string) => void;
  onSettingsClick?: (assistant: Assistant) => void;
  onAddContactsClick?: (assistant: Assistant) => void;
  selectedAssistantId: string | null;
  setSelectedAssistantId: (id: string | null) => void;
  isOpen: boolean;
};

const chevronAnimation = {
  expanded: { rotate: 0, transition: { duration: 0.3 } },
  collapsed: { rotate: 180, transition: { duration: 0.3 } },
};

const contentAnimation = {
  expanded: { opacity: 1, height: 'auto', transition: { duration: 0.3 } },
  collapsed: { opacity: 0, height: 0, transition: { duration: 0.3 } },
};

export const TypeAccordion: React.FC<TypeAccordionProps> = ({
  type,
  assistants,
  onPauseToggle,
  onDeleteClick,
  onRename,
  onAssistantClick,
  onSettingsClick,
  onAddContactsClick,
  selectedAssistantId,
  setSelectedAssistantId,
  isOpen,
}) => {
  return (
    <AccordionPrimitive.Item value={`type-${type}`} className="border-none">
      <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
          className={cn(
            'flex flex-1 items-center justify-start gap-2 rounded-lg px-3 py-2 text-sm text-gray-600 hover:bg-gray-200 bg-gray-100',
          )}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <motion.div animate={isOpen ? 'expanded' : 'collapsed'} variants={chevronAnimation}>
                {isOpen ? <ChevronDown className="h-5 w-5 shrink-0" /> : <ChevronRight className="h-5 w-5 shrink-0" />}
              </motion.div>
              <span className="ml-2">{type}</span>
            </div>
            <div className="flex items-center justify-center rounded-md bg-gray-900 bg-opacity-20 px-2 py-0.5">
              <span className="text-sm text-gray-900">{assistants.length}</span>
            </div>
          </div>
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content forceMount asChild>
        <motion.div
          initial="collapsed"
          animate={isOpen ? 'expanded' : 'collapsed'}
          variants={contentAnimation}
          className="overflow-hidden"
        >
          <div className="pb-1 pt-0 space-y-1 pl-6">
            {assistants.map((assistant) => (
              <AssistantItem
                key={assistant._id}
                assistant={assistant}
                onPauseToggle={onPauseToggle}
                onDeleteClick={onDeleteClick}
                onRename={onRename}
                onAssistantClick={onAssistantClick}
                onSettingsClick={onSettingsClick}
                onAddContactsClick={onAddContactsClick}
                selectedAssistantId={selectedAssistantId}
                setSelectedAssistantId={setSelectedAssistantId}
              />
            ))}
          </div>
        </motion.div>
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
};
