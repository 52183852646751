import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { getCompanyRelationships } from '@/helper/apiHelper';
import { INTERACTION_FILTER_OPTIONS } from '@postilize/shared';

// Constants
const SEARCH_TERM_DEBOUNCE_DELAY_MS = 300;

// Utility Functions
const initializeFilters = (options: readonly string[]) =>
  options.reduce<Record<string, boolean>>((acc, option) => {
    acc[option] = true;
    return acc;
  }, {});

const sanitizeFilterOptions = (filterOptions, availableOptions) => {
  const validFilterOptions = initializeFilters(availableOptions);
  if (typeof filterOptions !== 'object' || filterOptions === null) {
    return validFilterOptions;
  }
  for (const option of availableOptions) {
    if (typeof filterOptions[option] === 'boolean') {
      validFilterOptions[option] = filterOptions[option];
    }
  }
  return validFilterOptions;
};

const sanitizeSortCriteria = (sortCriteria, defaultSortCriteria) => {
  const validatedSortCriteria = { ...defaultSortCriteria };
  if (typeof sortCriteria?.key === 'string') {
    validatedSortCriteria.key = sortCriteria.key;
  }
  if (sortCriteria?.direction === 'asc' || sortCriteria?.direction === 'desc') {
    validatedSortCriteria.direction = sortCriteria.direction;
  }
  return validatedSortCriteria;
};

const sanitizeQueryParams = (queryParams, defaultQueryParams) => {
  const validatedQueryParams = { ...defaultQueryParams };
  if (typeof queryParams === 'object' && queryParams !== null) {
    if (typeof queryParams.filters === 'object' && queryParams.filters !== null) {
      validatedQueryParams.filters = { ...defaultQueryParams.filters };

      if (typeof queryParams.filters.nameSearchTerm === 'string') {
        validatedQueryParams.filters.nameSearchTerm = queryParams.filters.nameSearchTerm;
      }

      validatedQueryParams.filters.lastInteraction = sanitizeFilterOptions(
        queryParams.filters.lastInteraction,
        INTERACTION_FILTER_OPTIONS.flatKeys,
      );

      if (typeof queryParams.filters.showHidden === 'boolean') {
        validatedQueryParams.filters.showHidden = queryParams.filters.showHidden;
      }
      if (typeof queryParams.filters.mineOnly === 'boolean') {
        validatedQueryParams.filters.mineOnly = queryParams.filters.mineOnly;
      }
    }

    if (typeof queryParams.sortCriteria === 'object' && queryParams.sortCriteria !== null) {
      validatedQueryParams.sortCriteria = sanitizeSortCriteria(
        queryParams.sortCriteria,
        defaultQueryParams.sortCriteria,
      );
    }

    if (typeof queryParams.page === 'number') {
      validatedQueryParams.page = queryParams.page;
    }
    if (typeof queryParams.limit === 'number') {
      validatedQueryParams.limit = queryParams.limit;
    }
  }
  return validatedQueryParams;
};

const debouncedFetchContactsOnSearchTermChange = debounce((dispatch) => {
  dispatch(setContactTablePage(1));
  dispatch(setContactTableTotal(0));
  dispatch(invalidateContactTableCacheForAllPages());
  dispatch(fetchContacts());
}, SEARCH_TERM_DEBOUNCE_DELAY_MS);

// Initial State
const defaultContactTableState = {
  data: {
    cacheByPage: {} as Record<number, any[]>,
    total: 0,
    highestRelationshipScore: null as number | null,
  },
  queryParams: {
    page: 1,
    limit: 10,
    filters: {
      nameSearchTerm: '',
      jobTitleSearchTerm: '',
      lastInteraction: initializeFilters(INTERACTION_FILTER_OPTIONS.flatKeys),
      showHidden: false,
      mineOnly: false,
    },
    sortCriteria: { key: 'relationshipScore', direction: 'desc' as 'asc' | 'desc' },
  },
  loading: {
    loadingStateByPage: {
      1: { loading: false, error: null },
    },
    stageManagement: { loading: false, error: null },
  },
};

// Async Thunks
export const fetchContacts = createAsyncThunk(
  'contactTable/fetchContacts',
  async (_, { getState, rejectWithValue }) => {
    const state: any = getState();
    const contactTable = state.contactTable;
    const { page } = contactTable.queryParams;
    const contactCache = contactTable.data.cacheByPage;

    if (contactCache[page]) {
      return { cached: true, page };
    }

    try {
      const queryParams = {
        page: contactTable.queryParams.page,
        limit: contactTable.queryParams.limit,
        sortCriteria: contactTable.queryParams.sortCriteria,
        filters: {
          nameSearchTerm: contactTable.queryParams.filters.nameSearchTerm,
          jobTitleSearchTerm: contactTable.queryParams.filters.jobTitleSearchTerm,
          lastInteraction: Object.entries(contactTable.queryParams.filters.lastInteraction)
            .filter(([_, isActive]) => isActive)
            .map(([key]) => INTERACTION_FILTER_OPTIONS[key])
            .flat(),
          mineOnly: contactTable.queryParams.filters.mineOnly,
        },
      };

      const response = await getCompanyRelationships(queryParams);

      const augmentedContacts = response.relationships.map((relationship: any) => ({
        ...relationship,
        name: relationship?.contacts?.find((c: any) => c.name)?.name,
        position: relationship?.contacts?.find((c: any) => c.title)?.title || '',
      }));

      return {
        contacts: augmentedContacts,
        total: response.total,
        page,
      };
    } catch (error) {
      console.error('Error fetching contacts:', error);
      return rejectWithValue('Failed to load contacts');
    }
  },
);

// Slice
const contactTableSlice = createSlice({
  name: 'contactTable',
  initialState: defaultContactTableState,
  reducers: {
    setContactTableFilterNameSearchTerm: (state, action: PayloadAction<string>) => {
      state.queryParams.filters.nameSearchTerm = action.payload;
    },
    setContactTableFilterStages: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.queryParams.filters.stages = {
        ...state.queryParams.filters.stages,
        ...action.payload,
      };
    },
    setContactTableFilterLastInteraction: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.queryParams.filters.lastInteraction = sanitizeFilterOptions(
        action.payload,
        INTERACTION_FILTER_OPTIONS.flatKeys,
      );
    },
    setContactTableSortCriteria: (state, action: PayloadAction<{ key: string; direction: 'asc' | 'desc' }>) => {
      state.queryParams.sortCriteria = sanitizeSortCriteria(
        action.payload,
        defaultContactTableState.queryParams.sortCriteria,
      );
    },
    setContactTableShowHidden: (state, action: PayloadAction<boolean>) => {
      state.queryParams.filters.showHidden = action.payload;
    },
    setContactTableMineOnly: (state, action: PayloadAction<boolean>) => {
      state.queryParams.filters.mineOnly = action.payload;
    },
    setContactTablePage: (state, action: PayloadAction<number>) => {
      state.queryParams.page = action.payload;
    },
    setContactTableTotal: (state, action: PayloadAction<number>) => {
      state.data.total = action.payload;
    },
    invalidateContactTableCacheForAllPages: (state) => {
      state.data.cacheByPage = {};
    },
    invalidateContactTableCacheForPage: (state, action: PayloadAction<number>) => {
      state.data.cacheByPage[action.payload] = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.pending, (state) => {
      const page = state.queryParams.page;
      state.loading.loadingStateByPage[page] = { loading: true, error: null };
    });
    builder.addCase(fetchContacts.fulfilled, (state, action) => {
      const { page } = action.payload;
      if (action.payload.cached) {
        state.loading.loadingStateByPage[page] = { loading: false, error: null };
        return;
      }
      const { contacts, total } = action.payload;
      state.data.cacheByPage[page] = contacts;
      state.data.total = total;
      state.loading.loadingStateByPage[page] = { loading: false, error: null };
    });
    builder.addCase(fetchContacts.rejected, (state, action) => {
      const page = state.queryParams.page;
      state.loading.loadingStateByPage[page] = {
        loading: false,
        error: action.payload as string,
      };
    });
  },
});

export const {
  setContactTableFilterNameSearchTerm,
  setContactTableFilterStages,
  setContactTableFilterLastInteraction,
  setContactTableSortCriteria,
  setContactTableShowHidden,
  setContactTableMineOnly,
  setContactTablePage,
  setContactTableTotal,
  invalidateContactTableCacheForAllPages,
  invalidateContactTableCacheForPage,
} = contactTableSlice.actions;

export default contactTableSlice.reducer;

// Action Creators
const createContactTableFilterChangeAction =
  (setFilterAction) =>
  (...args) =>
  (dispatch) => {
    dispatch(setFilterAction(...args));
    dispatch(setContactTablePage(1));
    dispatch(setContactTableTotal(0));
    dispatch(invalidateContactTableCacheForAllPages());
    dispatch(fetchContacts());
  };

export const changeContactFilterStages = createContactTableFilterChangeAction(setContactTableFilterStages);
export const changeContactFilterLastInteraction = createContactTableFilterChangeAction(
  setContactTableFilterLastInteraction,
);
export const changeContactSortCriteria = createContactTableFilterChangeAction(setContactTableSortCriteria);
export const changeContactShowHidden = createContactTableFilterChangeAction(setContactTableShowHidden);

export const changeContactPage = (newPage: number) => (dispatch) => {
  dispatch(setContactTablePage(newPage || 1));
  dispatch(fetchContacts());
};

export const changeContactFilterSearchTerm = (term: string) => (dispatch) => {
  dispatch(setContactTableFilterNameSearchTerm(term));
  debouncedFetchContactsOnSearchTermChange(dispatch);
};

export const changeContactMineOnly = (mineOnly: boolean) => (dispatch) => {
  dispatch(setContactTableMineOnly(mineOnly));
  dispatch(setContactTablePage(1));
  dispatch(invalidateContactTableCacheForAllPages());
  dispatch(setContactTableTotal(0));
  dispatch(fetchContacts());
};
