import * as React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

function EmailContentModal({ open, onClose, title, htmlContent }) {
  const theme = useTheme();

  // Convert stringified HTML to plain text if needed
  const getPlainTextContent = (html) => {
    // Reference the existing HTML to plain text conversion utility
    if (typeof html === 'string') {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
    }
    return '';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            p: 2,
            maxHeight: '70vh',
            overflowY: 'auto',
          }}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </DialogContent>
    </Dialog>
  );
}

EmailContentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  htmlContent: PropTypes.string.isRequired,
};

EmailContentModal.defaultProps = {
  title: 'Content Preview',
  htmlContent: '',
};

export default EmailContentModal;
