import React, { useState } from 'react';
import { Modal, TextField, Button, Typography, Box } from '@mui/material';
import httpClient from '@/http/httpClient';

const containerStyle = {
  backgroundColor: '#f0f4f8',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem',
};

const modalBoxStyle = {
  width: '50vw',
  height: '50vh',
  maxWidth: 500,
  maxHeight: 450,
  backgroundColor: 'white',
  borderRadius: 3,
  boxShadow: 4,
  padding: '3rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const buttonStyle = {
  'mt': 1,
  'backgroundColor': '#0073e6',
  'color': 'white',
  '&:hover': {
    backgroundColor: '#005bb5',
  },
};

const textFieldStyle = {
  'mb': 2,
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: '#ddd' },
    '&:hover fieldset': { borderColor: '#aaa' },
    '&.Mui-focused fieldset': { borderColor: '#0073e6' },
  },
};

function EmailVerificationModal({ open, onClose }) {
  const [step, setStep] = useState('email'); // 'email' or 'code'
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const handleEmailSubmit = async () => {
    const response = await httpClient.postData('/auth/verifyEmail', { email });
    if (response.success) {
      setStep('code'); // Move to code verification step
      setError(''); // Clear any error
    } else {
      setError(response.data?.message || 'Failed to send verification code.');
    }
  };

  const handleCodeSubmit = async () => {
    const response = await httpClient.postData('/auth/submitCode', { email, code });
    if (response.success) {
      setError('');
      onClose(); // Close modal on successful verification
      // refresh page
      window.location.reload();
    } else {
      setError(response.data?.message || 'Verification code is incorrect.');
    }
  };

  const handleSubmit = () => {
    if (step === 'email') handleEmailSubmit();
    else handleCodeSubmit();
  };

  return (
    <Modal open={open} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={containerStyle}>
        <Box sx={modalBoxStyle}>
          <Typography variant="h5" mb={2} fontWeight="bold" color="text.primary">
            {step === 'email' ? 'Verify Your Email' : 'Enter Verification Code'}
          </Typography>
          <Typography variant="body1" mb={3} color="text.secondary">
            {step === 'email'
              ? 'Please enter your email address to receive a verification code.'
              : 'Please enter the verification code sent to your email.'}
          </Typography>
          <TextField
            label={step === 'email' ? 'Email Address' : 'Verification Code'}
            value={step === 'email' ? email : code}
            onChange={(e) => (step === 'email' ? setEmail(e.target.value) : setCode(e.target.value))}
            fullWidth
            variant="outlined"
            sx={textFieldStyle}
          />
          {error && (
            <Typography color="error" mb={2} variant="body2">
              {error}
            </Typography>
          )}
          <Button onClick={handleSubmit} variant="contained" fullWidth sx={buttonStyle}>
            {step === 'email' ? 'Send Verification Code' : 'Verify'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default EmailVerificationModal;
