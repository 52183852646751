import { createSlice } from '@reduxjs/toolkit';

const sequenceReviewSlice = createSlice({
  name: 'sequenceReview',
  initialState: {
    targetSequence: null,
  },
  reducers: {
    setTargetSequence: (state, action) => {
      state.targetSequence = action.payload;
    },
  },
});

export const { setTargetSequence } = sequenceReviewSlice.actions;
export default sequenceReviewSlice.reducer;
