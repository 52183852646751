import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getContactInteractions } from '@/helper/apiHelper';

// Initial State
const defaultInteractionState = {
  interactionsCache: {} as Record<string, any[]>,
  modalProps: {
    contactName: null as string | null,
    interactions: [] as any[],
  },
  selectedRelationship: null as any | null,
  ui: {
    isModalOpen: false,
  },
  loading: {} as Record<string, { loading: boolean; error: string | null }>,
};

// Async Thunks
export const fetchContactInteractions = createAsyncThunk(
  'interactions/fetchContactInteractions',
  async ({ contactId }: { contactId: string }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      if (!contactId) throw new Error('No contact ID found');
      if (state.interactions.interactionsCache[contactId]) {
        return {
          interactions: state.interactions.interactionsCache[contactId],
          contactId,
        };
      }

      const response = await getContactInteractions(contactId, 1, 100, true, false);
      return {
        interactions: response?.interactions || [],
        contactId,
      };
    } catch (error) {
      return rejectWithValue({ contactId, error: 'Failed to fetch contact interactions' });
    }
  },
);

// Slice
const interactionSlice = createSlice({
  name: 'interactions',
  initialState: defaultInteractionState,
  reducers: {
    setInteractionsModalOpen: (state, action) => {
      state.ui.isModalOpen = action.payload;
    },
    setInteractionsModalProps: (state, action) => {
      state.modalProps = action.payload;
    },
    clearInteractions: (state) => {
      state.interactionsCache = {};
      state.selectedRelationship = null;
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContactInteractions.pending, (state, action) => {
      const { contactId } = action.meta.arg;
      if (contactId) {
        state.loading[contactId] = { loading: true, error: null };
      }
    });
    builder.addCase(fetchContactInteractions.fulfilled, (state, action) => {
      const { interactions, contactId } = action.payload;
      state.interactionsCache[contactId] = interactions;
      state.loading[contactId] = { loading: false, error: null };
    });
    builder.addCase(fetchContactInteractions.rejected, (state, action) => {
      const { contactId, error } = action.meta.arg;
      if (contactId) {
        state.loading[contactId] = { loading: false, error };
      }
    });
  },
});

export const { setInteractionsModalOpen, clearInteractions, setInteractionsModalProps } = interactionSlice.actions;

export default interactionSlice.reducer;
