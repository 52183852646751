import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import {
  getCompanyClients,
  updateCompanyClientStage,
  setCompanyClientVisibilityForUser,
  getErmStages,
} from '@/helper/apiHelper';
import { NO_STAGE_DEFINITION, INTERACTION_FILTER_OPTIONS } from '@postilize/shared';
import { setCompany, setPipelineStages } from '@/redux/authSlice';
import { setSelectedClient } from '@/redux/slices/ERMDashboard';
import { registerStatePersistence } from '@/redux/middlewares/saveStateMiddleware';

// Constants
const SEARCH_TERM_DEBOUNCE_DELAY_MS = 300;

// Utility Functions
const initializeFilters = (options: readonly string[]) =>
  options.reduce<Record<string, boolean>>((acc, option) => {
    acc[option] = true;
    return acc;
  }, {});

const sanitizeSortCriteria = (sortCriteria, defaultSortCriteria) => {
  const validatedSortCriteria = { ...defaultSortCriteria };
  if (typeof sortCriteria?.key === 'string') {
    validatedSortCriteria.key = sortCriteria.key;
  }
  if (sortCriteria?.direction === 'asc' || sortCriteria?.direction === 'desc') {
    validatedSortCriteria.direction = sortCriteria.direction;
  }
  return validatedSortCriteria;
};

const buildClientQueryParamsForSubmission = (clientTableState) => {
  const { filters } = clientTableState.queryParams;
  const activeStages = Object.keys(filters.stages).filter((key) => filters.stages[key]);
  const activeInteractionLabels = Object.keys(filters.lastInteraction).filter((key) => filters.lastInteraction[key]);

  const interactionTypes = activeInteractionLabels.reduce((acc, label) => {
    const options = INTERACTION_FILTER_OPTIONS[label] || [];
    return acc.concat(options);
  }, []);

  return {
    ...clientTableState.queryParams,
    filters: {
      ...filters,
      stages: activeStages,
      lastInteraction: interactionTypes,
      lastInteractionDateRange: {
        from: filters.lastInteractionDateRange?.from
          ? new Date(filters.lastInteractionDateRange.from).toISOString()
          : null,
        to: filters.lastInteractionDateRange?.to ? new Date(filters.lastInteractionDateRange.to).toISOString() : null,
      },
    },
  };
};

const debouncedFetchClientsOnSearchTermChange = debounce((dispatch) => {
  dispatch(setClientTablePage(1));
  dispatch(setClientTableTotal(0));
  dispatch(invalidateClientTableCacheForAllPages());
  dispatch(fetchClients());
}, SEARCH_TERM_DEBOUNCE_DELAY_MS);

const mergeAndSanitizeStageFilters = (payloadStages, pipelineStages) => {
  const sanitizedStages = { ...payloadStages };
  pipelineStages.forEach((stage) => {
    if (sanitizedStages[stage._id] === undefined || sanitizedStages[stage._id] === null) {
      sanitizedStages[stage._id] = true;
    }
  });
  return sanitizedStages;
};

// Initial State
const defaultClientTableState = {
  data: {
    cacheByPage: {} as Record<number, any[]>,
    total: 0,
    highestRelationshipScore: null as number | null,
  },
  queryParams: {
    page: 1,
    limit: 10,
    filters: {
      searchTerm: '',
      stages: {} as Record<string, boolean>,
      lastInteraction: initializeFilters(INTERACTION_FILTER_OPTIONS.flatKeys),
      lastInteractionDateRange: {
        from: null as Date | null,
        to: null as Date | null,
      },
      showHidden: false,
    },
    sortCriteria: { key: 'relationshipScore', direction: 'desc' as 'asc' | 'desc' },
  },
  loading: {
    loadingStateByPage: {
      1: { loading: false, error: null },
    },
    stageManagement: { loading: false, error: null },
  },
  ui: {
    editingStageClientId: null as string | null,
    isClientSettingStage: {} as Record<string, boolean>,
  },
};

// Async Thunks
export const fetchClients = createAsyncThunk('clientTable/fetchClients', async (_, { getState, rejectWithValue }) => {
  const state: any = getState();
  const clientTable = state.clientTable;
  const pipelineStages = state.auth.company?.company?.ermPreference?.pipelineStages || [];
  const { page } = clientTable.queryParams;
  const clientCache = clientTable.data.cacheByPage;

  if (clientCache[page]) {
    return { cached: true, page };
  }

  try {
    const queryParamsForSubmission = buildClientQueryParamsForSubmission(clientTable, pipelineStages);
    const data = await getCompanyClients(queryParamsForSubmission);
    if (!data?.clients) return rejectWithValue('No clients found');

    const augmentedClients = data.clients.map((client: any) => {
      const stage =
        client?.stage &&
        state.auth.company.company.ermPreference.pipelineStages.some((s: any) => s._id === client.stage)
          ? client.stage
          : NO_STAGE_DEFINITION._id;

      return {
        ...client,
        name: client?.name || 'Unnamed Client',
        stage,
      };
    });

    return {
      augmentedClients,
      total: data.total,
      page,
      oldestInteractionDate: data.oldestInteractionDate,
    };
  } catch (error) {
    console.error('Error fetching clients', error);
    return rejectWithValue('Failed to load clients');
  }
});

export const updateClientStage = createAsyncThunk(
  'clientTable/updateClientStage',
  async ({ clientId, newStageId }: { clientId: string; newStageId: string }, { rejectWithValue }) => {
    try {
      const result = await updateCompanyClientStage(clientId, newStageId);
      if (!result?.success) throw new Error(result?.message || 'Failed to update stage');
      return { clientId, newStageId };
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update client stage');
    }
  },
);

export const toggleClientVisibility = createAsyncThunk(
  'clientTable/toggleClientVisibility',
  async ({ clientId, action }: { clientId: string; action: 'hide' | 'show' }, { rejectWithValue }) => {
    try {
      const result = await setCompanyClientVisibilityForUser(clientId, action);
      if (!result?.success) throw new Error(result?.message || 'Failed to toggle visibility');
      return { clientId, action };
    } catch (error) {
      return rejectWithValue(error.message || `Failed to ${action} client`);
    }
  },
);

export const fetchPipelineStages = createAsyncThunk<void, void, { rejectValue: string }>(
  'clientTable/fetchPipelineStages',
  async (_, { rejectWithValue }) => {
    try {
      const stages = await getErmStages();
      return stages;
    } catch (error) {
      return rejectWithValue('Failed to fetch pipeline stages.');
    }
  },
);

export const updateFilterStages = createAsyncThunk(
  'clientTable/updateFilterStages',
  async (stages: Record<string, boolean>, { getState, dispatch }) => {
    const state: any = getState();
    const pipelineStages = state.auth.company?.company?.ermPreference?.pipelineStages || [];

    const updatedStages = Object.keys(stages).filter((stageId) => pipelineStages.some((s) => s._id === stageId));

    dispatch(
      setClientTableFilterStages(
        updatedStages.reduce((acc, stageId) => {
          acc[stageId] = stages[stageId] === undefined ? true : stages[stageId];
          return acc;
        }, {}),
      ),
    );

    // Perform additional actions
    dispatch(setClientTablePage(1));
    dispatch(invalidateClientTableCacheForAllPages());
    dispatch(setSelectedClient(null));
    dispatch(fetchClients());
  },
);

// Slice
const clientTableSlice = createSlice({
  name: 'clientTable',
  initialState: defaultClientTableState,
  reducers: {
    setClientTableFilterNameSearchTerm: (state, action: PayloadAction<string>) => {
      state.queryParams.filters.searchTerm = action.payload;
    },
    setClientTableFilterStages: (state, action: PayloadAction<Record<string, boolean>>) => {
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            lastInteractionDate: null as Date | null,
            stages: mergeAndSanitizeStageFilters(
              action.payload,
              state.auth?.company?.company?.ermPreference?.pipelineStages || [],
            ),
          },
        },
      };
    },
    setClientTableFilterLastInteraction: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.queryParams.filters.lastInteraction = action.payload;
    },
    setClientTableSortCriteria: (state, action: PayloadAction<{ key: string; direction: 'asc' | 'desc' }>) => {
      state.queryParams.sortCriteria = sanitizeSortCriteria(
        action.payload,
        defaultClientTableState.queryParams.sortCriteria,
      );
    },
    setClientTableShowHidden: (state, action: PayloadAction<boolean>) => {
      state.queryParams.filters.showHidden = action.payload;
    },
    setClientTablePage: (state, action: PayloadAction<number>) => {
      state.queryParams.page = action.payload;
    },
    invalidateClientTableCacheForAllPages: (state) => {
      state.data.cacheByPage = {};
    },
    invalidateClientTableCacheForPage: (state, action: PayloadAction<number>) => {
      state.data.cacheByPage[action.payload] = null;
    },
    setClientTableEditingStageClientId: (state, action: PayloadAction<string | null>) => {
      state.ui.editingStageClientId = action.payload;
    },
    setClientTableLoadingStage: (state, action: PayloadAction<{ clientId: string; isLoading: boolean }>) => {
      const { clientId, isLoading } = action.payload;
      state.ui.isClientSettingStage[clientId] = isLoading;
    },
    setClientTableTotal: (state, action: PayloadAction<number>) => {
      state.data.total = action.payload;
    },
    setClientTableFilterLastInteractionDateRange: (
      state,
      action: PayloadAction<{ from: Date | null; to: Date | null }>,
    ) => {
      state.queryParams.filters.lastInteractionDateRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state) => {
      const page = state.queryParams.page;
      state.loading.loadingStateByPage[page] = { loading: true, error: null };
    });
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      const { page } = action.payload;
      if (action.payload.cached) {
        state.loading.loadingStateByPage[page] = { loading: false, error: null };
        return;
      }
      const { augmentedClients, total, oldestInteractionDate } = action.payload;
      state.data.cacheByPage[page] = augmentedClients;
      state.data.total = total;
      state.data.oldestInteractionDate = oldestInteractionDate;
      state.loading.loadingStateByPage[page] = { loading: false, error: null };
    });
    builder.addCase(fetchClients.rejected, (state, action) => {
      const page = state.queryParams.page;
      state.loading.loadingStateByPage[page] = {
        loading: false,
        error: action.payload as string,
      };
    });
    builder.addCase(updateClientStage.pending, (state, action) => {
      const { clientId } = action.meta.arg;
      state.ui.editingStageClientId = null;
      state.ui.isClientSettingStage[clientId] = true;
    });
    builder.addCase(updateClientStage.fulfilled, (state, action) => {
      const { clientId, newStageId } = action.payload;
      Object.keys(state.data.cacheByPage).forEach((pageKey) => {
        const page = state.data.cacheByPage[pageKey];
        if (page) {
          const clientIndex = page.findIndex((client) => client._id === clientId);
          if (clientIndex !== -1) {
            page[clientIndex].stage = newStageId;
          }
        }
      });

      state.ui.isClientSettingStage[clientId] = false;
      state.ui.editingStageClientId = null;
    });
    builder.addCase(updateClientStage.rejected, (state, action) => {
      const { clientId } = action.meta.arg;
      state.ui.isClientSettingStage[clientId] = false;
    });
    builder.addCase(toggleClientVisibility.fulfilled, (state) => {
      const currentPage = state.queryParams.page;
      if (state.data.cacheByPage[currentPage]?.length === 1) {
        state.queryParams.page = Math.max(currentPage - 1, 1);
      } else {
        state.data.cacheByPage[currentPage] = null;
      }
    });
    // builder.addCase(fetchPipelineStages.pending, (state) => {
    // });
    builder.addCase(fetchPipelineStages.fulfilled, (state, action) => {
      setPipelineStages(action.payload);
    });
    builder.addCase(fetchPipelineStages.rejected, (state, action) => {
      console.error('Failed to fetch pipeline stages:', action.payload);
    });
    builder.addCase(setCompany, (state, action) => {
      const pipelineStages = action.payload.company.ermPreference.pipelineStages;
      const existingStages = state.queryParams.filters.stages;

      // Only update stages that don't already have a value in state
      const updatedStages = [NO_STAGE_DEFINITION, ...pipelineStages].reduce((acc, stage) => {
        // If stage exists in state and has a boolean value, keep it
        if (typeof existingStages[stage.name] === 'boolean') {
          acc[stage.name] = existingStages[stage.name];
        } else {
          // Otherwise set to default true
          acc[stage.name] = true;
        }
        return acc;
      }, {});

      state.queryParams.filters.stages = { ...existingStages, ...updatedStages };
    });
  },
});

export const {
  setClientTableFilterNameSearchTerm,
  setClientTableFilterStages,
  setClientTableFilterLastInteraction,
  setClientTableSortCriteria,
  setClientTableShowHidden,
  setClientTablePage,
  setClientTableTotal,
  invalidateClientTableCacheForAllPages,
  invalidateClientTableCacheForPage,
  setClientTableEditingStageClientId,
  setClientTableLoadingStage,
  setClientTableFilterLastInteractionDateRange,
} = clientTableSlice.actions;

export default clientTableSlice.reducer;

// Action Creators
const createClientTableFilterChangeAction =
  (setFilterAction) =>
  (...args) =>
  (dispatch) => {
    dispatch(setFilterAction(...args));
    dispatch(setClientTablePage(1));
    dispatch(invalidateClientTableCacheForAllPages());
    dispatch(setSelectedClient(null));
    dispatch(fetchClients());
  };

export const changeClientFilterStages = createClientTableFilterChangeAction(setClientTableFilterStages);
export const changeClientFilterLastInteraction = createClientTableFilterChangeAction(
  setClientTableFilterLastInteraction,
);
export const changeClientSortCriteria = createClientTableFilterChangeAction(setClientTableSortCriteria);
export const changeClientShowHidden = createClientTableFilterChangeAction(setClientTableShowHidden);

export const changeClientPage = (newPage: number) => (dispatch) => {
  dispatch(setClientTablePage(newPage || 1));
  dispatch(fetchClients());
};

export const changeClientFilterSearchTerm = (term: string) => (dispatch) => {
  dispatch(setClientTableFilterNameSearchTerm(term));
  debouncedFetchClientsOnSearchTermChange(dispatch);
};

export const changeClientVisibility = (clientId: string, action: 'hide' | 'show') => async (dispatch) => {
  const result = await dispatch(toggleClientVisibility({ clientId, action }));
  if (toggleClientVisibility.fulfilled.match(result)) {
    dispatch(fetchClients());
  }
};

export const changeClientFilterLastInteractionDateRange =
  (dateRange: { from: Date | null; to: Date | null }) => (dispatch) => {
    dispatch(setClientTableFilterLastInteractionDateRange(dateRange));
    dispatch(setClientTablePage(1));
    dispatch(invalidateClientTableCacheForAllPages());
    dispatch(setSelectedClient(null));
    dispatch(fetchClients());
  };

registerStatePersistence({
  slice: 'clientTable',
  keys: ['queryParams.filters.stages', 'queryParams.filters.lastInteraction'],
  actions: [setClientTableFilterStages.type, setClientTableFilterLastInteraction.type],
  defaultState: defaultClientTableState,
});
