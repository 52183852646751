import { useState } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme, Snackbar, Alert } from '@mui/material';

import { handleAuthentication } from '@/helper/authUtility';
import { SetCookie } from '@/helper/cookieManager';
import { LoginLayout } from './loginLayout';
import PToolTip from '@Library/PToolTip';

import GoogleIcon from '@/assets/logos/Google-Icon.png';
import MicrosoftIcon from '@/assets/logos/MicrosoftLogo.png';
import LinkedInIcon from '@/assets/logos/LinkedInIcon.png';

const isFromChromeExtension = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('from') === 'extension';
};

const doAfterChromeExtensionLogin = (luser) => {
  SetCookie('chromeExtEmail', luser.email);
  window.close();
};

export default function Login({ showLinkedInOnly = false }) {
  const theme = useTheme();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const buttonStyle = {
    'mt': 3,
    'py': 2,
    'backgroundColor': theme.palette.primary.white,
    'color': 'rgba(0, 0, 0, 0.54)',
    'fontSize': '14px',
    'fontWeight': 500,
    'textTransform': 'none',
    'borderRadius': 1,
    'width': '100%',
    'justifyContent': 'flex-start',
    'paddingLeft': '16px',
    'boxShadow': '0px 1px 1px 0px rgba(0, 0, 0, 0.168)',
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
    '&:active': {
      backgroundColor: '#EEEEEE',
    },
  };

  const handleAuth = (provider) => () => {
    if (!termsAccepted) {
      setShowSnackbar(true);
      return;
    }
    const isFromChromeExt = isFromChromeExtension();
    handleAuthentication(`/api/auth/${provider}`, isFromChromeExt ? doAfterChromeExtensionLogin : null);
  };

  const renderLoginButton = (provider, icon, text) => {
    const button = (
      <Button
        variant="contained"
        component={Link}
        onClick={handleAuth(provider)}
        disabled={!termsAccepted}
        sx={buttonStyle}
      >
        <img src={icon} width="18px" style={{ marginRight: '16px' }} />
        {text}
      </Button>
    );

    return !termsAccepted ? (
      <PToolTip
        title="You need to accept the Terms of Service and Postilize Privacy Policy"
        pVariant="black"
        placement="bottom"
      >
        <span>{button}</span>
      </PToolTip>
    ) : (
      button
    );
  };

  const renderExpandButton = () => (
    <Button
      onClick={() => setShowMore(!showMore)}
      endIcon={
        <KeyboardArrowDownIcon
          sx={{
            transform: showMore ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'transform 0.3s',
          }}
        />
      }
      sx={{
        ...buttonStyle,
        'color': theme.palette.primary.grey,
        'backgroundColor': 'transparent',
        'boxShadow': 'none',
        'justifyContent': 'center',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      {showMore ? 'Show less options' : 'More ways to sign in'}
    </Button>
  );

  const loginProviders = {
    primary: showLinkedInOnly
      ? { provider: 'linkedin', icon: LinkedInIcon, text: 'Sign in with LinkedIn' }
      : [
          { provider: 'microsoft', icon: MicrosoftIcon, text: 'Sign in with Microsoft' },
          { provider: 'google', icon: GoogleIcon, text: 'Sign in with Google' },
        ],
    secondary: showLinkedInOnly
      ? [
          { provider: 'microsoft', icon: MicrosoftIcon, text: 'Sign in with Microsoft' },
          { provider: 'google', icon: GoogleIcon, text: 'Sign in with Google' },
        ]
      : { provider: 'linkedin', icon: LinkedInIcon, text: 'Sign in with LinkedIn' },
  };

  return (
    <LoginLayout termsAccepted={termsAccepted} handleCheckboxChange={setTermsAccepted}>
      {showLinkedInOnly ? (
        <>
          {renderLoginButton(loginProviders.primary.provider, loginProviders.primary.icon, loginProviders.primary.text)}
          {renderExpandButton()}
          {showMore &&
            loginProviders.secondary.map(({ provider, icon, text }) => renderLoginButton(provider, icon, text))}
        </>
      ) : (
        <>
          {loginProviders.primary.map(({ provider, icon, text }) => renderLoginButton(provider, icon, text))}
          {renderExpandButton()}
          {showMore &&
            renderLoginButton(
              loginProviders.secondary.provider,
              loginProviders.secondary.icon,
              loginProviders.secondary.text,
            )}
        </>
      )}

      <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={() => setShowSnackbar(false)}>
        <Alert onClose={() => setShowSnackbar(false)} severity="warning" sx={{ width: '100%' }}>
          Please accept the Terms of Service and Postilize Privacy Policy before signing in.
        </Alert>
      </Snackbar>
    </LoginLayout>
  );
}
