import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import IdealCustomerProfile from '@/pages/settings/components/IdealCustomerProfile';
import { getIdealCustomerProfileList } from '@/helper/apiHelper';

interface IdealCustomerProfileModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (newProfileId: string) => void;
}

const IdealCustomerProfileModal: FC<IdealCustomerProfileModalProps> = ({ open, onClose, onSave }) => {
  const handleProfileSave = (newProfileId: string) => {
    onSave(newProfileId);
    onClose();
  };

  const handleClose = () => {
    // Trigger a refresh of the ICP list by calling getIdealCustomerProfileList
    getIdealCustomerProfileList()
      .then((fetchedProfiles) => {
        // The list will be refreshed in the parent component through existing state management
      })
      .catch((error) => {
        console.error('Error refreshing ICP list:', error);
      });
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Customer Profile</DialogTitle>
      <DialogContent>
        <IdealCustomerProfile onSave={handleProfileSave} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdealCustomerProfileModal;
