import React from 'react';

import { Container, Stack, useTheme } from '@mui/material';

import Divider from '@mui/material/Divider';

import Http from '../../http/httpClient';

import Members from './components/members';
import Credits from './components/credits';
import InviteModal from './components/invite';

import { GetCookie } from '../../helper/cookieManager';
import Invites from './components/invites';

import PSectionNav from '../../components/library/PSectionNav';

const currentUser = GetCookie('user');

const Teams = () => {
  const theme = useTheme();
  const [inviteOpen, setInviteOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState('Members');
  const [currPage, setCurrPage] = React.useState(<Members currentUser={currentUser} />);

  const [company, setCompany] = React.useState(null);

  React.useEffect(() => {
    async function getCompany() {
      const response = await Http.getData('company/get');
      if (response) setCompany({ ...response.company, users: response.users });
    }
    getCompany();
  }, []);

  const handleListItemClick = (label) => {
    setActiveItem(label);
  };

  const teamsOptions = [
    { label: 'Members', page: <Members currentUser={currentUser} />, onClick: () => handleListItemClick('Members') },
    { label: 'Credits', page: <Credits currentUser={currentUser} />, onClick: () => handleListItemClick('Credits') },
  ];

  const getPageContent = () => {
    const activeOption = teamsOptions.find((option) => option.label === activeItem);
    return activeOption ? activeOption.page : null;
  };

  const closeInvite = () => {
    setInviteOpen(false);
  };

  return (
    <Stack direction="row" sx={{ padding: 0 }}>
      <PSectionNav
        pVariant={'primary'}
        menu={teamsOptions.map((option) => ({
          ...option,
          active: option.label === activeItem,
        }))}
        width="417px"
      />

      <Divider orientation="vertical" flexItem sx={{ height: '100vh' }} />

      <div
        style={{
          height: '100vh',
          width: '100%',
          margin: 0,
        }}
      >
        {getPageContent()}
      </div>
    </Stack>
    // {/* <InviteModal open={inviteOpen} onClose={closeInvite} /> */}
  );
};

export default Teams;
