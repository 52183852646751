import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import TopicSelection from '@CommonScreens/TopicSelection';
import { updateTopics } from '@/helper/apiHelper';
import { setUserTopics } from '@/redux/authSlice';
import { Button } from '@/components/ui/button';

// Function to extract topic names from persistentTopics
const extractTopicNames = (topics: any[]) =>
  topics.map((topic: any) => (typeof topic === 'string' ? topic : topic.name));

export default function SocialMediaAssistantView() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const assistant = useSelector((state: any) => state.assistance.socialMediaAssistant);
  const persistentTopics = useSelector((state: any) => state.auth.user.topics);

  const [topics, setTopics] = useState(extractTopicNames(persistentTopics));

  useEffect(() => {
    setTopics(extractTopicNames(persistentTopics));
  }, [persistentTopics]);

  const handleTopicsChange = useCallback((newTopics: string[]) => {
    setTopics(newTopics);
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateTopics(topics);
      dispatch(setUserTopics(topics));
    } catch (error) {
      console.error('Error updating topics:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Button onClick={handleSave} disabled={loading || topics.length === 0} className="absolute top-4 right-4">
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
      </Button>
      <Box sx={{ p: 3 }}>
        <TopicSelection autosave={false} initialSelectedTopics={topics} selectedTopicsSetter={handleTopicsChange} />
      </Box>
    </Box>
  );
}
