import * as React from 'react';
import { useTheme } from '@mui/material';
import PPostCard from '../../../components/library/PPostCard';
import PTypography from '../../../components/library/PTypography';
import { InteractionsModal } from '@/v0/ERMDashboard/components/InteractionsModal';
import { useSelector, useDispatch } from 'react-redux';
import { setInteractionsModalOpen } from '@/redux/slices/ERMDashboard/interactionSlice';

export default function ApprovalItems({ posts, handlePost, handleSendToMessage, updatePostCallback }) {
  const theme = useTheme();
  const [newPosts, setNewPosts] = React.useState(posts);
  const interactionsModalOpen = useSelector((state) => state.interactions.ui.isModalOpen);
  const modalProps = useSelector((state) => state.interactions.modalProps);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setNewPosts(posts);
  }, [posts]);

  return (
    <>
      {newPosts?.length > 0 ? (
        <>
          {newPosts.map((post) => (
            <div
              key={post._id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                paddingTop: '28px',
                paddingLeft: '14px',
                paddingRight: '14px',
              }}
            >
              <PPostCard
                theme={theme}
                post={post}
                onClick={handlePost}
                handleSendToMessage={handleSendToMessage}
                updatePostCallback={updatePostCallback}
              ></PPostCard>
            </div>
          ))}
        </>
      ) : (
        <>
          <PTypography
            size="footnote"
            weight="regular"
            children="No posts available for approval."
            sx={{ p: 2 }}
          ></PTypography>
        </>
      )}
      {interactionsModalOpen && (
        <InteractionsModal
          isOpen={interactionsModalOpen}
          onClose={() => dispatch(setInteractionsModalOpen(false))}
          contactName={modalProps.contactName}
          interactions={modalProps.interactions}
          notesFeatureEnabled={false}
          isLoading={false}
        />
      )}
    </>
  );
}
