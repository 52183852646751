import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { getSavedTopics, updateTopics } from '@/helper/apiHelper';

import PModal from '@Library/PModal';
import PButton from '@Library/PButton';
import PBackButton from '@Library/PBackButton';

import TopicSelection from '@CommonScreens/TopicSelection';
import BrandingAssistantCreated from './brandingAssistantCreated';
import { setUserTopics } from '@/redux/authSlice';
import { useDispatch } from 'react-redux';

const sendUpdatedTopics = async (topics, dispatch) => {
  await updateTopics(topics);
  dispatch(setUserTopics(topics));
  // TODO: Handle error response and retry update.
  //       Display a message to the user that we are having issues.
};

function BrandingFlow(props) {
  const { onClose } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [currentContent, setCurrentContent] = useState('audienceAndTopicSelection');
  const [topics, setTopics] = useState([]);
  const [pageHistory, setPageHistory] = useState([]);
  const dispatch = useDispatch();

  const handleSetTopics = useCallback((newTopics) => {
    setTopics(newTopics);
  }, []);

  const fetchSavedTopics = useCallback(async () => {
    setIsLoading(true);
    const savedTopics = await getSavedTopics();

    // Either there was an error, or the user has no saved topics.
    // In either case, we will generate topics based on the user's input.
    // TODO: Handle error response to prevent overwriting the user's saved topics.
    if (savedTopics === null || savedTopics?.length === 0) {
      setIsLoading(false);
      return;
    }

    handleSetTopics(savedTopics);
    setIsLoading(false);
  }, [handleSetTopics]);

  useEffect(() => {
    // Fetch topics from the API upon first render.
    // If the user has no saved topics, we will present
    // the "Tell us about your target audience" screen
    // and generate topics based on the user's input
    fetchSavedTopics();
  }, [fetchSavedTopics]);

  const nextPage = useCallback(
    (page) => {
      setPageHistory([...pageHistory, currentContent]);
      setCurrentContent(page);
    },
    [pageHistory, currentContent],
  );

  const handleContinue = useCallback(async () => {
    switch (currentContent) {
      case 'audienceAndTopicSelection':
        // No topics selected; do not proceed
        if (!topics?.length) {
          // TODO: warning message
          return;
        }

        await sendUpdatedTopics(topics, dispatch);
        nextPage('brandingAssistantCreated');
        break;

      default:
        break;
    }
  }, [currentContent, dispatch, nextPage, topics]);

  const handleBack = useCallback(
    (event) => {
      if (pageHistory.length === 0) {
        // Should never happen, but just in case
        onClose({ fullClose: false });
        return;
      }

      // TODO: Add guards to prevent going back with unsaved work
      const previousContent = pageHistory[pageHistory.length - 1];
      const updatedPageHistory = pageHistory.slice(0, pageHistory.length - 1);

      setPageHistory(updatedPageHistory);
      setCurrentContent(previousContent);
    },
    [onClose, pageHistory],
  );

  const renderContent = () => {
    switch (currentContent) {
      case 'audienceAndTopicSelection':
        return (
          <TopicSelection autosave={false} initialSelectedTopics={topics} selectedTopicsSetter={handleSetTopics} />
        );
      default:
        return null;
    }
  };

  const actionButtons = useMemo(() => {
    const buttons = [];

    const isContinueButtonDisabled =
      isLoading || (currentContent === 'audienceAndTopicSelection' && (topics.length === 0 || topics.length > 10));

    buttons.push(
      <PButton onClick={handleContinue} pVariant="primary" disabled={isContinueButtonDisabled}>
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : currentContent === 'audienceAndTopicSelection' ? (
          'Finish'
        ) : (
          'Continue'
        )}
      </PButton>,
    );

    return buttons;
  }, [isLoading, currentContent, topics.length, handleContinue]);

  return currentContent === 'brandingAssistantCreated' ? (
    <BrandingAssistantCreated onClose={onClose} />
  ) : (
    <PModal
      open={currentContent !== 'brandingAssistantCreated'}
      onClose={handleBack}
      loadingNext={isLoading}
      backButton={<PBackButton onClick={handleBack} iconType="back" />}
      actionButtons={actionButtons}
    >
      {renderContent()}
    </PModal>
  );
}

export default memo(BrandingFlow);
