import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getContactInteractions } from '@/helper/apiHelper';
import { setRelationshipTablePage, clearQueryParamsResetAndFetch } from './relationshipSlice';

// Initial State
const defaultDashboardState = {
  selectedClient: null as any | null,
  selectedTab: 'clientList' as string, // Default tab
  interactions: {
    data: {
      contactInteractions: [] as any[],
      selectedRelationship: null as any | null,
    },
    ui: {
      isModalOpen: false,
    },
    loading: {
      loading: false,
      error: null as string | null,
    },
  },
};

// Async Thunks
export const fetchContactInteractions = createAsyncThunk(
  'dashboard/fetchContactInteractions',
  async (relationship: any, { rejectWithValue }) => {
    try {
      const contactId = relationship?.contacts?.[0]?._id;
      if (!contactId) throw new Error('No contact ID found');

      const response = await getContactInteractions(contactId, 1, 100, true, false);
      return {
        interactions: response?.interactions || [],
        relationship,
      };
    } catch (error) {
      return rejectWithValue('Failed to fetch contact interactions');
    }
  },
);

export const fetchHighestRelationshipScores = createAsyncThunk(
  'ermDashboard/fetchHighestRelationshipScores',
  async () => {
    const companyScore = await getHighestCompanyClientRelationshipScore();
    const contactScore = await getHighestCompanyClientContactRelationshipScore();
    return { companyScore, contactScore };
  },
);

// Slice
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: defaultDashboardState,
  reducers: {
    setSelectedClient: (state, action: PayloadAction<any>) => {
      state.selectedClient = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
    setInteractionsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.interactions.ui.isModalOpen = action.payload;
    },
    clearInteractions: (state) => {
      state.interactions.data.contactInteractions = [];
      state.interactions.data.selectedRelationship = null;
    },
    changeContactInteractions: (state, action: PayloadAction<{ relationship: any }>) => {
      const { relationship } = action.payload;
      state.interactions.data.contactInteractions = relationship?.allInteractions || [];
      state.interactions.data.selectedRelationship = relationship;
      state.interactions.loading.loading = false;
      state.interactions.loading.error = null;
      state.interactions.ui.isModalOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContactInteractions.pending, (state) => {
      state.interactions.loading.loading = true;
      state.interactions.loading.error = null;
    });
    builder.addCase(fetchContactInteractions.fulfilled, (state, action) => {
      const { interactions, relationship } = action.payload;
      state.interactions.data.contactInteractions = interactions;
      state.interactions.data.selectedRelationship = relationship;
      state.interactions.loading.loading = false;
      state.interactions.loading.error = null;
      state.interactions.ui.isModalOpen = true;
    });
    builder.addCase(fetchContactInteractions.rejected, (state, action) => {
      state.interactions.loading.loading = false;
      state.interactions.loading.error = action.payload as string;
    });
  },
});

export const {
  setSelectedClient,
  setSelectedTab,
  setInteractionsModalOpen,
  clearInteractions,
  changeContactInteractions,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

// Action Creators
export const closeInteractionsModal = () => (dispatch) => {
  dispatch(setInteractionsModalOpen(false));
  dispatch(clearInteractions());
};

export const selectClientAndFetchRelationships = (client: any) => (dispatch) => {
  if (client?._id) {
    dispatch(setSelectedClient(client));
    dispatch(setRelationshipTablePage(1));
    dispatch(clearQueryParamsResetAndFetch(client._id));
  }
};

export const changeSelectedErmDashboardTab = (tab: string) => (dispatch) => {
  dispatch(setSelectedTab(tab));
};
