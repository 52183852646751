// This is where we can put generic stuff that can't really be scoped to a specific context

/**
 * @enum {string}
 */
const SortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

module.exports = { SortDirection };
