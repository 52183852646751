// These constants are used in system code to define interaction details

/**
 * @enum {string}
 */
const InteractionPlatform = {
  CALENDAR: 'calendar',
  EMAIL: 'email',
  LINKEDIN: 'linkedin',
  NONE: 'none',
  SOCIAL: 'social',
  SMS: 'sms',
};

/**
 * @enum {string}
 */
const InteractionTrafficDirection = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
  INTERNAL: 'internal',
};

/**
 * @enum {string}
 */
const InteractionType = {
  INTERNAL_ADDED_CONTACT: 'added_contact', // unique case for adding contacts to sequences
  CALENDAR_MEETING: 'calendar_meeting',
  CALENDAR_INCOMING: `${InteractionPlatform.CALENDAR}_${InteractionTrafficDirection.INCOMING}`,
  CALENDAR_OUTGOING: `${InteractionPlatform.CALENDAR}_${InteractionTrafficDirection.OUTGOING}`,
  EMAIL_INCOMING: `${InteractionPlatform.EMAIL}_${InteractionTrafficDirection.INCOMING}`,
  EMAIL_OUTGOING: `${InteractionPlatform.EMAIL}_${InteractionTrafficDirection.OUTGOING}`,
  LINKEDIN_INCOMING: `${InteractionPlatform.LINKEDIN}_${InteractionTrafficDirection.INCOMING}`,
  LINKEDIN_OUTGOING: `${InteractionPlatform.LINKEDIN}_${InteractionTrafficDirection.OUTGOING}`,
};

module.exports = { InteractionType, InteractionPlatform, InteractionTrafficDirection };
