import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { getIdealCustomerProfileList } from '@/helper/apiHelper';

interface ICP {
  _id: string;
  name: string;
}

interface ICPSelectProps {
  onAddICP: () => void;
  addICPModalOpen: boolean;
  setAddICPModalOpen: (open: boolean) => void;
  icpFetchTrigger: boolean;
  onSelectICP: (selectedICP: string) => void;
  step: any;
}

const ICPSelect: React.FC<ICPSelectProps> = ({
  onAddICP,
  addICPModalOpen,
  setAddICPModalOpen,
  icpFetchTrigger,
  onSelectICP,
  step,
}) => {
  const [icps, setIcps] = useState<ICP[]>([]);
  const [selectedICP, setSelectedICP] = useState<string | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchICPs = async () => {
      try {
        const fetchedProfiles = await getIdealCustomerProfileList();
        if (Array.isArray(fetchedProfiles)) {
          const uniqueICPs = fetchedProfiles.filter(
            (profile: ICP, index: number, self: ICP[]) =>
              index === self.findIndex((p) => p._id === profile._id && p.name === profile.name),
          );
          setIcps(uniqueICPs);

          if (step?.idealCustomerProfileId) {
            const matchingICP = uniqueICPs.find((icp) => icp._id === step.idealCustomerProfileId);
            if (matchingICP) {
              setSelectedICP(matchingICP._id);
              onSelectICP(matchingICP._id);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching ICPs:', error);
      }
    };

    fetchICPs();
  }, [icpFetchTrigger, step?.idealCustomerProfileId]);

  useEffect(() => {
    if (addICPModalOpen) {
      setDropdownOpen(false);
    }
  }, [addICPModalOpen]);

  return (
    <div className="mr-2">
      <Select
        onValueChange={(value) => {
          setSelectedICP(value);
          onSelectICP(value);
        }}
        value={selectedICP || undefined}
        open={dropdownOpen}
        onOpenChange={(open) => setDropdownOpen(open)}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Customer Profile" />
        </SelectTrigger>
        <SelectContent className="z-[1050] relative" portalContainer={document.body}>
          <div className="z-[1060] bg-background" style={{ boxShadow: '0px 2px 4px rgba(24, 24, 24, 0.1)' }}>
            <Button
              variant="ghost"
              className="w-full justify-start rounded-none font-normal"
              onClick={() => {
                setAddICPModalOpen(true);
                onAddICP();
              }}
            >
              <Plus className="mr-2 h-4 w-4" />
              Add/Edit Customer Profile
            </Button>
          </div>
          <div className="overflow-auto max-h-[260px]">
            <SelectGroup>
              {icps.map((icp) => (
                <SelectItem key={icp._id} value={icp._id}>
                  {icp.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </div>
        </SelectContent>
      </Select>
    </div>
  );
};

export default ICPSelect;
