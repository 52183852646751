import { memo, useMemo } from 'react';
import { useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import { ReactComponent as CheckSVG } from '@Assets/Icons/Check.svg';
import PropTypes from 'prop-types';

const chipLabelStyles = {
  display: 'flex',
  alignItems: 'center',
  margin: '14px',
  padding: 0,
};

const chipCommonStyles = {
  'display': 'inline-flex',
  'height': '42px',
  'fontSize': '14px',
  'fontWeight': 500,
  'lineHeight': '130%',
  'borderRadius': '14px',
  'padding': '0px',
  'border': '1px solid',
  'transition': '0.1s ease',
  '& > span': { padding: 0, margin: 0 },
};

const iconCommonStyles = {
  width: '20px',
  height: '20px',
  marginRight: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const postilizeVariants = (theme) => ({
  green: {
    'backgroundColor': theme.palette.primaryCL.Green50,
    'color': theme.palette.primaryCL.Green100,
    'borderColor': 'transparent',
    '&&:hover': {
      backgroundColor: theme.palette.primaryCL.Green50,
      borderColor: theme.palette.primaryCL.Green100,
    },
  },
  red: {
    'backgroundColor': theme.palette.primaryCL.Red50,
    'color': theme.palette.primaryCL.Red100,
    'borderColor': 'transparent',
    '&&:hover': {
      backgroundColor: theme.palette.primaryCL.Red50,
      borderColor: theme.palette.primaryCL.Red100,
    },
  },
  grey: {
    'backgroundColor': theme.palette.primaryCL.Black50,
    'color': theme.palette.primaryCL.Black200,
    'borderColor': 'transparent',
    '&&:hover': {
      backgroundColor: theme.palette.primaryCL.Black50,
      borderColor: theme.palette.primaryCL.Black200,
    },
  },
  darkGrey: {
    'backgroundColor': theme.palette.primaryCL.Black70,
    'color': theme.palette.primaryCL.Black200,
    'borderColor': 'transparent',
    '&&:hover': {
      backgroundColor: theme.palette.primaryCL.Black70,
      borderColor: theme.palette.primaryCL.Black200,
    },
  },
  default: {
    backgroundColor: theme.palette.primaryCL.Black50,
    color: theme.palette.primaryCL.Black200,
    borderColor: 'transparent',
  },
});

const PTopicSelector = memo(({ topic, onToggle, hideIcon, pVariant, useCheckIcon, disabled }) => {
  const theme = useTheme();
  const renderedPVariant = useMemo(() => postilizeVariants(theme)[pVariant], [theme, pVariant]);

  const determineIcon = useMemo(() => {
    if (hideIcon) return null;

    const iconStyle = {
      ...iconCommonStyles,
      fill: renderedPVariant?.color,
    };

    return topic?.isActive ? (
      useCheckIcon ? (
        <CheckSVG style={{ ...iconStyle, width: '12px', height: '12px', marginLeft: '0px' }} />
      ) : (
        <Close style={iconStyle} />
      )
    ) : (
      <Add style={iconStyle} />
    );
  }, [hideIcon, renderedPVariant, topic?.isActive, useCheckIcon]);

  if (!pVariant) return null;

  const chipStyle = {
    ...chipCommonStyles,
    ...renderedPVariant,
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  return (
    <Chip
      key={`${topic.name}Chip`}
      label={<span style={chipLabelStyles}>{topic.name}</span>}
      sx={chipStyle}
      onClick={!disabled ? () => onToggle(topic.name) : undefined}
      onDelete={!hideIcon && !disabled ? () => onToggle(topic.name) : undefined}
      deleteIcon={determineIcon}
    />
  );
});

PTopicSelector.propTypes = {
  topic: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
  pVariant: PropTypes.oneOf(['default', 'green', 'red', 'grey']).isRequired,
  hideIcon: PropTypes.bool,
  useCheckIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};

PTopicSelector.defaultProps = {
  hideIcon: false,
  useCheckIcon: false,
  disabled: false,
};

export default PTopicSelector;
