import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { PlusCircle, MoreHorizontal, Trash2, Share2 } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import { createIdealCustomerProfile } from '@/helper/apiHelper';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface Profile {
  _id: string;
  name: string;
  jobTitles?: string[];
  location?: string;
  industries?: string[];
  employeeSize?: string;
}

interface ProfileListProps {
  profiles: Profile[];
  selectedProfileId: string | null;
  onSelectProfile: (id: string) => void;
  onAddProfile: (profile: any) => void;
  onDeleteProfile: (id: string) => void;
}

export function ProfileList({
  profiles = [],
  selectedProfileId,
  onSelectProfile,
  onAddProfile,
  onDeleteProfile,
}: ProfileListProps) {
  const [profileToDelete, setProfileToDelete] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleCreateProfile = async () => {
    setIsCreating(true);
    try {
      const newProfile = {
        name: `Profile ${profiles.length + 1}`,
        jobTitles: [],
        location: [],
        industries: [],
        employeeSize: [],
      };

      const createdProfile = await createIdealCustomerProfile(newProfile);
      if (createdProfile && createdProfile._id) {
        onAddProfile(createdProfile);
        toast({
          title: 'Success',
          description: 'New profile created successfully',
        });
      } else {
        throw new Error('Invalid profile data received');
      }
    } catch (error) {
      console.error('Profile creation error:', error);
      toast({
        title: 'Error',
        description: 'Failed to create new profile',
        variant: 'destructive',
      });
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="w-64 border-r">
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold mb-2">Your profiles</h2>
        <Button onClick={() => handleCreateProfile()} variant="outline" className="w-full" disabled={isCreating}>
          <PlusCircle className="mr-2 h-4 w-4" />
          {isCreating ? 'Creating...' : 'New profile'}
        </Button>
      </div>
      <ScrollArea className="h-[calc(100vh-7rem)]">
        <div className="p-4">
          {Array.isArray(profiles) &&
            profiles.map((profile) =>
              profile && profile._id ? (
                <div key={profile._id} className="flex items-center mb-1 relative group">
                  <Button
                    variant="ghost"
                    className={`w-full justify-start ${selectedProfileId === profile._id ? 'bg-muted' : ''}`}
                    onClick={() => onSelectProfile(profile._id)}
                  >
                    {profile.name || 'Unnamed Profile'}
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0 absolute right-1 opacity-0 group-hover:opacity-100 data-[state=open]:opacity-100 transition-opacity"
                      >
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <DropdownMenuItem
                            onSelect={(event) => {
                              event.preventDefault();
                              setProfileToDelete(profile._id);
                            }}
                          >
                            <Trash2 className="mr-2 h-4 w-4" />
                            Delete
                          </DropdownMenuItem>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                              This action cannot be undone. This will permanently delete the profile "{profile.name}"
                              and remove its data from our servers.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() => {
                                if (profileToDelete) {
                                  onDeleteProfile(profileToDelete);
                                  setProfileToDelete(null);
                                }
                              }}
                            >
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              ) : null,
            )}
        </div>
      </ScrollArea>
    </div>
  );
}
