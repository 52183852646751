import React, { useState } from 'react';
import { ArrowUp, ArrowDown, Filter, ChevronDown } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import DateRangePicker from '@/components/ui/date-range-picker';
type SortableHeaderProps = {
  label: string;
  sortKey: string;
  currentSort: {
    key: string;
    direction: string;
  };
  onSort: (key: string, direction: string) => void;
  style?: React.CSSProperties;
  filterOptions?: {
    [key: string]: boolean;
  };
  onFilterChange?: (key: string) => void;
  searchValue: string;
  onNameTextFilterChange: (value: string) => void;
  filterIcons?: {
    [key: string]: React.ReactNode;
  };
  footerNode?: React.ReactNode;
  onToggleAll?: (checked: boolean) => void;
  onDateRangeChange?: (dateRange: { from: Date | null; to: Date | null }) => void;
  dateRange?: { from: Date | null; to: Date | null };
  oldestInteractionDate?: Date;
  enableDateFilter?: boolean;
};

export const SortableHeader = ({
  label,
  sortKey,
  currentSort,
  onSort,
  style,
  className,
  filterOptions = [],
  onNameTextFilterChange,
  searchValue,
  onFilterChange,
  filterIcons = {},
  footerNode,
  onToggleAll,
  onDateRangeChange,
  dateRange,
  oldestInteractionDate,
  enableDateFilter,
}: SortableHeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSort = (key: string, direction: string) => {
    if (currentSort.key === key && currentSort.direction === direction) {
      onSort('', '');
    } else {
      onSort({ key, direction });
    }
  };

  const handleNameTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    if (onNameTextFilterChange) {
      onNameTextFilterChange(value);
    }
  };

  const getSortOptions = (key: string) => {
    switch (key) {
      case 'lastInteraction':
        return [
          { label: 'Most Recent', direction: 'desc' },
          { label: 'Least Recent', direction: 'asc' },
        ];
      case 'name':
      case 'position':
        return [
          { label: 'A-Z', direction: 'asc' },
          { label: 'Z-A', direction: 'desc' },
        ];
      case 'relationshipScore':
        return [
          { label: 'High to Low', direction: 'desc' },
          { label: 'Low to High', direction: 'asc' },
        ];
      case 'stage':
        return [
          { label: 'Sort by pipeline order ascending', direction: 'asc' },
          { label: 'Sort by pipeline order descending', direction: 'desc' },
        ];
      case 'contacts.title':
        return [
          // This looks backwards but it's because the title hierarchy index is stored in reverse order
          { label: 'Sort by seniority ascending', direction: 'desc' },
          { label: 'Sort by seniority descending', direction: 'asc' },
        ];
      default:
        return [
          { label: 'Sort Ascending', direction: 'asc' },
          { label: 'Sort Descending', direction: 'desc' },
        ];
    }
  };

  const sortOptions = getSortOptions(sortKey);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const handleFilterChange = (key: string) => {
    if (onFilterChange) {
      onFilterChange(key);
    }
    setTimeout(() => setIsOpen(true), 0);
  };

  return (
    <th className={cn('h-10 px-2 text-left align-middle', className)} style={style}>
      <DropdownMenu open={isOpen} onOpenChange={handleOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="-ml-3 h-8 data-[state=open]:bg-accent hover:bg-transparent"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="font-semibold text-muted-foreground">{label}</span>
            {currentSort?.key === sortKey &&
              (currentSort.direction === 'asc' ? (
                <ArrowUp className="ml-1 h-4 w-4" />
              ) : (
                <ArrowDown className="ml-1 h-4 w-4" />
              ))}
            <ChevronDown className="ml-1 h-4 w-4 opacity-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          className="min-w-[180px] w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
        >
          <DropdownMenuLabel>Sort</DropdownMenuLabel>
          {sortOptions.map(({ label, direction }) => (
            <DropdownMenuCheckboxItem
              key={direction}
              checked={currentSort?.key === sortKey && currentSort?.direction === direction}
              onCheckedChange={() => handleSort(sortKey, direction)}
              onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
            >
              {label}
            </DropdownMenuCheckboxItem>
          ))}

          {filterOptions &&
            (Array.isArray(filterOptions) ? filterOptions.length > 0 : Object.keys(filterOptions).length > 0) && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuLabel>Filter</DropdownMenuLabel>

                <DropdownMenuCheckboxItem
                  checked={
                    Array.isArray(filterOptions)
                      ? filterOptions.every(({ checked }) => checked)
                      : Object.values(filterOptions).every((checked) => checked)
                  }
                  onCheckedChange={(checked) => {
                    if (onToggleAll) {
                      onToggleAll(checked);
                    }
                    setTimeout(() => setIsOpen(true), 0);
                  }}
                >
                  Toggle All
                </DropdownMenuCheckboxItem>

                {Array.isArray(filterOptions)
                  ? filterOptions.map(({ label, value, checked }) => (
                      <DropdownMenuCheckboxItem
                        key={value}
                        checked={checked}
                        onCheckedChange={(checked) => handleFilterChange(value, checked)}
                      >
                        {label}
                      </DropdownMenuCheckboxItem>
                    ))
                  : Object.entries(filterOptions).map(([key, checked]) => (
                      <DropdownMenuCheckboxItem
                        key={key}
                        checked={checked}
                        onCheckedChange={(checked) => handleFilterChange(key, checked)}
                      >
                        {filterIcons[key] ? (
                          <div className="flex items-center">
                            {filterIcons[key]}
                            <span className="ml-2">{key}</span>
                          </div>
                        ) : (
                          key
                        )}
                      </DropdownMenuCheckboxItem>
                    ))}
              </>
            )}
          {[sortKey, label].map((key) => key.toLowerCase()).includes('name') && (
            <>
              <DropdownMenuSeparator />

              <div className="px-2 py-1.5">
                <div className="flex items-center space-x-2">
                  <Filter className="h-4 w-4 opacity-50" />
                  <Input
                    placeholder="Filter..."
                    value={searchValue}
                    onChange={handleNameTextFilterChange}
                    onKeyDown={(e) => e.stopPropagation()}
                    className="h-8 w-full"
                  />
                </div>
              </div>
            </>
          )}

          {sortKey === 'lastInteraction' && enableDateFilter && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuLabel className="flex justify-between items-center">
                Filter by Date
                {dateRange?.from && (
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-4 px-2 text-xs"
                    onClick={() => onDateRangeChange?.({ from: null, to: null })}
                  >
                    Clear
                  </Button>
                )}
              </DropdownMenuLabel>
              <div className="px-2 py-1.5">
                <DateRangePicker
                  value={dateRange}
                  onChange={onDateRangeChange}
                  className="w-full"
                  oldestInteractionDate={oldestInteractionDate}
                />
              </div>
            </>
          )}

          {footerNode && (
            <>
              <DropdownMenuSeparator />
              <div className="px-2 py-1.5">{footerNode}</div>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </th>
  );
};
