import { ArrowUp, ArrowDown } from 'lucide-react';

interface StrengthCellProps {
  score: number;
  scoreChange?: number;
}

export const StrengthCell = ({ score, scoreChange }: StrengthCellProps) => {
  const formattedValue = score.toLocaleString();
  const formattedDelta = `${Math.abs(scoreChange)}%`;

  const hasChange = scoreChange !== 0;
  const isPositive = scoreChange > 0;

  return (
    <div className="flex items-center gap-2">
      <div>{formattedValue}</div>
      {hasChange && (
        <div className={`flex items-center ${isPositive ? 'text-green-600' : 'text-red-600'}`}>
          {isPositive ? <ArrowUp className="h-4 w-4" /> : <ArrowDown className="h-4 w-4" />}
          <span className="text-sm">{formattedDelta}</span>
        </div>
      )}
    </div>
  );
};

StrengthCell.displayName = 'StrengthCell';
