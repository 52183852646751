import React from 'react';
import { MoreHorizontal, Play, Pencil, Trash2, Pause, Settings, UserPlus } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Assistant } from '@/types/assistant';

type AssistantActionsProps = {
  assistant: Assistant;
  onPauseToggle: (assistant: Assistant) => void;
  onDeleteClick: (assistant: Assistant) => void;
  onRenameClick: (id: string) => void;
  onSettingsClick?: () => void;
  onAddContactsClick?: () => void;
};

export const AssistantActions: React.FC<AssistantActionsProps> = ({
  assistant,
  onPauseToggle,
  onDeleteClick,
  onRenameClick,
  onSettingsClick,
  onAddContactsClick,
}) => (
  <Popover>
    <PopoverTrigger asChild>
      <button
        onClick={(e) => e.stopPropagation()}
        className="ml-auto opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <MoreHorizontal className="h-5 w-5" />
        <span className="sr-only">Actions</span>
      </button>
    </PopoverTrigger>
    <PopoverContent className="w-40 p-2" side="right">
      <div className="grid gap-1">
        {assistant.type !== 'Social Media' && Boolean(onAddContactsClick) && (
          <button
            className="flex w-full items-center gap-2 rounded px-2 py-1 text-sm hover:bg-accent"
            onClick={onAddContactsClick}
          >
            <UserPlus className="h-4 w-4" />
            Add contacts
          </button>
        )}
        {assistant.type !== 'Social Media' && (
          <>
            <button
              className="flex w-full items-center gap-2 rounded px-2 py-1 text-sm hover:bg-accent"
              onClick={() => onPauseToggle(assistant)}
            >
              {assistant.status === 'Paused' ? (
                <>
                  <Play className="h-4 w-4" />
                  Resume
                </>
              ) : (
                <>
                  <Pause className="h-4 w-4" />
                  Pause
                </>
              )}
            </button>
            <button
              className="flex w-full items-center gap-2 rounded px-2 py-1 text-sm hover:bg-accent"
              onClick={() => onRenameClick(assistant._id)}
            >
              <Pencil className="h-4 w-4" />
              Rename
            </button>
          </>
        )}
        {assistant.type !== 'Social Media' && Boolean(onSettingsClick) && (
          <button
            className="flex w-full items-center gap-2 rounded px-2 py-1 text-sm hover:bg-accent"
            onClick={onSettingsClick}
          >
            <Settings className="h-4 w-4" />
            Settings
          </button>
        )}
        {assistant.type !== 'Social Media' && <hr className="my-1" />}
        <button
          className="flex w-full items-center gap-2 rounded px-2 py-1 text-sm text-red-600 hover:bg-accent"
          onClick={() => onDeleteClick(assistant)}
        >
          <Trash2 className="h-4 w-4" />
          Delete
        </button>
      </div>
    </PopoverContent>
  </Popover>
);
