import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Http from '@/http/httpClient';
import { getDefaultStrategies, getDefaultTemplates, getAvailableList, toggleSequencePaused } from '@/helper/apiHelper';
import { GetCookie } from '@/helper/cookieManager';
import { setAssistantsFromSequences, setSocialMediaAssistant } from '@/redux/slices/Assistance/assistanceSlice';

type Template = {};
type Strategy = {};
type AvailableList = {};

type UserBio = {
  name: string;
  company?: string;
};

type Assistant = {
  _id: string;
  name: string;
  status: 'Active' | 'Paused';
  type: string;
  createdAt?: string;
  startedAt?: string;
  contacts?: number;
};

type UseAssistanceReturn = {
  userBio: UserBio | null;
  defaultTemplates: Template[];
  strategies: Strategy[];
  availableList: AvailableList[];
  targetAudience: any;
  stepText: string[];
  stepTypes: any[];
  isEmpty: boolean;
  isLoading: boolean;
  initialLoading: boolean;
  strategyLoading: boolean;
  initialListLoading: boolean;
  modalOpen: boolean;
  openSequenceModal: () => void;
  closeSequenceModal: () => void;
  setCreatedSequenceId: React.Dispatch<React.SetStateAction<string | null>>;
  createdSequenceId: string | null;
  fetchAssistantsData: (sequenceId?: string) => void;
  createAssistant: (data: any) => Promise<void>;
  pauseOrResumeAssistant: (assistant: Assistant) => Promise<void>;
  handleAssistantRename: (id: string, newName: string) => Promise<void>;
  handleAssistantDelete: (id: string) => Promise<void>;
  handleAssistantEdit: (id: string) => void;
  initializeSocialMediaAssistant: () => Promise<void>;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  showActive: boolean;
  setShowActive: (active: boolean) => void;
  showPaused: boolean;
  setShowPaused: (paused: boolean) => void;
  sortBy: string;
  setSortBy: (sort: string) => void;
  groupByType: boolean;
  setGroupByType: (type: boolean) => void;
  filteredAndSortedAssistants: () => Assistant[];
  groupedAssistants: any;
};

export function useAssistance(): UseAssistanceReturn {
  const curUser = GetCookie('user');
  const dispatch = useDispatch();

  const delegationAccessId = useSelector((state: any) => state.auth.delegationAccessId);
  const forDelegation = useSelector((state: any) => state.auth.forDelegation || false);
  const userFromStore = useSelector((state: any) => state.auth.user);

  const [userBio, setUserBio] = useState<UserBio | null>(null);
  const [defaultTemplates, setDefaultTemplates] = useState<Template[]>([]);
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [availableList, setAvailableList] = useState<AvailableList[]>([]);
  const [stepText, setStepText] = useState<string[]>([]);
  const [stepTypes, setStepTypes] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // New states moved from ClientAcquisition
  const [initialLoading, setInitialLoading] = useState(true);
  const [strategyLoading, setStrategyLoading] = useState(true);
  const [initialListLoading, setInitialListLoading] = useState(true);
  const [targetAudience, setTargetAudience] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [createdSequenceId, setCreatedSequenceId] = useState<string | null>(null);
  const assistants: Assistant[] = useSelector((state: any) => state.assistance.assistants);
  const [searchTerm, setSearchTerm] = useState('');
  const [showActive, setShowActive] = useState(true);
  const [showPaused, setShowPaused] = useState(true);
  const [sortBy, setSortBy] = useState('alphabetically');
  const [groupByType, setGroupByType] = useState(false);

  const getUpdateEndpoint = (forDelegation: boolean, delegationAccessId: string | undefined) => {
    return forDelegation && delegationAccessId
      ? `updateSequenceSettings/${delegationAccessId}`
      : 'updateSequenceSettings';
  };

  const getDeleteEndpoint = (forDelegation: boolean, delegationAccessId: string | undefined) => {
    return forDelegation && delegationAccessId ? `deleteSequenceData/${delegationAccessId}` : 'deleteSequenceData';
  };

  const fetchBio = async () => {
    try {
      const response = await Http.getData('setup/getBio');
      setUserBio({ name: curUser.name, company: response?.bio?.companyName });
    } catch (error) {
      console.error('Failed to fetch bio:', error);
    }
  };

  const fetchAssistantsData = useCallback(
    (sequenceId?: string) => {
      setIsLoading(true);
      Http.getData('getSequences', { sequenceId })
        .then((response: any) => {
          if (response && response.status) {
            dispatch(setAssistantsFromSequences(response.sequences));
            setIsEmpty(response.sequences.length === 0);
          }
        })
        .catch((error: any) => console.error('Failed to fetch assistants:', error))
        .finally(() => setIsLoading(false));
    },
    [dispatch],
  );

  const fetchDefaultTemplatesFn = async () => {
    try {
      const response = await getDefaultTemplates();
      if (response && response.status) {
        setDefaultTemplates([...response.templates]);
        if (response.stepText) {
          setStepText([...response.stepText]);
        }
      }
    } catch (error) {
      console.error('Failed to fetch default templates:', error);
    } finally {
      setInitialLoading(false);
    }
  };

  const fetchStrategiesFn = async () => {
    try {
      const response = await getDefaultStrategies();
      if (response && response.status) {
        setStrategies([...response.strategies]);
        setTargetAudience(response.targetAudience);
      }
    } catch (error) {
      console.error('Error fetching strategies:', error);
    } finally {
      setStrategyLoading(false);
    }
  };

  const fetchAvailableListsFn = async () => {
    try {
      const response = await getAvailableList();
      if (response && response.status) {
        setAvailableList([...response.lists]);
      }
    } catch (error) {
      console.error('Error fetching available lists:', error);
    } finally {
      setInitialListLoading(false);
    }
  };

  const createAssistant = async (data: any) => {
    try {
      const response = await Http.postData('createSequence', data);
      if (response.status) {
        fetchAssistantsData();
      }
    } catch (error) {
      console.error('Failed to create assistant:', error);
    }
  };

  const pauseOrResumeAssistant = async (assistant: Assistant) => {
    try {
      await toggleSequencePaused(assistant, null, delegationAccessId);
      fetchAssistantsData();
    } catch (error) {
      console.error('Failed to pause/resume assistant:', error);
    }
  };

  const handleAssistantRename = async (id: string, newName: string) => {
    try {
      const endpoint = getUpdateEndpoint(forDelegation, delegationAccessId);
      await Http.postData(endpoint, {
        type: 'Rename',
        sequenceData: {
          _id: id,
          sequenceName: newName,
        },
      });
      fetchAssistantsData();
    } catch (error) {
      console.error('Failed to rename assistant:', error);
    }
  };

  const handleAssistantDelete = async (id: string) => {
    try {
      const endpoint = getDeleteEndpoint(forDelegation, delegationAccessId);
      await Http.postData(endpoint, { seqId: id });
      fetchAssistantsData();
    } catch (error) {
      console.error('Failed to delete assistant:', error);
    }
  };

  const handleAssistantEdit = async (id: string) => {
    try {
      const response = await Http.getData('getSequences', { sequenceId: id });
      if (response?.status && response.sequences?.length > 0) {
        return response.sequences[0];
      }
      return null;
    } catch (error) {
      console.error('Failed to fetch assistant data:', error);
      return null;
    }
  };

  const openSequenceModal = () => setModalOpen(true);
  const closeSequenceModal = () => setModalOpen(false);

  const initializeSocialMediaAssistant = useCallback(async () => {
    const user = userFromStore;

    if (!user?.topics || user.topics?.length > 0) {
      dispatch(
        setSocialMediaAssistant({
          _id: 'social-media',
          name: 'Social Media Assistant',
          status: 'Active',
          type: 'Social Media',
          topics: user.topics,
          socialNetworks: ['twitter', 'linkedin'],
          postFrequency: 'daily',
        }),
      );
    }
  }, [dispatch, userFromStore]);

  const filteredAndSortedAssistants = useCallback(() => {
    return assistants
      .filter((assistant) => {
        const isActive = assistant.status === 'Active';
        const isPaused = assistant.status === 'Paused';
        if (!showActive && isActive) return false;
        if (!showPaused && isPaused) return false;
        return true;
      })
      .filter((assistant) => assistant.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => {
        switch (sortBy) {
          case 'alphabetically':
            return a.name.localeCompare(b.name);
          case 'contacts':
            return (b.contacts || 0) - (a.contacts || 0);
          case 'created':
            if (a.createdAt && b.createdAt) {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
            return 0;
          case 'started':
            if (a.startedAt && b.startedAt) {
              return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
            }
            return 0;
          default:
            return 0;
        }
      });
  }, [assistants, showActive, showPaused, searchTerm, sortBy]);

  const groupedAssistants = useCallback(() => {
    if (!groupByType) {
      return { All: filteredAndSortedAssistants() };
    }
    return filteredAndSortedAssistants().reduce((acc: any, assistant: Assistant) => {
      if (!acc[assistant.type]) {
        acc[assistant.type] = [];
      }
      acc[assistant.type].push(assistant);
      return acc;
    }, {});
  }, [filteredAndSortedAssistants, groupByType]);

  useEffect(() => {
    initializeSocialMediaAssistant();
  }, [initializeSocialMediaAssistant]);

  useEffect(() => {
    fetchBio();
    fetchAssistantsData();
    fetchDefaultTemplatesFn();
    fetchStrategiesFn();
    fetchAvailableListsFn();
  }, [fetchAssistantsData]);

  return {
    userBio,
    defaultTemplates,
    strategies,
    availableList,
    targetAudience,
    stepText,
    stepTypes,
    isEmpty,
    isLoading,
    initialLoading,
    strategyLoading,
    initialListLoading,
    modalOpen,
    openSequenceModal,
    closeSequenceModal,
    createdSequenceId,
    setCreatedSequenceId,
    fetchAssistantsData,
    createAssistant,
    pauseOrResumeAssistant,
    handleAssistantRename,
    handleAssistantDelete,
    handleAssistantEdit,
    initializeSocialMediaAssistant,
    searchTerm,
    setSearchTerm,
    showActive,
    setShowActive,
    showPaused,
    setShowPaused,
    sortBy,
    setSortBy,
    groupByType,
    setGroupByType,
    filteredAndSortedAssistants,
    groupedAssistants,
  };
}
