import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Users, Eye, EyeOff, LinkedinIcon, Mail, Calendar, UserPlus } from 'lucide-react';
import { debounce } from 'lodash';

import { SortableHeader } from './SortableHeader';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { InteractionsCell } from './InteractionsCell';
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';

import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';
import { useToast } from '@Hooks/use-toast';
import useOutsideClick from '@Hooks/useOutsideClick';

import { StageManager } from '../../StageManagement/stage-manager';
import { StagePicker } from '../../StageManagement/stage-picker';
import { StageBadge } from '../../StageManagement/stage-badge';
import { ERMPagination } from './ERMPagination';

import { NO_STAGE_DEFINITION } from '@postilize/shared/constants/ERM';

import {
  changeClientFilterSearchTerm,
  changeClientFilterStages,
  changeClientFilterLastInteraction,
  changeClientSortCriteria,
  changeClientPage,
  selectClientAndFetchRelationships,
  setClientTableEditingStageClientId,
  updateClientStage,
  changeClientVisibility,
  fetchClients,
  invalidateClientTableCacheForPage,
  changeClientFilterLastInteractionDateRange,
} from '@/redux/slices/ERMDashboard';

import { StrengthCell } from './StrengthCell';

// Types (Adjust these based on your actual data models)
type Client = {
  _id: string;
  name: string;
  relationshipScore?: number;
  stage?: string;
  lastInteraction?: string;
  scoreChange?: number;
};

type Stage = {
  _id: string;
  name: string;
  backgroundColor: string;
  textColor: string;
};

export const FILTER_ICON_MAP: Record<string, JSX.Element> = {
  'Incoming LinkedIn InMails': <LinkedinIcon className="h-4 w-4" />,
  'Outgoing LinkedIn InMails': <LinkedinIcon className="h-4 w-4" />,
  'Incoming Emails': <Mail className="h-4 w-4" />,
  'Outgoing Emails': <Mail className="h-4 w-4" />,
  'Calendar Meetings': <Calendar className="h-4 w-4" />,
  'Added as Contact': <UserPlus className="h-4 w-4" />,
};

/** Extract stage filter options based on available stages and current filters. */
function createStageFilterOptions(
  allStages: Stage[],
  currentStageFilters: Record<string, boolean>,
): {
  label: JSX.Element;
  value: string;
  checked: boolean;
}[] {
  return allStages.map((stage) => ({
    label: (
      <StageBadge
        initialText={stage.name}
        backgroundColor={stage.backgroundColor}
        textColor={stage.textColor}
        isEditing={false}
        editable={false}
      />
    ),
    value: stage.name,
    checked: Boolean(currentStageFilters[stage.name]),
  }));
}

interface ClientTableProps {
  clientFilter: React.ReactNode;
}

export const ClientTable = ({ clientFilter }: ClientTableProps) => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const popoverRef = useRef<HTMLDivElement>(null);
  const stagePickerRef = useRef<HTMLElement | null>(null);

  // ------------------------------
  // Data Selectors
  // ------------------------------
  const isClientSettingStage = useSelector((state: any) => state.clientTable.ui.isClientSettingStage);
  const {
    page,
    limit: itemsPerPage,
    filters: currentFilters,
    sortCriteria,
  } = useSelector((state: any) => state.clientTable.queryParams);
  const { cacheByPage, total, oldestInteractionDate } = useSelector((state: any) => state.clientTable.data);
  const isLoading = useSelector((state: any) => !!state.clientTable.loading.loadingStateByPage[page]?.loading);

  const companyInfo = useSelector((state: any) => state.auth.company?.company);
  const activeClient = useSelector((state: any) => {
    const selectedClient = state.dashboard.selectedClient;
    return selectedClient;
  });
  const statePipelineStages = useSelector((state: any) => state.auth.company?.company?.ermPreference?.pipelineStages);
  const pipelineStages = useMemo(() => statePipelineStages || [], [statePipelineStages]);

  const clientList: Client[] = useMemo(() => cacheByPage[page] || [], [cacheByPage, page]);

  // ------------------------------
  // Feature Flags
  // ------------------------------
  const isStrengthFeatureEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard.strength');

  // ------------------------------
  // Handlers: Sorting & Filtering
  // ------------------------------
  const handleSort = useCallback(
    (criteria: { key: string; direction: 'asc' | 'desc' }) => {
      dispatch(changeClientSortCriteria(criteria));
    },
    [dispatch],
  );

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedDispatch = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(changeClientFilterSearchTerm(value));
      }, 500),
    [dispatch],
  );

  const handleSearchTermChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      debouncedDispatch(value);
    },
    [debouncedDispatch],
  );

  const handleToggleStageFilter = useCallback(
    (stageLabel: string) => {
      const updatedStages = { ...currentFilters.stages, [stageLabel]: !currentFilters.stages[stageLabel] };
      dispatch(changeClientFilterStages(updatedStages));
    },
    [dispatch, currentFilters.stages],
  );

  const handleToggleLastInteractionFilter = useCallback(
    (interaction: string) => {
      const updatedInteractions = {
        ...currentFilters.lastInteraction,
        [interaction]: !currentFilters.lastInteraction[interaction],
      };
      dispatch(changeClientFilterLastInteraction(updatedInteractions));
    },
    [dispatch, currentFilters.lastInteraction],
  );

  // ------------------------------
  // Handlers: Toggle All Filters
  // ------------------------------
  const handleToggleAllStageFilters = useCallback(
    (checked: boolean) => {
      const updatedStages = Object.fromEntries(Object.keys(currentFilters.stages).map((stage) => [stage, checked]));
      dispatch(changeClientFilterStages(updatedStages));
    },
    [dispatch, currentFilters.stages],
  );

  const handleToggleAllInteractionFilters = useCallback(
    (checked: boolean) => {
      const updatedInteractions = Object.fromEntries(
        Object.keys(currentFilters.lastInteraction).map((interaction) => [interaction, checked]),
      );
      dispatch(changeClientFilterLastInteraction(updatedInteractions));
    },
    [dispatch, currentFilters.lastInteraction],
  );

  // ------------------------------
  // Handlers: Stage Management
  // ------------------------------
  const [stagePickerOpen, setStagePickerOpen] = useState<string | null>(null);
  const [stageManagerOpen, setStageManagerOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const handleStageUpdate = useCallback(
    async (clientId: string, stageId: string) => {
      try {
        await dispatch(updateClientStage({ clientId, newStageId: stageId })).unwrap();
      } catch (error) {
        console.error('Failed to update stage:', error);
        toast({ title: 'Error', description: 'Failed to update client stage' });
      }
    },
    [dispatch, toast],
  );

  const handleBaseBadgeClick = useCallback((client: Client, e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    stagePickerRef.current = target;
    const rect = target.getBoundingClientRect();
    setPopoverPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setStagePickerOpen(client._id);
  }, []);

  const handleEditStagesClick = useCallback(() => {
    setStageManagerOpen(true);
  }, []);

  const handleStagePickerOpenChange = useCallback(
    (open: boolean) => {
      if (!open && !stageManagerOpen) {
        setStagePickerOpen(null);
      }
    },
    [stageManagerOpen],
  );

  useOutsideClick(popoverRef, () => dispatch(setClientTableEditingStageClientId(null)));

  // ------------------------------
  // Handlers: Visibility & Pagination
  // ------------------------------
  const handleToggleClientVisibility = useCallback(
    async (client: Client) => {
      const action = currentFilters.showHidden ? 'show' : 'hide';
      try {
        await dispatch(changeClientVisibility(client._id, action)).unwrap();
        dispatch(invalidateClientTableCacheForPage(page));
        dispatch(fetchClients());
      } catch {
        toast({ title: 'Error', description: `Failed to ${action} client` });
      }
    },
    [dispatch, toast, currentFilters.showHidden, page],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(changeClientPage(newPage));
    },
    [dispatch],
  );

  const handleClientSelection = useCallback(
    (client: Client) => {
      dispatch(selectClientAndFetchRelationships(client));
    },
    [dispatch],
  );

  const handleDateRangeChange = useCallback(
    (dateRange: { from: Date | null; to: Date | null }) => {
      dispatch(changeClientFilterLastInteractionDateRange(dateRange));
    },
    [dispatch],
  );

  // ------------------------------
  // Responsive Adjustments
  // ------------------------------
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1199);
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 1199);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ------------------------------
  // Derived Values
  // ------------------------------
  const allStages = useMemo(() => [NO_STAGE_DEFINITION, ...pipelineStages], [pipelineStages]);
  const stageFilterOptions = useMemo(
    () => createStageFilterOptions(allStages, currentFilters.stages),
    [allStages, currentFilters.stages],
  );

  const visibilityIcon = useMemo(
    () => (currentFilters.showHidden ? <Eye className="h-4 w-4 icon" /> : <EyeOff className="h-4 w-4 icon" />),
    [currentFilters.showHidden],
  );
  const visibilityText = useMemo(
    () => (currentFilters.showHidden ? 'Unhide Client' : 'Hide Client'),
    [currentFilters.showHidden],
  );

  const loadingOrEmptyMessage = useMemo(() => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center mt-4">
          <CircularProgress size={32} color="inherit" />
        </div>
      );
    }
    if (!isLoading && clientList.length === 0) {
      return (
        <div className="flex justify-center items-center mt-4">
          <p className="text-muted-foreground">
            {currentFilters.showHidden ? 'No hidden clients found' : 'No active clients found'}
          </p>
        </div>
      );
    }
    return null;
  }, [isLoading, clientList, currentFilters.showHidden]);

  // ------------------------------
  // Conditional Headers
  // ------------------------------
  const strengthHeader = useMemo(() => {
    if (!currentFilters.showHidden && isStrengthFeatureEnabled) {
      return (
        <SortableHeader
          label={isSmallScreen ? 'Str' : 'Strength'}
          sortKey="relationshipScore"
          currentSort={sortCriteria}
          onSort={handleSort}
          className="strength-column w-[124px]"
        />
      );
    }
    return null;
  }, [currentFilters.showHidden, isStrengthFeatureEnabled, isSmallScreen, sortCriteria, handleSort]);

  const stageHeader = useMemo(() => {
    if (!currentFilters.showHidden) {
      return (
        <SortableHeader
          label="Stage"
          sortKey="stage"
          currentSort={sortCriteria}
          onSort={handleSort}
          filterOptions={stageFilterOptions}
          onFilterChange={handleToggleStageFilter}
          onToggleAll={handleToggleAllStageFilters}
          style={{ width: '1px', whiteSpace: 'nowrap' }}
        />
      );
    }
    return null;
  }, [
    currentFilters.showHidden,
    sortCriteria,
    handleSort,
    stageFilterOptions,
    handleToggleStageFilter,
    handleToggleAllStageFilters,
  ]);

  // ------------------------------
  // Render Client Rows
  // ------------------------------
  const clientRows = useMemo(() => {
    return clientList.map((client) => {
      const currentStageId = client.stage || NO_STAGE_DEFINITION._id;
      const isSelected = client._id === activeClient?._id;

      return (
        <tr
          key={client._id}
          className={cn(
            'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
            isSelected && 'bg-gray-200/50',
          )}
        >
          <td className="min-w-fit p-2 align-middle">
            <span
              className="cursor-pointer font-semibold hover:underline"
              onClick={() => handleClientSelection(client)}
            >
              {client.name}
            </span>
          </td>

          {!currentFilters.showHidden && isStrengthFeatureEnabled && (
            <td className="p-2 strength-column align-middle">
              <StrengthCell score={client?.relationshipScore || 0} scoreChange={client?.scoreChange} />
            </td>
          )}

          {!currentFilters.showHidden && (
            <td className="p-2 align-middle whitespace-nowrap relative px-2">
              {isClientSettingStage[client._id] ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <StagePicker
                  open={stagePickerOpen === client._id && !stageManagerOpen}
                  client={client}
                  stages={allStages}
                  currentStageId={currentStageId}
                  onStageSelect={handleStageUpdate}
                  onBaseBadgeClick={(e) => handleBaseBadgeClick(client, e)}
                  onEditStagesClick={handleEditStagesClick}
                  onOpenChange={handleStagePickerOpenChange}
                />
              )}
            </td>
          )}

          <td className="align-middle max-w-full overflow-ellipsis p-2 truncate whitespace-nowrap">
            <InteractionsCell interaction={client.lastInteraction} />
          </td>

          <td className="align-middle p-2 w-[100px]">
            <TooltipProvider>
              <div className="flex items-center justify-between gap-2 group">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => handleClientSelection(client)}
                      className="action-button"
                    >
                      <Users className="h-4 w-4 icon" />
                      <span className="sr-only">Relationships</span>
                      <span className="hidden text">Relationships</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Relationships</TooltipContent>
                </Tooltip>

                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="opacity-20 group-hover:opacity-100 transition-opacity action-button"
                      onClick={() => handleToggleClientVisibility(client)}
                    >
                      {visibilityIcon}
                      <span className="sr-only">{visibilityText}</span>
                      <span className="hidden text">{visibilityText}</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{visibilityText}</TooltipContent>
                </Tooltip>
              </div>
            </TooltipProvider>
          </td>
        </tr>
      );
    });
  }, [
    clientList,
    activeClient,
    currentFilters.showHidden,
    isStrengthFeatureEnabled,
    isClientSettingStage,
    stagePickerOpen,
    stageManagerOpen,
    allStages,
    visibilityIcon,
    visibilityText,
    handleClientSelection,
    handleStageUpdate,
    handleBaseBadgeClick,
    handleEditStagesClick,
    handleStagePickerOpenChange,
    handleToggleClientVisibility,
  ]);

  return (
    <div className="rounded-lg pb-4">
      <style jsx>{`
        @media (min-width: 1500px) {
          .action-button .icon {
            display: none;
          }
          .action-button .text {
            display: inline;
          }
          .actions-column {
            width: 235px;
          }
        }
        @media (max-width: 1499px) {
          .actions-column {
            width: 100px;
          }
        }
        @media (min-width: 1200px) {
          .strength-column {
            width: 124px;
          }
        }
        @media (max-width: 1199px) {
          .strength-column {
            width: 70px;
          }
        }
      `}</style>

      <div className="flex items-center justify-between my-3 mx-4">
        <h2 className="text-lg font-semibold text-muted-foreground">
          Client List for
          <span className="text-black"> {companyInfo?.name}</span>
        </h2>
        {clientFilter}
      </div>

      <div className="p-2 relative w-full overflow-auto pb-2.5">
        <table className="p-2 pb-2.5 w-full caption-bottom text-sm">
          <thead className="[&_tr]:border-b">
            <tr className="border-b transition-colors data-[state=selected]:bg-muted">
              <SortableHeader
                label="Client Name"
                sortKey="name"
                currentSort={sortCriteria}
                onSort={handleSort}
                onNameTextFilterChange={handleSearchTermChange}
                searchValue={searchTerm}
                className="flex-grow"
              />
              {strengthHeader}
              {stageHeader}
              <SortableHeader
                label="Last Interaction"
                sortKey="lastInteraction"
                currentSort={sortCriteria}
                onSort={handleSort}
                filterOptions={currentFilters.lastInteraction}
                onFilterChange={handleToggleLastInteractionFilter}
                onToggleAll={handleToggleAllInteractionFilters}
                className="max-w-full whitespace-nowrap"
                filterIcons={FILTER_ICON_MAP}
                searchValue=""
                onNameTextFilterChange={() => {}}
                onDateRangeChange={handleDateRangeChange}
                dateRange={currentFilters.lastInteractionDateRange}
                oldestInteractionDate={oldestInteractionDate}
                enableDateFilter={true}
              />
              <th className={cn('align-middle font-medium h-10 px-2 text-left text-muted-foreground actions-column')}>
                Actions
              </th>
            </tr>
          </thead>

          {!isLoading && clientList.length > 0 && <tbody className="[&_tr:last-child]:border-0">{clientRows}</tbody>}
        </table>
        {loadingOrEmptyMessage}
      </div>

      <StageManager
        open={stageManagerOpen}
        onOpenChange={(open) => !open && setStageManagerOpen(false)}
        stages={pipelineStages}
        onStageSelect={handleStageUpdate}
        triggerRef={stagePickerRef}
        top={popoverPosition.top}
        left={popoverPosition.left}
      />

      <ERMPagination currentPage={page} totalPages={Math.ceil(total / itemsPerPage)} onPageChange={handlePageChange} />
    </div>
  );
};
